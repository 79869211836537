import { gql, useMutation } from '@apollo/client';

const deleteModuleMutation = gql`
  mutation deleteModule($id: String!, $checksum: String!) {
    deleteModule(id: $id, checksum: $checksum)
  }
`;

export function useDeleteModule() {
  return useMutation(deleteModuleMutation);
}
