import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 53rem;
  min-width: 30rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
`;

export const Header = styled.div`
  padding: 0.5rem;
`;

export const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const TableItem = styled.div``;

export const TableHeader = styled.div`
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.5rem;

  &:hover {
    background-color: #f9fafb;
  }
`;

export const TableName = styled.span`
  font-weight: 500;
`;

export const QueryButton = styled.button`
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  background-color: #eff6ff;
  color: #2563eb;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    background-color: #dbeafe;
  }
`;

export const ColumnType = styled.span`
  color: #9ca3af;
`;

export const QuerySection = styled.div`
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  background-color: #f9fafb;
`;

export const QueryCode = styled.pre`
  padding: 0.75rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  overflow-x: auto;
`;

export const ColumnItem = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f9fafb;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    color: #6b7280;
  }

  &:hover svg {
    color: #374151;
  }
`;

export const SearchContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

export const FieldName = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

export interface PillStyledListProps {
  padding?: string[];
  $hasError?: boolean;
}

export const PillStyledList = styled.div<PillStyledListProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: ${({ padding }) =>
    padding != null ? `${padding[0]} ${padding[1]}` : '0.4rem 1rem'};
  background-color: #f3f4f6;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #e5e7eb;
  }

  ${({ $hasError }) =>
    $hasError === true &&
    `
    border: 1px solid #ef4444;
  `}
`;

export const AlignedText = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #374151;
`;
