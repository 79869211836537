import { z } from 'zod';

export const BEARER_TOKEN_SECHEMA = z.object({
  name: z
    .string()
    .nonempty('Required Field')
    .min(1, 'Invalid name')
    .max(50, 'Maximum 50 characters allowed')
    .refine((value) => value.trim() !== '', {
      message: 'Only spaces not allowed',
    }),
  token: z.string().nonempty('Required Field').min(1, 'Invalid token'),
});

export const API_KEY_SCHEMA = z.object({
  name: z
    .string()
    .nonempty('Required Field')
    .min(1, 'Invalid name')
    .max(50, 'Maximum 50 characters allowed')
    .refine((value) => value.trim() !== '', {
      message: 'Only spaces not allowed',
    }),
  value: z.string().nonempty('Required Field').min(1, 'Invalid value'),
});
