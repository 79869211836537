import type { Control, UseFormSetError } from 'react-hook-form';
import { EditorMethods, NectedEditorField, NectedSuggestionModel } from 'ui';

import { MongoNoCodeEditor } from '../../pages/DataSets/components/MongoNoCodeEditor';
import type { DatabaseName } from '../../pages/DataSets/types';
import { editorDomain } from '../../utils/constant';

type QueryEditorProps = {
  name: string;
  from: string;
  control?: Control<any>;
  customSuggestion?: string[];
  customSuggestionObj?: NectedSuggestionModel[];
  databaseName?: DatabaseName;
  disabled?: boolean;
  setValidity?: (state: boolean) => void;
  methods?: EditorMethods;
  handleGetExecutionValues?: (value: Record<string, any>) => void;
  execValues?: Record<string, any>;
  defaultValue?: string | null;
  resetValue?: string | null;
  onReset?: (value?: any) => void;
  setValue?: (value?: any, name?: any) => void;
  lastCursorObj: Record<string, any>;
  setLastCursorObj: any;
  setError: UseFormSetError<any>;
};

export function QueryEditor({
  from,
  name,
  control,
  databaseName = 'mysql',
  customSuggestion = [],
  disabled = false,
  customSuggestionObj = [],
  setValidity,
  methods = 'write',
  handleGetExecutionValues,
  execValues,
  defaultValue = '',
  resetValue,
  onReset,
  setValue,
  setError,
  lastCursorObj,
  setLastCursorObj,
}: QueryEditorProps) {
  switch (databaseName) {
    case 'mongodb':
      return (
        <MongoNoCodeEditor
          from={from}
          setValue={setValue}
          control={control}
          name={name}
          mode={databaseName}
          customSuggestions={customSuggestionObj}
          readOnly={disabled}
          methods={from === 'workflow' ? 'all' : methods}
          onSetEditorValidity={setValidity}
          domain={editorDomain}
          lastCursorObj={lastCursorObj}
          setLastCursorObj={setLastCursorObj}
          setError={setError}
        />
      );
    case 'mysql':
    case 'pgsql':
    case 'redshift':
    case 'sqlserver':
    case 'snowflake':
    case 'oracle':
      return (
        <NectedEditorField
          control={control}
          name={name}
          mode={databaseName}
          customSuggestions={customSuggestionObj}
          readOnly={disabled}
          methods={methods}
          onSetEditorValidity={setValidity}
          domain={editorDomain}
          handleGetExecData={handleGetExecutionValues}
          execValues={execValues}
          defaultValue={defaultValue}
          resetValue={resetValue}
          onReset={onReset}
        />
      );
    default:
      return null;
  }
}
