import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

import { StackAsItem } from '../../../components/layouts/Stack.styled';

export const FullHeightStack = styled(Stack)`
  flex-grow: 1;
`;

export const DatasetFormStyled = styled(StackAsItem)`
  border-inline-end: 1px solid var(--gainsboro);
  width: inherit;
`;

export const SqlEditorAndSchemaContainer = styled.div<{ databaseName: string }>`
  border: ${({ databaseName }) =>
    databaseName !== 'mongodb' ? '1px solid var(--color-lightGray)' : 'none'};
  border-radius: 6px;
  max-height: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: ${({ databaseName }) =>
    databaseName !== 'mongodb' ? '1rem' : '0rem'};
  border-radius: 5px;
  min-height: 31rem;
`;

export const FileExplorerContainer = styled(FullHeightStack)`
  border: 1px solid var(--color-lightGray);
  border-radius: 6px;
  max-height: 31rem;
  height: 31rem;
`;

export const FileExplorerStyledInline = styled(Inline)`
  height: inherit;
`;

export const TextFieldContainer = styled.div`
  width: 10rem;
`;

export const RefreshSchemaContainer = styled(Inline)`
  justify-content: space-between;
`;

export const FormStyled = styled.form`
  height: 100vh;
`;

export const OutputResultContainer = styled.div`
  background-color: var(--color-white);
  border-radius: 6px;
  overflow: auto;
  max-height: 25rem;
`;

export const SyntaxErrorContainer = styled(PadBox)`
  background-color: var(--color-snow);
  border-radius: 4px;
  color: var(--color-fireEngineRed);
`;

export const TreeViewerContainer = styled.div`
  max-height: 30rem;
`;

export const SettingFieldContainer = styled(Inline)`
  min-block-size: 3.7rem;
`;

export const QueryEditorContainer = styled.div`
  flex: 0.75;
`;

export const SchemaContainer = styled.div`
  flex: 0.25;
`;

export const StyledInlineContainer = styled(Inline)<{ status?: string }>`
  justify-content: ${({ status }) =>
    status === 'inreview' ? 'space-between' : 'end'};
`;
