import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Dataset, Image, TextField, TooltipReact, Typography } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';
import { LoopNodeForm } from '../../../models/models';
import { Selector } from '../RuleSheet/Selector/Selector';

type SetVariableMappingProps = {
  control?: Control<LoopNodeForm>;
  setValue: UseFormSetValue<any>;
  dataSet?: Record<string, Dataset>;
  watch: UseFormWatch<any>;
};

export const LoopNodeMapping = ({
  control,
  setValue,
  dataSet,
}: SetVariableMappingProps) => {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <Stack gutter="1.5rem">
      <Stack>
        <Inline>
          <Typography fontWeight={700}>Input Value</Typography>
        </Inline>

        <Selector
          control={control}
          setValue={setValue}
          nodeName="input.inputValue"
          keyName="inputValue"
          dataType="list"
          dataSet={dataSet ?? {}}
          index={0}
          showKeyName={false}
          showPillOnly
        />
      </Stack>
      <Stack gutter={4}>
        <Inline align="center">
          <Typography>Chunk Size</Typography>
          <TooltipReact id="loop-chunk-size-id">
            Chunk size is the number of items grouped into smaller lists to
            process for each loop ite
          </TooltipReact>
        </Inline>
        <TextField
          name={`input.chunkSize.value`}
          type="number"
          size="small"
          control={control}
          disabled={isWorkflowReadOnly}
          showErrorIcon={false}
        />
      </Stack>

      <Stack gutter={4}>
        <Inline align="center">
          <Typography>Concurrency</Typography>
          <TooltipReact
            id="loop-concurrency-id"
            launcher={
              <Image
                src="/assets/konark/icons/parallelIcon.svg"
                alt="concurrency"
                size="xxs"
              />
            }
          >
            Concurrency is how many chunks/items run at the same time
          </TooltipReact>
        </Inline>
        <TextField
          name={`input.concurrency.value`}
          type="number"
          size="small"
          control={control}
          disabled={isWorkflowReadOnly}
          showErrorIcon={false}
        />
      </Stack>
    </Stack>
  );
};
