import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import _isNil from 'lodash/isNil';
import { Outlet } from 'react-router-dom';
import Slider from 'react-slick';

import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import { checkIfThemeEnabled } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { AuthBanner } from '../banner/AuthBanner';
import { NectedLogo } from '../logo';
import {
  AuthBannerContainer,
  AuthConatinerWrapper,
  AuthenticationContainer,
  LogoContainer,
  PadBoxStyled,
  PageContentContainer,
  PageContentWithLogo,
  SlickContainer,
} from './Authentication.styled';

export function Authentication() {
  const isScrollable =
    _isNil(Cookies.get('cc_cookie')) &&
    envMap.VITE_COOKIE_CONSENT_ENABLED === 'true';
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const assets: string[] = [
    `${envMap.VITE_ASSETS_URL}home/signBackground-1.svg`,
    `${envMap.VITE_ASSETS_URL}home/signBackground-2.svg`,
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  const isWhiteLabeled =
    checkIfThemeEnabled(
      currentWorkspace?.whiteLabelEnabled,
      envMap.VITE_FULL_WHITELABELLED === 'true'
    ) ?? false;

  return (
    <AuthConatinerWrapper isScrollable={isScrollable}>
      <AuthenticationContainer>
        {!isWhiteLabeled && (
          <AuthBannerContainer>
            <AuthBanner />
            <SlickContainer>
              <Slider {...settings}>
                {assets.map((i, index) => {
                  return (
                    <div key={index}>
                      <img src={i} alt="slider-image" />
                    </div>
                  );
                })}
              </Slider>
            </SlickContainer>
          </AuthBannerContainer>
        )}

        <PageContentWithLogo gutter="0rem">
          <LogoContainer justify="start">
            <PadBoxStyled>
              <NectedLogo />
            </PadBoxStyled>
          </LogoContainer>

          <PageContentContainer>
            <Outlet />
          </PageContentContainer>
        </PageContentWithLogo>
      </AuthenticationContainer>
    </AuthConatinerWrapper>
  );
}
