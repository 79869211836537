export const workspaceSettingsObject = Object.freeze({
  WORKSPACE_NAME: 'name',
  WORKSPACE_ID: 'uuid',
  TIMEZONE: 'timezone',
  DATE_FORMAT: 'datetimeFormat',
  USER_COUNT: 'userCount',
  IS_DEFAULT: 'isDefault',
  WORKSPACE_DOMAIN: 'subDomain',
  APPROVAL_FLOW_ENABLED: 'approvalFlowEnabled',
  WORKSPACE_THEME: 'theme',
  WORKSPACE_THEME_ENABLED: 'whiteLabelEnabled',
  ENFORCE_MODULE_SCHEMA: 'enforceModuleSchema',
});
