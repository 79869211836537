import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const TestNodesContainerStyled = styled(Stack)`
  /* position: relative; */
  overflow-y: auto;
  overflow-x: auto;
  list-style: none;
  max-block-size: 100vh;
  inline-size: max-content;
  margin: 0;
  padding: 0;
  inline-size: 100%;
`;

export const HeaderStyled = styled(Inline)`
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--color-solitude);
  inline-size: fit-content;
`;

export const HeaderItemStyled = styled.div`
  min-inline-size: 22rem;
  border-inline-end: 1px solid var(--color-solitude);

  &:last-child {
    border-inline-end: none;
  }
`;

export const RowStyled = styled(Inline)`
  inline-size: fit-content;
  border: 1px solid var(--color-solitude);
  border-block-start: none;
`;

export const RowItemStyled = styled.div`
  min-inline-size: 22rem;
  border-inline-end: 1px solid var(--color-solitude);

  &:last-child {
    border-inline-end: none;
  }
`;

export const Form = styled.form`
  block-size: 100vh;
  flex: 1;
  padding: 0rem 2.4rem 6rem;
`;

export const EmptyInputText = styled(Typography)`
  margin-bottom: 1.6rem;
`;

export const SheetContainer = styled.div`
  block-size: 100vh;
  overflow-y: auto;
`;
