import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import AceEditor from 'react-ace';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { IconButton, Toast, Typography } from 'ui';

import {
  CronColumn,
  CronJsonContainer,
  CronRow,
  InfoBlock,
  OutputBlock,
  OutputSection,
  ResultBox,
} from './SchedulerResult.styled';

export type SuccessErrorResponse = {
  success: {
    count: number;
  };
  failure: {
    count: number;
    message?: Record<string, any>;
  };
  limitExceeded: {
    count: number;
    message?: Record<string, any>;
  };
};

type SchedulerResultProps = {
  value: SuccessErrorResponse;
  isValid?: boolean;
};

export function SchedulerResult({
  isValid = true,
  value,
}: SchedulerResultProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLimit, setIsOpenLimit] = useState(false);

  return (
    <PadBox padding="1rem" as={Stack} gutter="1rem">
      <ResultBox>
        <PadBox padding="1rem">
          <Typography fontWeight={700}>Result</Typography>
        </PadBox>

        <OutputBlock padding={[8, 16, 8, 16]}>
          <OutputSection>
            <CronRow>
              <CronColumn padding={[6, 10, 6, 10]} $small>
                <Typography fontWeight={700}>Rows Executed</Typography>
              </CronColumn>

              <CronColumn padding={[6, 10, 6, 10]}>
                <Typography fontWeight={700}>Output</Typography>
              </CronColumn>
            </CronRow>
            {value.failure.count > 0 && (
              <CronRow>
                <CronColumn padding={[6, 10, 6, 10]} $small>
                  <Typography fontWeight={700}>
                    {value.failure.count}
                  </Typography>
                </CronColumn>

                <CronColumn padding={[6, 10, 6, 10]}>
                  <Inline align="center" stretch="start">
                    <Typography fontWeight={700} name="error">
                      Fail
                    </Typography>
                    <IconButton onClick={() => setIsOpen((prev) => !prev)}>
                      {isOpen ? <BiChevronUp /> : <BiChevronDown />}
                    </IconButton>
                  </Inline>
                </CronColumn>
              </CronRow>
            )}

            {!_isNil(value.failure.message) && isOpen && (
              <CronRow>
                <CronColumn padding={[6, 10, 6, 10]} $small />

                <CronColumn padding={[6, 10, 6, 10]}>
                  <CronJsonContainer>
                    <AceEditor
                      className="json-result-readonly"
                      mode="json"
                      theme="chrome"
                      width="100%"
                      fontSize={12}
                      showPrintMargin={false}
                      highlightActiveLine={true}
                      showGutter={true}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      readOnly
                      value={JSON.stringify(value.failure.message)}
                    />
                  </CronJsonContainer>
                </CronColumn>
              </CronRow>
            )}

            {value.limitExceeded.count > 0 && (
              <CronRow>
                <CronColumn padding={[6, 10, 6, 10]} $small>
                  <Typography fontWeight={700}>
                    {value.limitExceeded.count}
                  </Typography>
                </CronColumn>

                <CronColumn padding={[6, 10, 6, 10]}>
                  <Inline align="center" stretch="start">
                    <Typography fontWeight={700} name="error">
                      Limit Exceeded
                    </Typography>
                    <IconButton onClick={() => setIsOpenLimit((prev) => !prev)}>
                      {isOpenLimit ? <BiChevronUp /> : <BiChevronDown />}
                    </IconButton>
                  </Inline>
                </CronColumn>
              </CronRow>
            )}

            {!_isNil(value.limitExceeded.message) && isOpenLimit && (
              <CronRow>
                <CronColumn padding={[6, 10, 6, 10]} $small />

                <CronColumn padding={[6, 10, 6, 10]}>
                  <CronJsonContainer>
                    <AceEditor
                      className="json-result-readonly"
                      mode="json"
                      theme="chrome"
                      width="100%"
                      fontSize={12}
                      showPrintMargin={false}
                      highlightActiveLine={true}
                      showGutter={true}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      readOnly
                      value={JSON.stringify(value.limitExceeded.message)}
                    />
                  </CronJsonContainer>
                </CronColumn>
              </CronRow>
            )}

            {value.success.count > 0 && (
              <CronRow>
                <CronColumn padding={[6, 10, 6, 10]} $small>
                  <Typography fontWeight={700}>
                    {value.success.count}
                  </Typography>
                </CronColumn>

                <CronColumn padding={[6, 10, 6, 10]}>
                  <Typography fontWeight={700} name="success">
                    Success
                  </Typography>
                </CronColumn>
              </CronRow>
            )}
          </OutputSection>
        </OutputBlock>
      </ResultBox>

      {isValid && (
        <InfoBlock>
          <Toast
            message="After testing in staging, implement your schedule in production"
            type="infoBlue"
          />
        </InfoBlock>
      )}
    </PadBox>
  );
}
