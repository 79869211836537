/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable  @typescript-eslint/restrict-template-expressions */
import _isNil from 'lodash/isNil';
import { NectedSuggestionModel } from 'ui';

import {
  Column,
  DatabaseType,
  QueryType,
} from '../../components/SchemaViewer/types';

enum TokenType {
  STRING = 'string',
  DATE = 'date',
  DATETIME = 'datetime',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  JSON = 'json',
  LIST = 'list',
}

interface OperationFields {
  find: string[];
  aggregate: string[];
  updateOne: string[];
  updateMany: string[];
  insertOne: string[];
  insertMany: string[];
  delete: string[];
  index: string[];
  distinct: string[];
  countDocuments: string[];
  [key: string]: string[];
}

type SelectOption = {
  label: string;
  value: string;
};

type FieldConfig = {
  label: string;
  name: string;
  type: string;
  options?: string[] | Record<string, string>;
  placeholder?: string;
  defaultValue?: string;
  rows?: number;
};

type FieldMap = Record<string, FieldConfig>;

interface MongoQueryHelpers {
  getOperationType: (command: string) => string;
  getFieldsForCommand: (command: string) => string[];
  getSortedFields: (command: string) => FieldMap;
}

// Helper function to determine token type based on suggestion's meta field
const getTokenType = (
  token: string,
  customSuggestions: NectedSuggestionModel[]
): TokenType => {
  const tokenName = token;

  // Find the matching suggestion
  const suggestion = customSuggestions.find((s) => s.value === tokenName || s.value.includes(tokenName));

  if (!suggestion) {
    return TokenType.STRING; // Default to string if no match found
  }

  // Directly map meta to TokenType
  switch (suggestion.meta.toLowerCase()) {
    case 'number':
    case 'numeric':
      return TokenType.NUMBER;
    case 'boolean':
    case 'bool':
      return TokenType.BOOLEAN;
    case 'date':
      return TokenType.DATE;
    case 'datetime':
      return TokenType.DATETIME;
    case 'json':
    case 'object':
      return TokenType.JSON;
    case 'array':
    case 'list':
      return TokenType.LIST;
    case 'string':
    default:
      return TokenType.STRING;
  }
};

const needsQuotes = (tokenType: TokenType): boolean => {
  return [TokenType.STRING, TokenType.DATE, TokenType.DATETIME].includes(
    tokenType
  );
};

// Constants
const FIELD_MAP: FieldMap = {
  command: {
    label: 'Action',
    name: 'command',
    type: 'select',
    options: {
      'Find Document': 'find',
      Aggregate: 'aggregate',
      'Insert document': 'insertOne',
      'Insert document(s)': 'insertMany',
      'Update document': 'updateOne',
      'Update document(s)': 'updateMany',
      'Count Documents': 'countDocuments',
    },
    placeholder: 'Select Command',
  },
  collection: {
    label: 'Collection',
    name: 'collection',
    type: 'select',
    options: ['users', 'products', 'orders', 'categories'],
    placeholder: 'Select Collection Name',
  },
  query: {
    label: 'Query',
    name: 'query',
    type: 'textarea',
    rows: 7,
    placeholder: '{key1 : {$gte : value}}',
    defaultValue: '{}',
  },
  options: {
    label: 'Options',
    name: 'options',
    type: 'textarea',
    rows: 7,
    placeholder: '{upsert : false}',
    defaultValue: '{}',
  },
  sort: {
    label: 'Sort',
    name: 'sort',
    type: 'textarea',
    placeholder: '{key1 : value}',
    defaultValue: '{}',
  },
  projection: {
    label: 'Projection',
    name: 'projection',
    type: 'textarea',
    placeholder: '{key1 : value, key2: value}',
    defaultValue: '{}',
  },
  limit: {
    label: 'Limit',
    name: 'limit',
    type: 'number',
    placeholder: '10',
    defaultValue: '0',
  },
  skip: {
    label: 'Skip',
    name: 'skip',
    type: 'number',
    placeholder: '0',
    defaultValue: '0',
  },
  aggregate: {
    label: 'Aggregation Pipeline',
    name: 'aggregate',
    type: 'textarea',
    rows: 3,
    placeholder: '[ { $match: { } }, { $group: { } } ]',
    defaultValue: '[]',
  },
  distinct: {
    label: 'Distinct Field',
    name: 'distinct',
    type: 'text',
    placeholder: 'fieldName',
    defaultValue: '',
  },
  group: {
    label: 'Group',
    name: 'group',
    type: 'textarea',
    rows: 3,
    placeholder: '{ _id: "$field", total: { $sum: 1 } }',
    defaultValue: '{}',
  },
  pipeline: {
    label: 'Pipeline Stages',
    name: 'pipeline',
    type: 'textarea',
    rows: 10,
    placeholder: '[{ $match: {} }, { $group: {} }]',
    defaultValue: '[]',
  },
  update: {
    label: 'Update Operations',
    name: 'update',
    type: 'textarea',
    rows: 3,
    placeholder: '{ $set: { field: "value" } }',
    defaultValue: '{}',
  },
};

const OPERATION_FIELDS: OperationFields = {
  find: [
    'query',
    'projection',
    'sort',
    'limit',
    'skip',
    'readPreference',
    'hint',
  ],
  aggregate: ['pipeline', 'options', 'readPreference', 'hint', 'collation'],
  updateMany: ['query', 'update', 'options', 'writeConcern'],
  updateOne: ['query', 'update', 'options', 'writeConcern'],
  insertMany: ['query', 'options', 'writeConcern'],
  insertOne: ['query', 'options', 'writeConcern'],
  delete: ['query', 'options', 'writeConcern'],
  index: ['index', 'options'],
  distinct: ['distinct', 'query', 'options', 'readPreference'],
  countDocuments: ['query', 'options', 'readPreference', 'hint'],
};

// Helper functions with type safety
export const mongoQueryHelpers: MongoQueryHelpers = {
  getOperationType: (command: string): string => {
    const commandOptions = FIELD_MAP.command.options;

    // If no options exist, return default
    if (_isNil(commandOptions)) {
      return 'find';
    }

    // If command is already an operation type and exists in values
    if (typeof commandOptions === 'object' && !Array.isArray(commandOptions)) {
      const operationTypes = Object.values(commandOptions);

      if (operationTypes.includes(command)) {
        return command;
      }

      // Try to get the operation type for the command label
      return commandOptions[command] ?? 'find';
    }

    return 'find';
  },

  getFieldsForCommand: (command: string): string[] => {
    const operationType = mongoQueryHelpers.getOperationType(command);

    return OPERATION_FIELDS[operationType] ?? [];
  },

  getSortedFields: (command: string): FieldMap => {
    const baseFields = ['command', 'collection'];
    const operationType = mongoQueryHelpers.getOperationType(command);
    const operationFields = OPERATION_FIELDS[operationType] ?? [];

    return [...baseFields, ...operationFields].reduce<FieldMap>(
      (acc, fieldName) => {
        if (!_isNil(FIELD_MAP[fieldName])) {
          acc[fieldName] = FIELD_MAP[fieldName];
        }

        return acc;
      },
      {}
    );
  },
};

const READ_COMMANDS = ['find', 'aggregate', 'distinct', 'countDocuments'];

export const getSelectOptions = (
  fieldConfig: FieldConfig,
  methods: string
): SelectOption[] => {
  if (fieldConfig?.options == null) {
    return [];
  }

  // Handle array options
  if (Array.isArray(fieldConfig.options)) {
    return fieldConfig.options.map((opt) => ({
      label: opt,
      value: opt,
    }));
  }

  // Get all entries from options
  let entries = Object.entries(fieldConfig.options);

  // Filter commands based on read/write method, only if command field
  if (fieldConfig.name === 'command') {
    if (methods === 'read') {
      entries = entries.filter(([_, value]) => READ_COMMANDS.includes(value));
    } else if (methods === 'write') {
      entries = entries.filter(([_, value]) => !READ_COMMANDS.includes(value));
    }
    // If methods === 'all', no filtering is applied
  }

  // Convert to SelectOption format
  return entries.map(([key, value]) => ({
    label: key,
    value,
  }));
};

export const parseMongoQuery = (
  queryString: string,
  customSuggestions: NectedSuggestionModel[]
): Record<string, any> => {
  const normalizedQuery = queryString
    .replace(/\/\*[\s\S]*?\*\//g, '') // Remove multi-line comments
    .replace(/\/\/.*$/gm, '') // Remove single-line comments
    .trim();

  const values: Record<string, any> = {
    query: '{}',
    projection: '{}',
    sort: '{}',
    skip: '0',
    limit: '0',
    options: '{}',
    pipeline: '[]',
    update: '{}',
    document: '{}',
    documents: '[]',
    arrayFilters: '[]',
    filter: '{}',
    operations: '[]',
    distinct: '',
    index: '{}',
    count: false,
  };

  try {
    // Extract collection name
    const collectionMatch = normalizedQuery.match(/db\.(\w+)\./);

    if (!_isNil(collectionMatch)) {
      values.collection = {
        label: collectionMatch[1],
        value: collectionMatch[1],
      };
    }

    // Parse limit
    const limitMatch = normalizedQuery.match(/\.limit\((\d+)\)/);

    if (!_isNil(limitMatch)) {
      values.limit = limitMatch[1];
    }

    // Parse skip
    const skipMatch = normalizedQuery.match(/\.skip\((\d+)\)/);

    if (!_isNil(skipMatch)) {
      values.skip = skipMatch[1];
    }

    // Check for count
    if (
      normalizedQuery.endsWith('.count()') ||
      normalizedQuery.endsWith('.length')
    ) {
      values.count = true;
    }

    // Command extraction
    const commandRegex = /db\.\w+\.(\w+(?:One|Many)?)\(/;
    const commandMatch = normalizedQuery.match(commandRegex);

    if (!_isNil(commandMatch)) {
      const operation = commandMatch[1];
      const commandOptions = FIELD_MAP.command.options as Record<
        string,
        string
      >;
      const commandLabel = Object.entries(commandOptions).find(
        ([_, value]) => value === operation
      )?.[0];

      if (!_isNil(commandLabel)) {
        values.command = {
          label: commandLabel,
          value: operation,
        };
      }
    }

    // Parsing different operations
    if (normalizedQuery.includes('.find(')) {
      const findRegex = /find\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/;
      const findMatch = normalizedQuery.match(findRegex);

      if (!_isNil(findMatch)) {
        const parts = findMatch[1]
          .split(/,(?=\s*{)/)
          .map((part) => part.trim())
          .filter(Boolean);

        // Handle query part
        if (!_isNil(parts[0])) {
          let queryStr = parts[0];
          const openBraces = (queryStr.match(/{/g) ?? []).length;
          const closeBraces = (queryStr.match(/}/g) ?? []).length;

          if (openBraces !== closeBraces) {
            queryStr = parts[0] + ',' + parts[1];
            parts.splice(1, 1);
          }

          values.query =
            queryStr === '{}'
              ? '{}'
              : removeQuotesFromKeys(queryStr, customSuggestions);
        }

        // Handle projection part
        if (!_isNil(parts[1])) {
          values.projection =
            parts[1] === '{}'
              ? '{}'
              : removeQuotesFromKeys(parts[1], customSuggestions);
        }
      }

      // Handle sort
      const sortRegex = /\.sort\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/;
      const sortMatch = normalizedQuery.match(sortRegex);

      if (!_isNil(sortMatch)) {
        const sortContent = sortMatch[1].trim();
        values.sort =
          sortContent === '{}'
            ? '{}'
            : removeQuotesFromKeys(sortContent, customSuggestions);
      }
    }

    // Handle update operations
    else if (normalizedQuery.includes('.update')) {
      const updateRegex = /update(?:One|Many)\(([\s\S]*?)\)\s*$/;
      const updateMatch = normalizedQuery.match(updateRegex);

      if (!_isNil(updateMatch)) {
        const params = updateMatch[1];
        const objects = params.split(',').reduce<string[]>((acc, curr) => {
          const trimmed = curr.trim();

          if (trimmed.startsWith('{')) {
            acc.push(trimmed);
          } else if (acc.length > 0) {
            acc[acc.length - 1] += `, ${trimmed}`;
          }

          return acc;
        }, []);

        if (!_isNil(objects[0])) {
          values.query = removeQuotesFromKeys(objects[0], customSuggestions);
        }

        if (!_isNil(objects[1])) {
          values.update = removeQuotesFromKeys(objects[1], customSuggestions);
        }

        if (!_isNil(objects[2])) {
          values.options = removeQuotesFromKeys(objects[2], customSuggestions);
        }
      }
    }

    // Handle insert operations
    else if (normalizedQuery.includes('.insert')) {
      if (normalizedQuery.includes('.insertOne(')) {
        const insertRegex = /insertOne\(([\s\S]*?)\)(?:\s*;?\s*$)/;
        const insertMatch = normalizedQuery.match(insertRegex);

        if (!_isNil(insertMatch)) {
          const documentMatch = insertMatch[1].match(/{[\s\S]*}/);

          if (!_isNil(documentMatch)) {
            values.query = removeQuotesFromKeys(
              documentMatch[0],
              customSuggestions
            );
          }
        }
      } else if (normalizedQuery.includes('.insertMany(')) {
        const insertRegex = /insertMany\(([\s\S]*?)\)(?:\s*;?\s*$)/;
        const insertMatch = normalizedQuery.match(insertRegex);

        if (!_isNil(insertMatch)) {
          const arrayContent = insertMatch[1].trim();
          values.query = removeQuotesFromKeys(arrayContent, customSuggestions);

          if (!values.query.startsWith('[')) {
            values.query = `[${values.query}]`;
          }
        }
      }
    }

    // Handle aggregate
    else if (normalizedQuery.includes('.aggregate(')) {
      const aggregateRegex = /aggregate\(([\s\S]*?)\)(?:\s*;?\s*$)/;
      const aggregateMatch = normalizedQuery.match(aggregateRegex);

      if (!_isNil(aggregateMatch)) {
        const pipelineMatch = aggregateMatch[1].match(/\[[\s\S]*\]/);

        if (!_isNil(pipelineMatch) && pipelineMatch?.length > 0) {
          values.pipeline = removeQuotesFromKeys(
            pipelineMatch[0] ?? '',
            customSuggestions
          );
        }

        const optionsMatch = normalizedQuery.match(/\.options\(([\s\S]*?)\)/);

        if (!_isNil(optionsMatch)) {
          const optionsJsonMatch = optionsMatch[1].match(/{[\s\S]*}/);

          if (!_isNil(optionsJsonMatch) && optionsJsonMatch?.length > 0) {
            values.options = removeQuotesFromKeys(
              optionsJsonMatch[0] ?? '',
              customSuggestions
            );
          }
        }
      }
    }

    // Handle bulkWrite
    else if (normalizedQuery.includes('.bulkWrite(')) {
      const bulkRegex = /bulkWrite\(([\s\S]*?)\)(?:\s*;?\s*$)/;
      const bulkMatch = normalizedQuery.match(bulkRegex);

      if (!_isNil(bulkMatch)) {
        const operationsMatch = bulkMatch[1].match(/\[[\s\S]*\]/);

        if (!_isNil(operationsMatch)) {
          values.operations = removeQuotesFromKeys(
            operationsMatch[0],
            customSuggestions
          );
        }
      }
    }

    // Handle replaceOne
    else if (normalizedQuery.includes('.replaceOne(')) {
      const replaceRegex =
        /replaceOne\(([\s\S]*?})\s*,\s*({[\s\S]*?})\s*(?:,\s*({[\s\S]*?}))?\s*\)/;
      const replaceMatch = normalizedQuery.match(replaceRegex);

      if (!_isNil(replaceMatch)) {
        const queryMatch = replaceMatch[1].match(/{[\s\S]*}/);

        if (!_isNil(queryMatch)) {
          values.query = removeQuotesFromKeys(queryMatch[0], customSuggestions);
        }

        if (!_isNil(replaceMatch[2])) {
          values.document = removeQuotesFromKeys(
            replaceMatch[2],
            customSuggestions
          );
        }

        if (!_isNil(replaceMatch[3])) {
          values.options = removeQuotesFromKeys(
            replaceMatch[3],
            customSuggestions
          );
        }
      }
    }

    // Handle distinct
    else if (normalizedQuery.includes('.distinct(')) {
      const distinctRegex = /distinct\("([^"]*)",?\s*([\s\S]*?)\)(?=\.|\s*$)/;
      const distinctMatch = normalizedQuery.match(distinctRegex);

      if (!_isNil(distinctMatch)) {
        values.distinct = distinctMatch[1];

        if (!_isNil(distinctMatch[2])) {
          const queryMatch = distinctMatch[2].match(/{[\s\S]*}/);

          if (!_isNil(queryMatch)) {
            values.query = removeQuotesFromKeys(
              queryMatch[0],
              customSuggestions
            );
          }
        }
      }
    }

    // Handle countDocuments
    else if (normalizedQuery.includes('.countDocuments(')) {
      const countDocumentsRegex =
        /countDocuments\((?:"([^"]*)",\s*)?([\s\S]*?)\)(?=\.|\s*$)/;
      const countDocumentsMatch = normalizedQuery.match(countDocumentsRegex);

      if (!_isNil(countDocumentsMatch)) {
        if (!_isNil(countDocumentsMatch[1])) {
          values.distinct = {
            label: countDocumentsMatch[1],
            value: countDocumentsMatch[1],
          };
        }

        if (!_isNil(countDocumentsMatch[2])) {
          let paramString = countDocumentsMatch[2];

          if (!_isNil(countDocumentsMatch[1])) {
            paramString = paramString.replace(/^,\s*/, '');
          }

          const objectsMatch = paramString.match(
            /{[^{}]*(?:{[^{}]*(?:{[^{}]*}[^{}]*)*}[^{}]*)*}/g
          );

          if (!_isNil(objectsMatch)) {
            values.query = removeQuotesFromKeys(
              objectsMatch[0],
              customSuggestions
            );

            if (objectsMatch.length > 1) {
              values.options = removeQuotesFromKeys(
                objectsMatch[1],
                customSuggestions
              );
            }
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error parsing MongoDB query:', error);
  }

  return values;
};

export const addQuotesToKeys = (
  str: string,
  customSuggestions: NectedSuggestionModel[]
): string => {
  if (
    typeof str !== 'string' ||
    str.length === 0 ||
    str.trim() === '{}' ||
    str.trim() === '[]'
  ) {
    return str;
  }

  // Store MongoDB special values
  const specialValues: string[] = [];
  let processedStr = str.replace(
    /ObjectId\([^)]+\)|(?:new Date|ISODate)\([^)]*\)|NumberLong\([^)]+\)|NumberInt\([^)]+\)|NumberDecimal\([^)]+\)/g,
    (match) => {
      specialValues.push(match);

      return `__SPECIAL_VALUE_${specialValues.length - 1}__`;
    }
  );

  // Handle tokens based on their types
  processedStr = processedStr.replace(
    /(?:"(\{\{\s*\.[^}]+\}\})")|(\{\{\s*\.[^}]+\}\})/g,
    (match, quoted, unquoted) => {
      const token = quoted || unquoted;
      const tokenType = getTokenType(token, customSuggestions);

      return `__TOKEN_${tokenType}_${token}__`;
    }
  );

  // Add quotes to MongoDB field names
  processedStr = processedStr
    .replace(/(\{|\s+)(\$[a-zA-Z]+):/g, '$1__mongo_op_$2:')
    .replace(/([{,]\s*)([a-zA-Z0-9_$.]+)\s*:/g, '$1"$2":')
    .replace(/"__mongo_op_(\$[a-zA-Z]+)":/g, '$1:');

  // Restore special values
  specialValues.forEach((value, index) => {
    const placeholder = `__SPECIAL_VALUE_${index}__`;
    processedStr = processedStr.replace(placeholder, value);
  });

  // Restore tokens with proper quoting
  processedStr = processedStr.replace(
    /__TOKEN_(\w+)_(\{\{[^}]+\}\})__/g,
    (match, type, token) => {
      const tokenType = type as TokenType;

      return needsQuotes(tokenType) ? `"${token}"` : token;
    }
  );

  return processedStr;
};

export const removeQuotesFromKeys = (
  str: string,
  customSuggestions: NectedSuggestionModel[]
): string => {
  if (
    typeof str !== 'string' ||
    str.length === 0 ||
    str.trim() === '{}' ||
    str.trim() === '[]'
  ) {
    return str;
  }

  // Store MongoDB special values
  const specialValues: string[] = [];
  let processedStr = str.replace(
    /"\\?"__(?:objectid|date|bool)__[^"]+\\?""/g,
    (match) => {
      specialValues.push(match);

      return `__SPECIAL_VALUE_${specialValues.length - 1}__`;
    }
  );

  // Handle tokens based on their types
  processedStr = processedStr.replace(
    /(?:"(\{\{\s*\.[^}]+\}\})")|(\{\{\s*\.[^}]+\}\})/g,
    (match, quoted, unquoted) => {
      const token = quoted || unquoted;
      const tokenType = getTokenType(token, customSuggestions);

      return `__TOKEN_${tokenType}_${token}__`;
    }
  );

  // Remove quotes from field names
  processedStr = processedStr
    .replace(/"(\$[a-zA-Z]+)":/g, '$1:')
    .replace(/"([a-zA-Z0-9_$.]+)":/g, '$1:');

  // Restore special values
  specialValues.forEach((value, index) => {
    const placeholder = `__SPECIAL_VALUE_${index}__`;
    processedStr = processedStr.replace(placeholder, value);
  });

  // Restore MongoDB special types
  processedStr = processedStr
    .replace(/"\\"__objectid__([a-fA-F0-9]{0,24})\\""/g, 'ObjectId("$1")')
    .replace(/"__date__now"/g, 'new Date()')
    .replace(/"__date__([^"]+)"/g, 'ISODate("$1")')
    .replace(/"__bool__true"/g, 'true')
    .replace(/"__bool__false"/g, 'false')
    .replace(/"(\d{10,})"/g, 'NumberLong("$1")')
    .replace(/"(\d+\.\d+)"/g, '$1')
    .replace(/"(\d+)"/g, '$1');

  // Restore tokens with proper quoting
  processedStr = processedStr.replace(
    /__TOKEN_(\w+)_(\{\{[^}]+\}\})__/g,
    (match, type, token) => {
      const tokenType = type as TokenType;

      return needsQuotes(tokenType) ? `"${token}"` : token;
    }
  );

  return processedStr;
};

export const sremoveQuotesFromKeys = (
  str: string,
  customSuggestions: NectedSuggestionModel[]
): string => {
  // Explicit type and empty checks for str
  if (
    typeof str !== 'string' ||
    str.length === 0 ||
    str.trim() === '{}' ||
    str.trim() === '[]'
  ) {
    return str;
  }

  // Store tokens with their types
  str = str.replace(/\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/g, (match) => {
    const tokenType = getTokenType(match, customSuggestions);

    return `__TOKEN_${tokenType}_${match}__`;
  });

  // Store special values temporarily to prevent modification
  const specialValues: string[] = [];
  str = str.replace(/"\\?"__(?:objectid|date|bool)__[^"]+\\?""/g, (match) => {
    specialValues.push(match);

    return `__SPECIAL_VALUE_${specialValues.length - 1}__`;
  });

  // Remove quotes from MongoDB operators (e.g., "$set")
  str = str.replace(/"(\$[a-zA-Z]+)":/g, '$1:');

  // Remove quotes only from valid MongoDB field names
  // Valid field names contain alphanumeric characters, underscores, and dots
  str = str.replace(/"([a-zA-Z0-9_$.]+)":/g, '$1:');

  // Restore special values with explicit type checking
  specialValues.forEach((value: string, index: number) => {
    if (typeof value === 'string' && value.length > 0) {
      const placeholder = `__SPECIAL_VALUE_${index}__`;
      str = str.replace(placeholder, value);
    }
  });

  // Now process the special values
  str = str
    // Restore ObjectId
    .replace(/"\\"__objectid__([a-fA-F0-9]{0,24})\\""/g, 'ObjectId("$1")')
    // Restore Date values
    .replace(/"__date__now"/g, 'new Date()')
    .replace(/"__date__([^"]+)"/g, 'ISODate("$1")')
    // Restore booleans
    .replace(/"__bool__true"/g, 'true')
    .replace(/"__bool__false"/g, 'false')
    // Restore numbers
    .replace(/"(\d{10,})"/g, 'NumberLong("$1")')
    .replace(/"(\d+\.\d+)"/g, '$1')
    .replace(/"(\d+)"/g, '$1');

  // Handle special edge cases
  str = str.replace(/: ""/g, ': ""').replace(/: "null"/g, ': null');

  // Restore tokens with proper quoting based on encoded type
  str = str.replace(
    /__TOKEN_(\w+)_(\{\{[^}]+\}\})__/g,
    (match, type, token) => {
      const tokenType = type as TokenType;

      return needsQuotes(tokenType) ? `"${token}"` : token;
    }
  );

  return str;
};

function getMethodType(
  displayCommand: string,
  singleMethod: string,
  multiMethod: string
): string {
  return typeof displayCommand === 'string' && displayCommand.includes('One')
    ? singleMethod
    : multiMethod;
}

export const buildMongoQuery = ({
  command,
  values,
  forStorage = false,
  customSuggestions,
}: BuildMongoQueryArgs): string => {
  // Properly type check collection value
  const collection = values.collection?.value;

  if (typeof collection !== 'string' || collection.length === 0) return '';

  // Start building query with type-safe collection name
  let queryString = `db.${String(collection)}.`;

  // Safely get the display command
  const displayCommand = typeof command === 'object' ? command.value : command;
  const operation =
    mongoQueryHelpers.getOperationType(String(displayCommand)) ?? 'find';

  try {
    const buildQueryPart = (
      value: unknown,
      defaultValue: string = '{}'
    ): string => {
      // Type guard for value
      if (typeof value !== 'string' || value === defaultValue) {
        return defaultValue;
      }

      if (forStorage) {
        const unquoted = removeQuotesFromKeys(value, customSuggestions);

        return unquoted
          .replace(/([{,]\s*)([a-zA-Z0-9_$][a-zA-Z0-9_$]*)\s*:/g, '$1"$2":')
          .replace(/ObjectId\("([^"]+)"\)/g, 'ObjectId("$1")')
          .replace(/ISODate\("([^"]+)"\)/g, 'ISODate("$1")')
          .replace(/new Date\(\)/g, 'new Date()')
          .replace(/NumberLong\("([^"]+)"\)/g, 'NumberLong("$1")')
          .replace(/NumberInt\("([^"]+)"\)/g, 'NumberInt("$1")')
          .replace(/NumberDecimal\("([^"]+)"\)/g, 'NumberDecimal("$1")');
      }

      return removeQuotesFromKeys(value, customSuggestions);
    };

    // Type-safe method determination
    const insertMethod: string =
      typeof displayCommand === 'string'
        ? getMethodType(displayCommand, 'insertOne', 'insertMany')
        : 'insertOne';

    const updateMethod: string =
      typeof displayCommand === 'string'
        ? getMethodType(displayCommand, 'updateOne', 'updateMany')
        : 'updateOne';

    const deleteMethod: string =
      typeof displayCommand === 'string'
        ? getMethodType(displayCommand, 'deleteOne', 'deleteMany')
        : 'deleteOne';

    switch (operation) {
      case 'find': {
        queryString += `find(${buildQueryPart(values.query)}${
          values.projection != null && values.projection !== '{}'
            ? `,${buildQueryPart(values.projection)}`
            : ''
        })`;

        if (values.sort != null && values.sort !== '{}') {
          queryString += `.sort(${buildQueryPart(values.sort)})`;
        }

        if (values.skip != null && values.skip !== '0') {
          queryString += `.skip(${String(values.skip)})`;
        }

        if (values.limit != null && values.limit !== '0') {
          queryString += `.limit(${String(values.limit)})`;
        }

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `.options(${buildQueryPart(values.options)})`;
        }

        break;
      }

      case 'aggregate': {
        queryString += `aggregate(${buildQueryPart(values.pipeline, '[]')}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }
      case 'insertOne':
      case 'insertMany': {
        queryString += `${String(insertMethod)}(`;

        if (insertMethod === 'insertOne') {
          const documentValue =
            typeof values.document === 'string' && values.document !== '{}'
              ? values.document
              : values.query;
          queryString += buildQueryPart(documentValue);
        } else {
          let constructedValue = values.query;

          if (
            typeof constructedValue === 'string' &&
            !constructedValue.startsWith('[') &&
            !constructedValue.endsWith(']')
          ) {
            constructedValue = `[${constructedValue}]`;
          }
          queryString += buildQueryPart(constructedValue, '[]');
        }

        if (typeof values.options === 'string' && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'updateOne':
      case 'updateMany': {
        queryString += `${String(updateMethod)}(`;

        // First parameter: filter/query
        if (typeof values.query === 'string' && values.query !== '{}') {
          queryString += buildQueryPart(values.query, '{}');
        } else {
          queryString += '{}'; // Default empty filter if not provided
        }

        // Second parameter: update operations
        if (typeof values.update === 'string' && values.update !== '{}') {
          queryString += `, ${buildQueryPart(values.update, '{}')}`;
        } else {
          queryString += ', {}'; // Default empty update if not provided
        }

        // Third parameter (optional): options
        if (typeof values.options === 'string' && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options, '{}')}`;
        }

        queryString += ')';
        break;
      }
      case 'delete': {
        queryString += `${String(deleteMethod)}(${buildQueryPart(
          values.query
        )}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'distinct': {
        queryString += `distinct(${
          typeof values.distinct === 'string' ? `"${values.distinct}"` : '""'
        }, ${buildQueryPart(values.query)}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'replace': {
        queryString += `replaceOne(${buildQueryPart(
          values.query
        )}, ${buildQueryPart(values.document)}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'countDocuments': {
        queryString += `countDocuments(${buildQueryPart(values.query)}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'index': {
        queryString += `createIndex(${buildQueryPart(values.index)}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      case 'bulk': {
        queryString += `bulkWrite(${buildQueryPart(values.operations, '[]')}`;

        if (!_isNil(values.options) && values.options !== '{}') {
          queryString += `, ${buildQueryPart(values.options)}`;
        }
        queryString += ')';
        break;
      }

      default:
        queryString += 'find({})';
    }

    return queryString;
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error building query:', error);

    return 'QUERY_ERROR';
  }
};

type BuildMongoQueryArgs = {
  command: Record<string, any> | string;
  values: Record<string, any>;
  operationType?: string;
  forStorage: boolean;
  customSuggestions: NectedSuggestionModel[];
};
export interface Option extends NectedSuggestionModel {
  label: string;
}

export const getUniqueOptions = (
  customSuggestions: NectedSuggestionModel[]
): Option[] => {
  const uniqueMap = new Map<string, Option>();

  customSuggestions
    .filter((item: NectedSuggestionModel) => item.meta === 'collection')
    .forEach((item) => {
      // Use value as unique identifier since it's required
      const key = item.value;

      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, {
          ...item,
          label: item.name ?? item.value,
        });
      }
    });

  return Array.from(uniqueMap.values()).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
};

// Helper function to get dummy value based on data type
const getDummyValue = (type: string): string => {
  const loweredType = type.toLowerCase();

  // MongoDB specific types
  if (loweredType.includes('objectid')) {
    return 'ObjectId("507f1f77bcf86cd799439011")';
  }

  // Basic types
  if (loweredType.includes('string')) return '"sample_text"';

  if (loweredType.includes('int') || loweredType.includes('number')) return '0';

  if (
    loweredType.includes('float') ||
    loweredType.includes('double') ||
    loweredType.includes('decimal')
  ) {
    return '0.0';
  }

  if (loweredType.includes('bool')) return 'false';

  if (loweredType.includes('date') || loweredType.includes('time')) {
    return `"${new Date().toISOString()}"`;
  }

  if (loweredType.includes('array') || loweredType === 'primitive.a') {
    return '[]';
  }

  if (loweredType.includes('map') || loweredType === 'primitive.m') return '{}';

  return 'null';
};

const generateMongoDBQuery = (
  tableName: string,
  columns: Column[],
  queryType: QueryType
): string => {
  switch (queryType) {
    case 'select':
      return `db.${tableName}.find(
    {}, // Query criteria
    {} // Projection
   ).limit(10);`;

    case 'aggregate':
      return `db.${tableName}.aggregate([
    {
      $match: {
        // Match criteria
      }
    },
    {
      $group: {
        _id: null,
        total: { $sum: 1 }
      }
    },
    {
      $project: {
        _id: 0,
        total: 1
      }
    }
   ]);`;

    case 'insert':
      return `db.${tableName}.insertOne({
      ${columns
        .map((col) => `"${col.name}": ${getDummyValue(col.type)}`)
        .join(',\n    ')}
   });`;

    case 'update':
      return `db.${tableName}.updateOne(
      { "_id": ObjectId("507f1f77bcf86cd799439011") },
      {
        "$set": {
          ${columns
            .map((col) => `"${col.name}": ${getDummyValue(col.type)}`)
            .join(',\n        ')}
        }
      },
      { "upsert": false }
   );`;

    default:
      return '';
  }
};

const generateMySQLQuery = (
  tableName: string,
  columns: Column[],
  queryType: QueryType
): string => {
  switch (queryType) {
    case 'select':
      return `SELECT 
      ${columns
        .map((col) => `\`${col.name}\` /* ${getDummyValue(col.type)} */`)
        .join(',\n    ')}
  FROM \`${tableName}\`
  WHERE 1=1;`;

    case 'insert':
      return `INSERT INTO \`${tableName}\` (
      ${columns.map((col) => `\`${col.name}\``).join(',\n    ')}
  )
  VALUES (
      ${columns.map((col) => getDummyValue(col.type)).join(',\n    ')}
  );`;

    case 'update':
      return `UPDATE \`${tableName}\`
  SET
      ${columns
        .map((col) => `\`${col.name}\` = ${getDummyValue(col.type)}`)
        .join(',\n    ')}
  WHERE \`id\` = 1;`;
    case 'aggregate':
    default:
      return '';
  }
};

const generatePostgresQuery = (
  tableName: string,
  columns: Column[],
  queryType: QueryType
): string => {
  switch (queryType) {
    case 'select':
      return `SELECT 
      ${columns
        .map((col) => `"${col.name}" /* ${getDummyValue(col.type)} */`)
        .join(',\n    ')}
  FROM "${tableName}"
  WHERE TRUE;`;

    case 'insert':
      return `INSERT INTO "${tableName}" (
      ${columns.map((col) => `"${col.name}"`).join(',\n    ')}
  )
  VALUES (
      ${columns.map((col) => getDummyValue(col.type)).join(',\n    ')}
  )
  RETURNING *;`;

    case 'update':
      return `UPDATE "${tableName}"
  SET
      ${columns
        .map((col) => `"${col.name}" = ${getDummyValue(col.type)}`)
        .join(',\n    ')}
  WHERE "id" = 1
  RETURNING *;`;
    case 'aggregate':
    default:
      return '';
  }
};

const generateSnowflakeQuery = (
  tableName: string,
  columns: Column[],
  queryType: QueryType
): string => {
  switch (queryType) {
    case 'select':
      return `SELECT 
      ${columns
        .map(
          (col) =>
            `"${col.name.toUpperCase()}" /* ${getDummyValue(col.type)} */`
        )
        .join(',\n    ')}
  FROM "${tableName.toUpperCase()}"
  WHERE TRUE;`;

    case 'insert':
      return `INSERT INTO "${tableName.toUpperCase()}" (
      ${columns.map((col) => `"${col.name.toUpperCase()}"`).join(',\n    ')}
  )
  VALUES (
      ${columns.map((col) => getDummyValue(col.type)).join(',\n    ')}
  );`;

    case 'update':
      return `UPDATE "${tableName.toUpperCase()}"
  SET
      ${columns
        .map(
          (col) => `"${col.name.toUpperCase()}" = ${getDummyValue(col.type)}`
        )
        .join(',\n    ')}
  WHERE "ID" = 1;`;
    case 'aggregate':
    default:
      return '';
  }
};

const generateOracleQuery = (
  tableName: string,
  columns: Column[],
  queryType: QueryType
): string => {
  switch (queryType) {
    case 'select':
      return `SELECT 
      ${columns
        .map(
          (col) =>
            `"${col.name.toUpperCase()}" /* ${getDummyValue(col.type)} */`
        )
        .join(',\n    ')}
  FROM "${tableName.toUpperCase()}"
  WHERE 1=1;`;

    case 'insert':
      return `INSERT INTO "${tableName.toUpperCase()}" (
      ${columns.map((col) => `"${col.name.toUpperCase()}"`).join(',\n    ')}
  )
  VALUES (
      ${columns.map((col) => getDummyValue(col.type)).join(',\n    ')}
  );`;

    case 'update':
      return `UPDATE "${tableName.toUpperCase()}"
  SET
      ${columns
        .map(
          (col) => `"${col.name.toUpperCase()}" = ${getDummyValue(col.type)}`
        )
        .join(',\n    ')}
  WHERE "ID" = 1;`;
    case 'aggregate':
    default:
      return '';
  }
};

export const generateDatabaseSpecificQuery = (
  dbType: DatabaseType,
  tableName: string,
  columns: Column[],
  queryType: QueryType | 'aggregate'
): string => {
  switch (dbType.toLowerCase() as DatabaseType) {
    case 'mysql':
      return generateMySQLQuery(tableName, columns, queryType);
    case 'pgsql':
    case 'redshift':
      return generatePostgresQuery(tableName, columns, queryType);
    case 'sqlserver':
      return generateMySQLQuery(tableName, columns, queryType);
    case 'snowflake':
      return generateSnowflakeQuery(tableName, columns, queryType);
    case 'oracle':
      return generateOracleQuery(tableName, columns, queryType);
    case 'mongodb':
      return generateMongoDBQuery(tableName, columns, queryType);
    default:
      return 'Unsupported database type';
  }
};
