import { gql, useLazyQuery } from '@apollo/client';

import type { TableDataItemModel } from '../../types';

const getModuleByIdQuery = gql`
  query getModuleById($id: String!) {
    getModule(id: $id) {
      data {
        checksum
        id
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

export type ModuleResponseModel = {
  getModule: {
    data: TableDataItemModel[];
  };
};

export type ModuleByIdVariables = {
  id: string;
};

export function useGetModuleById() {
  return useLazyQuery<ModuleResponseModel, ModuleByIdVariables>(
    getModuleByIdQuery
  );
}
