import { Inline } from '@bedrock-layout/primitives';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer, Typography } from 'ui';

import { ExecutionView } from '../../../../../components/jsonOutputField/components/ExecutionView';
import { JsonTreeContainer } from '../SwitchNodeSheet/SwitchNodeSheet.styled';
import { JsonContainer, OutputContainer } from './ApiTestResult.styled';

type ApiTestResultProps = {
  result: Record<string, any>;
};

export function ApiTestResult({ result }: ApiTestResultProps) {
  return (
    <OutputContainer padding="2rem">
      <Inline gutter="2rem" align="center">
        <Typography name="heading3">Output</Typography>

        <Inline gutter="1rem" align="center">
          <Typography name="success">Success</Typography>
        </Inline>
      </Inline>
      <ExecutionView>
        <JsonContainer>
          <AceEditor
            className="json-result-readonly"
            mode="json"
            theme="chrome"
            width="100%"
            fontSize={12}
            showPrintMargin={false}
            highlightActiveLine={true}
            showGutter={true}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            readOnly
            value={JSON.stringify(result, null, '\t')}
          />
        </JsonContainer>

        <JsonTreeContainer>
          <JsonHeroTreeViewer json={result} />
        </JsonTreeContainer>
      </ExecutionView>
    </OutputContainer>
  );
}
