import { gql, useMutation } from '@apollo/client';

const deleteModuleSchemaMutation = gql`
  mutation deleteModuleSchema($id: String!, $checksum: String!) {
    deleteModuleSchema(id: $id, checksum: $checksum)
  }
`;

export function useDeleteModuleSchema() {
  return useMutation(deleteModuleSchemaMutation);
}
