import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _capitalize from 'lodash/capitalize';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown } from 'react-icons/bi';
import { Button, TextField, Typography, toasts } from 'ui';
import { z } from 'zod';

import { axiosPrivate } from '../../../../api/axios';
import { subscriptionPlanAtom } from '../../../../atom';
import { getUserState } from '../../../../hooks/getUserState';
import { SubscriptionPlanType } from '../../../../types';
import {
  isOnboardingCompleted,
  showErrorToast,
} from '../../../../utils/common';
import { inviteUser } from '../../../../utils/constant';
import type { CustomAxiosError } from '../../../../utils/response/types';
import { emailValidationSchema } from '../../../../utils/schema';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import { useGetWorkspaceMemberList } from '../../hooks/restApi/useGetWorkspaceMemberList';
import { useInviteMemberInAWorkspace } from '../../hooks/restApi/useInviteMemberInAWorkspace';
import { InlineChildrenContainer } from '../common/InlineChildrenContainer';
import { WorkspaceContainer } from '../common/Workspace.styled';
import type { Role, RoleDetails } from '../types';
import { RoleList } from './RoleList';
import { WorkspaceMemberListMemoized } from './WorkspaceMemberList';
import { TextFieldContainer } from './WorkspaceMemberList.styled';

const formValidationSchema = z.object({
  ...emailValidationSchema,
});

type FormValues = {
  email: string;
};

type TeamProps = {
  inviteType: 'embed' | 'team';
};

export function Team({ inviteType }: TeamProps) {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formValidationSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const { data, getWorkspaceMemberList } = useGetWorkspaceMemberList();
  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  useEffect(() => {
    void getWorkspaceMemberList({ inviteType });
  }, [inviteType]);

  const [role, setRole] = useState<Role>('Select Role');

  const { inviteMemberInAWorkspace } = useInviteMemberInAWorkspace();

  const onClickRole = (role: RoleDetails) => {
    const { value } = role;
    setRole(value);
  };

  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);

    if (!_isNil(response.data.data)) {
      setSubscriptionPlan({
        ...response.data.data,
        planName: response.data.data.plan.name,
      });
    }
  };

  const sendInvite = async (formValues: FormValues) => {
    const { email } = formValues;

    try {
      await inviteMemberInAWorkspace({
        email,
        role,
        inviteType,
      });

      if (!isOnboardingCompleted(inviteUser)) {
        updateWidgetState(inviteUser)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      setValue('email', '');
      toasts.success(
        inviteType === 'embed' ? 'User added' : 'Invite sent',
        'success'
      );
      setRole('Select Role');
      void getWorkspaceMemberList({ inviteType });
      void getAccountUsageDetails();
    } catch (error: unknown) {
      showErrorToast(error as AxiosError<CustomAxiosError>);
    }
  };

  const launcher = (
    <Inline gutter=".8rem" align="center">
      <Typography>{_capitalize(role)}</Typography>
      <BiChevronDown />
    </Inline>
  );

  return (
    <PadBox padding="2.4rem" gutter="2.4rem" as={WorkspaceContainer}>
      <Stack gutter="2rem">
        <Stack gutter=".8rem">
          <Typography fontWeight={700}>
            {' '}
            {inviteType === 'embed' ? 'Add user' : 'Invite user'} by email
          </Typography>

          <form onSubmit={handleSubmit(sendInvite)}>
            <Inline align="center">
              <InlineChildrenContainer>
                <TextFieldContainer width="35rem">
                  <TextField
                    widthFull
                    name="email"
                    placeholder={`${
                      inviteType === 'embed' ? 'Add user' : 'Invite user'
                    } via email e.g. abc@nected.ai`}
                    control={control}
                    showOutline={false}
                    showErrorIcon={false}
                    showError={false}
                  />
                </TextFieldContainer>

                <PadBox
                  padding={{
                    right: '1.2rem',
                  }}
                >
                  <RoleList
                    launcher={launcher}
                    onClickRole={onClickRole}
                    placement="bottom-start"
                  />
                </PadBox>
              </InlineChildrenContainer>

              <Button id="send-ws-invite-btn" type="submit">
                {inviteType === 'embed' ? 'Add User' : 'Send Invite'}
              </Button>
            </Inline>
          </form>

          {!_isNil(errors.email) && (
            <Typography name="error">{errors.email.message}</Typography>
          )}
        </Stack>

        <WorkspaceMemberListMemoized
          memberList={data ?? []}
          inviteType={inviteType}
          getWorkspaceMemberList={getWorkspaceMemberList}
        />
      </Stack>
    </PadBox>
  );
}
