import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {
  Control,
  UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Dataset, TextInput, Typography } from 'ui';

import {
  convertArrayToString,
  getFieldValueAsArray,
} from '../../../utils/common';
import { EXTRACT_TOKEN_REGEX } from '../../../utils/regex';
import { useOpenJsonEditorSheet } from '../../Rules/hooks/useOpenJsonEditor';
import { AlignedText, PillStyled } from './PredefineValueSelection.styled';
import { schemaSavingErrorAtom } from './createModuleSchemaSheet';

type PredefineValueSelectionProps = {
  control: Control<any>;
  index: number;
  setValue?: UseFormSetValue<any>;
  disabled?: boolean;
  dataSet?: Record<string, Dataset>;
  name: string;
  returnTypeName?: string;
  executedValueName?: string;
  onClose?: () => void;
};

export const PredefineValueSelection = (data: PredefineValueSelectionProps) => {
  const value =
    useWatch({
      name: `${data.name}`,
      control: data.control,
    }) ?? null;

  const { fieldState } = useController({
    name: `${data.name}`,
    control: data.control,
  });

  const errorMessage = fieldState.error?.message;

  return (
    <Stack gutter="0.5rem">
      <TextInput
        readOnly
        placeholder="Enter values"
        value={convertArrayToString(getFieldValueAsArray(value))}
        hasError={!_isNil(errorMessage) && !_isEmpty(errorMessage)}
        showErrorIcon={false}
        icon={<ListPill {...data} />}
      />
      <Typography name="errorXs">{errorMessage}</Typography>
    </Stack>
  );
};

export function ListPill({
  control,
  setValue,
  disabled = false,
  dataSet,
  name,
  index,
}: PredefineValueSelectionProps) {
  const [, setSchemaSavingError] = useAtom(schemaSavingErrorAtom);

  const { openWithProps: openJsonEditor } = useOpenJsonEditorSheet({
    name,
    control,
    index: 0,
    type: 'list',
  });

  const { fieldState } = useController({
    name,
    control,
  });

  const dataType: Record<string, any> | null = useWatch({
    name: `moduleSchema.${index}.dataType`,
    control,
  });

  const errorMessage = fieldState.error?.message;

  const onChangeSpecial = (val: any) => {
    let newVal = val;
    try {
      const tokens = (typeof val === 'string' ? val : '').match(
        EXTRACT_TOKEN_REGEX
      );

      if (_isNil(tokens) || _isEmpty(tokens)) {
        newVal = JSON.parse(val);
      }
    } catch {}

    if (!_isNil(setValue)) {
      setValue(name, newVal);
    }

    setSchemaSavingError('you have unsaved changes');
  };

  return (
    <Stack gutter={2}>
      <PillStyled
        padding={['0.4rem', '1rem']}
        onClick={() =>
          openJsonEditor({
            name,
            control,
            dataSet,
            setOriginalValue: setValue,
            entityName: 'module',
            disabled,
            onChangeSpecial,
            editorMetaInfo: {
              defaultOpenNoCode: true,
              restrictDataTypeInList: dataType?.value ?? 'string',
            },
          })
        }
        $hasError={!_isEmpty(errorMessage)}
      >
        <AlignedText fontWeight={700}>Values</AlignedText>
        <MdEdit />
      </PillStyled>
    </Stack>
  );
}
