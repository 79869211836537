import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import AceEditor from 'react-ace';
import { Control, useWatch } from 'react-hook-form';
import { TextInput, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import {
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import { versionInfoRuleAtom } from '../../atom/atom';
import { TriggerApi } from '../Trigger API/TriggerApi';
import { JsonContainerStyled } from './TriggerSheet.styled';

type WorkflowTriggerFormProps = {
  bodyExample: string;
  control?: Control<any>;
  nameByType: string;
  credentialKey: string;
  triggerUrl: string;
  value: any;
};

export function WorkflowTriggerForm({
  bodyExample,
  credentialKey,
  nameByType,
  control,
  triggerUrl,
  value,
}: WorkflowTriggerFormProps) {
  const [siteConstant] = useAtom(siteConstantsAtom);

  const [versionInfoRule] = useAtom(versionInfoRuleAtom);
  const currentLiveVersion = versionInfoRule?.currentLiveVersion;

  const execValue = useWatch({
    control,
    name: `${nameByType}.staticUrl`,
  });

  const status = useWatch({
    control,
    name: `status`,
  });

  return (
    <>
      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Base URL</Typography>
          <TooltipReact id="baseurl-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBaseURL')}
          </TooltipReact>
        </Inline>

        <TextInput
          value={handleChangeApiToWebhookUrl(
            execValue,
            status === 'published' ? 'production' : 'staging',
            currentLiveVersion
          )}
          disabled
        />
      </Stack>

      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Body Params</Typography>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBodyParams')}
          </TooltipReact>
        </Inline>

        <Typography>
          <span>
            There is no need to provide an environment and version; the default
            environment for testing is staging and for version it would be
            draft.
          </span>
        </Typography>

        <JsonContainerStyled>
          {bodyExample.length > 0 && (
            <AceEditor
              className="json-result-readonly"
              mode="json"
              theme="chrome"
              width="100%"
              fontSize={12}
              showPrintMargin={false}
              highlightActiveLine={true}
              showGutter={true}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              readOnly
              value={bodyExample}
            />
          )}
        </JsonContainerStyled>
      </Stack>

      <TriggerApi
        authType={_isNil(value) ? '' : value}
        credentialKey={credentialKey}
        triggerUrl={triggerUrl}
      />
    </>
  );
}
