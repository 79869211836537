import { Column, Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ColumnStyled = styled(Column)`
  block-size: 25rem;
`;

export const PluginContainer = styled(Inline)`
  padding: 0.8rem 1.6rem;
  border: 1px solid var(--color-solitude);
  border-radius: 0.4rem;
`;

export const EditorContainer = styled.div<{ databaseName: string }>`
  border-radius: 6px;
  max-height: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 5px;
  min-height: 31rem;
`;
