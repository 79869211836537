import { PadBox } from '@bedrock-layout/primitives';
import { Typography } from 'ui';

import { StyledLink } from './EmptyPredefineValue.styled';

type EmptyPredefineValueComponentProps = {
  moduleId: string;
  attributeId: string;
};
export const EmptyPredefineValueComponent = ({
  moduleId,
  attributeId,
}: EmptyPredefineValueComponentProps) => {
  const wsid = window.sessionStorage.getItem('workspaceUUID') ?? '';

  return (
    <PadBox padding={['0.5rem', '1rem']}>
      <Typography name="paragraphSmall">
        No predefined values created for this attribute, Add Values in
        attributes from
        <StyledLink
          to={`module/${moduleId}?type=edit&wsid=${wsid}&attributeId=${attributeId}`}
          target="_blank"
        >
          Attribute Library
        </StyledLink>
      </Typography>
    </PadBox>
  );
};
