import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const TokenSelectionContainer = styled(Stack)<{ size: string }>`
  inline-size: ${({ size }) => size};
  max-block-size: 40rem;
  overflow-y: auto;

  .tabStyled {
    flex: 1;
  }
`;

export const InfoComponentContainer = styled(Inline)`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary2);
  color: var(--color-black);
`;
