import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiEdit } from 'react-icons/bi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  IconButton,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import {
  EntityInfoStyled,
  EntityNameContainer,
  NameStyled,
} from '../../components/EntityHeader/EntityHeader.styled';
import {
  EntityInfoModalFormData,
  EntityInfoUpdateModal,
} from '../../components/EntityHeader/EntityInfoUpdateModal';
import { ListingComponent } from '../../components/Listing';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../utils/common';
import { maxFiftyCharactersRule } from '../../utils/validation';
import { DeleteRuleModal } from '../Rules/components/RulesTable/DeleteRuleModal/DeleteRuleModal';
import { CreateModuleSchemaSheet } from './components/createModuleSchemaSheet';
import { CreateModuleSchema } from './createModuleSchema';
import { useDeleteModuleSchema } from './hooks/graphql/useDeleteModuleSchema';
import {
  TableSchemaResponseModel,
  useGetSchemaData,
} from './hooks/graphql/useGetSchemaData';
import { useGetModuleById } from './hooks/graphql/useGetSingleModule';
import { useUpdateModule } from './hooks/graphql/useUpdateModule';
import { ModuleProps } from './types';

export function CEModule(props: ModuleProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  const attributeId = searchParams.get('attributeId');

  const [refreshListData, setRefreshListData] = useState(false);
  const [getSchemaData, { data, refetch: refetchSchemaList }] =
    useGetSchemaData();
  const { subModules, permissions } = props;
  const { siteMeta } = useGetSiteMeta();
  const [listData, setListData] = useState<
    TableSchemaResponseModel | undefined
  >();
  const [moduleName, setModuleName] = useState('');
  const [moduleDescripition, setModuleDescripition] = useState('');
  const [localAttrId, setLocalAttrId] = useState<string>();

  const [getModule, { data: moduleData }] = useGetModuleById();
  const [deleteModuleSchema] = useDeleteModuleSchema();
  const { moduleId } = useParams();
  const isReadOnly = false;
  const [updateModule] = useUpdateModule();

  useEffect(() => {
    setLocalAttrId(attributeId ?? '');
  }, [attributeId]);

  useEffect(() => {
    if (!_isNil(data) && !_isEmpty(data)) {
      setListData(data);
    }

    if (!_isNil(moduleId)) {
      void getModule({ variables: { id: moduleId } });
    } else {
      window.history.back();
    }
  }, [data]);

  const { openWithProps: createSchemaSheet } = useLayer(
    <CreateModuleSchemaSheet
      moduleId={moduleId ?? ''}
      moduleName={moduleName}
      refetchSchemaList={refetchSchemaList}
    />
  );

  useEffect(() => {
    if (!_isEmpty(localAttrId)) {
      const schemaData = data?.getModuleSchema?.data.find(
        (obj) => obj.id === localAttrId
      );

      if (!_isNil(schemaData)) {
        createSchemaSheet({
          moduleId,
          moduleName,
          refetchSchemaList,
          schemaData,
        });
      }

      setLocalAttrId('');
    }
  }, [data, localAttrId]);

  useEffect(() => {
    if (action === 'add' && !_isNil(moduleName) && !_isEmpty(moduleName)) {
      createSchemaSheet({ moduleId, moduleName, refetchSchemaList });
    }
  }, [action, moduleName]);

  useEffect(() => {
    if (!_isNil(moduleData) && !_isNil(moduleData.getModule?.data[0])) {
      const moduleDataObj = moduleData.getModule.data[0];
      setModuleName(moduleDataObj.name);
      setModuleDescripition(moduleDataObj.description ?? '');
      handleSetCheckSumByEntityName('module', moduleDataObj.checksum);
    }
  }, [moduleData]);
  const { close: closeModal } = useCurrentLayer();
  const { openWithProps } = useLayer(
    <EntityInfoUpdateModal
      entityName="Group"
      name={moduleName}
      description={moduleDescripition}
    />
  );

  const handleUpdateModule = async (data: Record<string, any>) => {
    if (_isNil(data.name) || _isEmpty(data.name)) {
      toasts.error('Group name is required', 'module-name');

      return;
    } else if (data.description.length > maxFiftyCharactersRule.value) {
      return;
    }

    try {
      const checksum = handleGetCheckSumByEntityName('module');

      const payload: Record<string, any> = {
        id: moduleId,
        name: data.name,
        description: data.description ?? '',
        checksum: checksum ?? '',
      };
      const response = await updateModule({
        variables: { ...payload },
      });

      if (
        !_isNil(response) &&
        !_isNil(response?.data) &&
        !_isNil(response?.data.updateModule)
      ) {
        setModuleDescripition(response?.data.updateModule.name.description);
        setModuleName(response?.data.updateModule.name);
        handleSetCheckSumByEntityName(
          'module',
          response?.data.updateModule.checksum
        );
        closeModal();
      }
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };

  const HeaderComponent = useCallback(() => {
    return (
      <Inline gutter={25}>
        <Stack>
          <EntityInfoStyled
            onClick={() => {
              if (isReadOnly) {
                return;
              }

              openWithProps({ moduleName, moduleDescripition, onSave });
            }}
          >
            <EntityNameContainer justify="start" align="center" gutter="0.5rem">
              <IconButton disabled={isReadOnly}>
                <BiEdit size={20} color="var(--color-darkGray)" />
              </IconButton>

              <TooltipReact
                id="action-rest-base-url"
                placement="bottom-start"
                customStyles={{
                  wordBreak: 'break-word',
                }}
                launcher={
                  <NameStyled name="heading3" fontWeight={700}>
                    {moduleName}
                  </NameStyled>
                }
              >
                <Stack gutter={'1rem'}>
                  <Typography>{moduleName}</Typography>
                </Stack>
              </TooltipReact>
            </EntityNameContainer>
          </EntityInfoStyled>
        </Stack>
        <CreateModuleSchema
          moduleId={moduleId ?? ''}
          moduleName={moduleName}
          refetchSchemaList={refetchSchemaList}
        />
      </Inline>
    );
  }, [moduleName, moduleDescripition, moduleId]);

  const onSave = async (data: EntityInfoModalFormData) => {
    if (!_isNil(data)) {
      await handleUpdateModule(data);
    }
  };

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteRuleModal
      title="Delete Attribute"
      description={
        'you sure you want to delete the attribute? It might be used in any rules/workflow that might fail.'
      }
    />
  );

  const handleDeleteModuleSchema = async (id: string, checksum: string) => {
    handleSetCheckSumByEntityName('moduleSchema', checksum);

    try {
      await deleteModuleSchema({
        variables: {
          id,
          checksum,
        },
      });

      toasts.success('Attribute deleted successfully', 'deleted-success');
      const response = await refetchSchemaList();
      setListData(response.data);
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to schema module', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleDeleteActionClick = async (data: any) => {
    await handleDeleteModuleSchema(data.id, data.checksum);
  };

  const handleActionColumnClick = useCallback((actionData: any) => {
    const { type, data } = actionData;

    if (type === 'delete') {
      openDeleteConfirmationModal({
        onDeleteClick: async () => await handleDeleteActionClick(data),
      });
    }
  }, []);

  const handleRowClick = (data: any) => {
    if (!_isNil(data.id)) {
      createSchemaSheet({
        moduleId,
        moduleName,
        refetchSchemaList,
        schemaData: data,
      });
    }
  };

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>

      <ListingComponent
        entity={'moduleSchema'}
        entityId={moduleId}
        handleGoBack={() => {
          if (!_isNil(window.sessionStorage.getItem('module-back-url'))) {
            navigate(
              window.sessionStorage.getItem('module-back-url') ?? '/module'
            );
          } else {
            window.history.back();
          }
        }}
        description={moduleDescripition}
        HeaderComponent={HeaderComponent}
        headerTitle=""
        callOnRowClick={handleRowClick}
        callOnCellClick={() => {}}
        errorTitle="No attribute found"
        isGraphQL={true}
        getListDataQuery={getSchemaData}
        queryListData={listData}
        handleActionItemClick={handleActionColumnClick}
        permissions={permissions}
        subModules={subModules}
        isCreateDisable={isReadOnly}
        refreshListData={refreshListData}
        setRefreshListData={setRefreshListData}
      />
    </PagesBodyContainer>
  );
}
