import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { CheckboxInput, Typography, toasts } from 'ui';

import { simpleRuleNodeIdAtom } from '../../..';
import { onClickTokenSelectionArgs } from '../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { convertCaSampleValues } from '../../../../../utils/common';
import { useOpenNonPrimitiveDataType } from '../../../hooks/useOpenNonPrimitiveDataType';
import {
  getDefaultValueByDataTypeForRhsOutput,
  getResultKeyName,
} from '../../../utils/common';
import { DataActionsType } from '../../DataAddAction/DataActionsType';
import { ResultAddDataModel } from '../models';
import DataActions from './DataActions';
import { DataParams } from './DataParams';
import { DataParamsType } from './DataParamsTypePopover';
import { sendEventToGTMType } from './Results';
import { BlockStyled } from './Results.styled';

type ElseProps = Omit<UseControllerProps, 'name'> & {
  setValue?: UseFormSetValue<any>;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
};

const defaultValues: ResultAddDataModel = {
  keyName: 'key_name_1',
  value: '',
  dataType: '',
  returnType: '',
  executedValue: '',
};

export function Else({ control, setValue, handleSendEventToGTM }: ElseProps) {
  const [id] = useAtom(simpleRuleNodeIdAtom);

  const fields = useWatch({
    control,
    name: 'elseDataParams',
  });

  const {
    fields: actionFields,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    name: 'elseActionParams',
    control,
  });

  const [open, setOpen] = useState(false);

  const { handleAddNonPrimitiveDataType } = useOpenNonPrimitiveDataType({
    control,
    section: 'elseDataParams',
    fields,
    setValue,
  });

  const handleAddActionElement = (connectorId: string) => {
    appendAction({
      name: getResultKeyName(actionFields, 'action_'),
      connectorId,
      config: {
        query: '',
      },
    });
  };

  const handleDeleteDataParam = (index: number) => {
    const updatedDataParams = [...fields];
    updatedDataParams.splice(index, 1);

    if (!_isNil(setValue)) {
      setValue('elseDataParams', updatedDataParams);
    }
  };

  const appendDataParams = (dataParamObj: Record<string, any>) => {
    const updatedDataParams = [...structuredClone(fields), dataParamObj];

    if (!_isNil(setValue)) {
      setValue('elseDataParams', updatedDataParams);
    }
  };

  const checkIsKeyNameUnique = (name?: string) => {
    let exists = false;
    fields.forEach((currentField: any) => {
      if (currentField.keyName === name) {
        exists = true;
      }
    });

    return !exists;
  };

  const handleAddDataParam = (
    dataType: string,
    predefinedTokenData?: onClickTokenSelectionArgs
  ) => {
    const name = fields.length === 0 ? 'key_name_1' : getResultKeyName(fields);

    if (!_isNil(predefinedTokenData)) {
      const isKeyNameUnique = checkIsKeyNameUnique(
        predefinedTokenData.schemaName
      );

      if (!isKeyNameUnique) {
        toasts.infoBlue(
          'Cannot add schema with same key name',
          'keyname-unique'
        );

        return {
          isKeyNameUnique,
        };
      }
    }

    appendDataParams({
      ...defaultValues,
      dataType,
      keyName: predefinedTokenData?.schemaName ?? name,
      executedValue: convertCaSampleValues(
        dataType,
        getDefaultValueByDataTypeForRhsOutput(dataType)
      ),
      value: getDefaultValueByDataTypeForRhsOutput(dataType),
      schemaId: predefinedTokenData?.schemaId,
    });

    handleSendEventToGTM({
      action: 'selection',
      element: 'data',
      actionName: dataType,
    });

    if (['json', 'jsFormula', 'excelFormula', 'list'].includes(dataType)) {
      handleAddNonPrimitiveDataType(dataType);
    }

    return {
      isKeyNameUnique: true,
    };
  };

  return (
    <Inline align="center" gutter={10} minItemWidth={30}>
      <Inline justify="end">
        <Typography>Else</Typography>
      </Inline>

      <BlockStyled padding={[16, 20, 16, 10]}>
        <Stack gutter={10}>
          <Inline gutter={16}>
            <Inline align="center" gutter={8}>
              <CheckboxInput
                // kept this checked as this won't change ever it'll be remain checked
                checked
                onChange={() => {
                  // TODO: do something
                }}
              />
              <Typography>DefaultValue</Typography>
            </Inline>

            <Inline align="center" gutter={8}>
              <CheckboxInput
                // kept this checked as this won't change ever it'll be remain checked
                checked
                onChange={() => {
                  // TODO: do something
                }}
              />
              <Typography>False</Typography>
            </Inline>

            <Inline gutter="0.8rem" align="center">
              {fields.length === 0 && (
                <DataParamsType
                  control={control}
                  handleAddDataParam={handleAddDataParam}
                />
              )}
              {actionFields.length === 0 && (
                <DataActionsType
                  ruleId={id}
                  control={control}
                  ruleType="simpleRule"
                  addAction={(value, id) => {
                    appendAction({
                      name: 'action_1',
                      connectorId: id,
                      config: {
                        query: '',
                      },
                    });

                    handleSendEventToGTM({
                      action: 'selection',
                      element: 'action',
                      actionName: value,
                    });

                    setOpen(true);
                  }}
                />
              )}
            </Inline>
          </Inline>
          <Stack>
            {fields.map((param: Record<string, any>, index: number) => (
              <DataParams
                control={control}
                index={index}
                key={index.toString()}
                isLast={index === fields.length - 1}
                onAddDataClick={handleAddDataParam}
                dataType={param.dataType}
                type="elseDataParams"
                handleDeleteElement={(index) => handleDeleteDataParam(index)}
                handleSendEventToGTM={handleSendEventToGTM}
                setValue={setValue}
              />
            ))}

            {actionFields.map((action: Record<string, any>, index: number) => {
              return (
                <DataActions
                  connectorId={action.connectorId}
                  field={action}
                  control={control}
                  handleDeleteElement={(index) => removeAction(index)}
                  handleAddElement={handleAddActionElement}
                  openFirst={open}
                  index={index}
                  total={actionFields.length}
                  isLast={index === actionFields.length - 1}
                  section="elseActionParams"
                  key={`else_action_${index}_${action.id as string}`}
                  setValue={setValue}
                  handleSendEventToGTM={handleSendEventToGTM}
                />
              );
            })}
          </Stack>
        </Stack>
      </BlockStyled>
    </Inline>
  );
}
