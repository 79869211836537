import { Center, Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import React, { useCallback, useMemo, useState } from 'react';
import {
  BiChevronDown,
  BiChevronRight,
  BiCube,
  BiRefresh,
  BiSearch,
} from 'react-icons/bi';
import { TbTable } from 'react-icons/tb';
import { VscSymbolField } from 'react-icons/vsc';
import { TextInput, Typography } from 'ui';

import {
  ColumnItem,
  Container,
  FieldName,
  Header,
  HeaderContent,
  PillStyledList,
  RefreshButton,
  SearchContainer,
  TableHeader,
  TableItem,
} from './SchemaViewer.styled';
import { SchemaViewerProps } from './types';

const SchemaViewer = React.memo(
  ({
    schema = {},
    dbType = 'mysql',
    onQueryGenerated,
    onColumnClick,
    getDataSetSchema,
    isLoading = false,
    method = 'none',
    updatedDataSet,
    placeholder = 'Search and Select',
    schemaHeading = 'Schema',
  }: SchemaViewerProps) => {
    const [expandedTables, setExpandedTables] = useState<
      Record<string, boolean>
    >({});
    const [searchTerm, setSearchTerm] = useState('');

    const handleRefresh = useCallback(async () => {
      if (typeof getDataSetSchema === 'function') {
        await getDataSetSchema(true);
      }
    }, [getDataSetSchema]);

    const filteredSchemaEntries = useMemo(() => {
      const entries = Object.entries(schema).filter(
        ([_, tableData]) =>
          !_isNil(tableData) &&
          typeof tableData === 'object' &&
          tableData.attributes
      );

      if (searchTerm === '') return entries;

      return entries.filter(([_, tableData]) => {
        const tableName = tableData.name.toLowerCase();
        const columnNames = Object.values(tableData.attributes).map((attr) =>
          attr.name.toLowerCase()
        );

        const searchLower = searchTerm.toLowerCase();

        return (
          tableName.includes(searchLower) ||
          columnNames.some((col) => col.includes(searchLower))
        );
      });
    }, [schema, searchTerm]);

    const toggleTable = useCallback((tableName: string): void => {
      setExpandedTables((prev) => ({
        ...prev,
        [tableName]: !prev[tableName],
      }));
    }, []);

    const handleColumnClick = useCallback(
      (tableName: string, columnName: string) => {
        if (typeof onColumnClick === 'function') {
          onColumnClick(tableName, columnName);
        }
      },
      [onColumnClick]
    );

    if (_isNil(schema) || typeof schema !== 'object') {
      return (
        <Container>
          <Center>
            <Inline gutter="size3" align="center">
              <BiCube size={20} />
              <span>No schema data available</span>
            </Inline>
          </Center>
        </Container>
      );
    }

    const searchButton = useMemo(
      () => (
        <Stack gutter={2}>
          <PillStyledList padding={['0.4rem', '1rem']}>
            <BiSearch size={16} />
          </PillStyledList>
        </Stack>
      ),
      []
    );

    const handleSearch = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
      },
      []
    );

    return (
      <Container>
        <Stack gutter="0rem">
          <Header>
            <HeaderContent>
              {!_isNil(schemaHeading) && (
                <Inline gutter="size3" align="center">
                  <BiCube size={20} />
                  <Typography>{schemaHeading}</Typography>
                </Inline>
              )}

              {typeof getDataSetSchema === 'function' &&
                !_isNil(getDataSetSchema) && (
                  <RefreshButton
                    onClick={handleRefresh}
                    disabled={isLoading}
                    title="Refresh Schema"
                  >
                    <BiRefresh
                      size={20}
                      className={isLoading ? 'animate-spin' : ''}
                    />
                  </RefreshButton>
                )}
            </HeaderContent>
          </Header>

          <SearchContainer>
            <TextInput
              placeholder={placeholder}
              value={searchTerm}
              onChange={handleSearch}
              icon={searchButton}
            />
          </SearchContainer>

          <Stack
            gutter="size2"
            style={{
              maxBlockSize: '45rem',
              overflow: 'auto',
            }}
          >
            {filteredSchemaEntries.map(([tableName, tableData]) => (
              <TableItem key={tableName}>
                <Stack gutter="size2">
                  <TableHeader onClick={() => toggleTable(tableName)}>
                    <Inline gutter="size3" align="center">
                      {expandedTables[tableName] ? (
                        <BiChevronDown size={16} />
                      ) : (
                        <BiChevronRight size={16} />
                      )}
                      <TbTable size={16} />
                      <Typography>{tableData.name}</Typography>
                    </Inline>
                  </TableHeader>

                  {expandedTables[tableName] && (
                    <Stack gutter="size3">
                      <Stack gutter="size1">
                        {Object.values(tableData.attributes)
                          .filter(
                            (attr) =>
                              searchTerm === '' ||
                              attr.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                          )
                          .map((attr) => (
                            <ColumnItem
                              key={`${tableName}-${attr.name}`}
                              onClick={() =>
                                handleColumnClick(tableName, attr.name)
                              }
                            >
                              <Inline gutter="size2" align="center">
                                <VscSymbolField size={16} />
                                <FieldName>
                                  <Typography name="secondaryXsDark">
                                    {attr.name}
                                  </Typography>
                                  <Typography name="secondarySm">
                                    ({attr.dataType})
                                  </Typography>
                                </FieldName>
                              </Inline>
                            </ColumnItem>
                          ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </TableItem>
            ))}
          </Stack>
        </Stack>
      </Container>
    );
  }
);

SchemaViewer.displayName = 'SchemaViewer';

export default SchemaViewer;
