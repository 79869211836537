import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

export const ErrorTextStyled = styled.span`
  color: var(--color-fireEngineRed);
`;

export const FieldStyled = styled(Stack)<{
  $heightFull: boolean;
  $height: string;
  $widthFull: false;
}>`
  ${({ $heightFull = false }) => $heightFull && 'height: 100%'};
  ${({ $widthFull = false }) => $widthFull && 'width: 100%'};
  ${({ $height }) => !_isEmpty($height) && `height: ${$height}`};
`;
