import { ApolloError } from '@apollo/client';
import { PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Sheet,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  WorkflowNodeSheetHeader,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import {
  checksumWarningAtom,
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../atom';
import {
  DynamicPanelContainer,
  EditorPanelContainer,
  SidePanelContainer,
} from '../../../components/CommonStyles/CommonStyles.styled';
import { EntityDependencyUsage } from '../../../components/EntityDependencyUsage/EntityDependencyUsage';
import { EntityHeader } from '../../../components/EntityHeader/EntityHeader';
import { FAQMenu } from '../../../components/FAQMenu';
import { getValueFromObject } from '../../../components/Listing/utils';
import { Panel } from '../../../components/Panel/Panel';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { VersionControl } from '../../../components/VersionControl/VersionControl';
import { tabList } from '../../../components/VersionControl/constant';
import { StackAsItem } from '../../../components/layouts/Stack.styled';
import { getUserState } from '../../../hooks/getUserState';
import { useGetConnectorById } from '../../../hooks/graphql/useGetConnectorById';
import { useHandleRequests } from '../../../hooks/useHandleRequests';
import { useLayerCloseOnPath } from '../../../hooks/useLayerCloseOnPath';
import type { EntityType } from '../../../hooks/useLayerCloseOnPath';
import { useUpdateAttributesOnResize } from '../../../hooks/useUpdateAttributesOnResize';
import { useUpdateMappedConnectorsData } from '../../../hooks/useUpdateMappedConnectorsData';
import type { TimerId } from '../../../types';
import {
  checksumMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
  showGraphQlErrorToast,
} from '../../../utils/common';
import type { RuleEnvironment } from '../../../utils/constant';
import {
  ENTITY_ID,
  Environment,
  SCHEMA_NOT_TO_BE_FETCHED_FOR,
  configureDatabase,
} from '../../../utils/constant';
import { updateWidgetState } from '../../Home/components/sub-components/UpdateWidgetState';
import type { ConnectorAndPluginModel } from '../../Integrations/types';
import { getDataSetsQueryByName } from '../../Rules/utils/common';
import {
  activePanelDatasetAtom,
  vcListTabIndexDatasetAtom,
} from '../atom/atom';
import { useCreateDataSet } from '../hooks/useCreateDataSet';
import { useGetDataSet } from '../hooks/useGetDataSet';
import { useGetDataSetSchema } from '../hooks/useGetDataSetSchema';
import type { UseGetDataSetsReturnType } from '../hooks/useGetDataSets';
import { useGetPublishedConnectors } from '../hooks/useGetPublishedConnectors';
import { useTestDataSet } from '../hooks/useTestDataSet';
import { useUpdateDataSet } from '../hooks/useUpdateDataSet';
import { useUpdateDataSetStatus } from '../hooks/useUpdateDataSetStatus';
import { useWatchFields } from '../hooks/useWatchFields';
import { dataSetSchema } from '../schema';
import type { CreateDataSetFormValues } from '../types';
import { dataSetInitialFormValues, transformDatasetData } from '../utils';
import { DataSetFooter } from './DataSetFooter';
import { DataSetForm } from './DataSetForm';
import { FormStyled } from './DataSetForm.styled';
import { DataSetSettingForm } from './DataSetSettingForm';
import { DatasetEditorContainer } from './DataSetsList.styled';
import { DsVersionInfo } from './DsVersionInfo/DsVersionInfo';

export type DataSetSheetProps = {
  connector?: ConnectorAndPluginModel;
  id?: string;
  isLive?: boolean;
  version?: string;
  commitId?: string;
  refetch?: UseGetDataSetsReturnType[1]['refetch'];
  viewLiveParam?: string;
  from: string;
  onPublish?: () => void;
  onUnmount?: () => void;
};

export function DataSetSheet({
  connector,
  id,
  version,
  refetch,
  isLive = false,
  commitId,
  viewLiveParam,
  from,
  onPublish,
  onUnmount,
}: DataSetSheetProps) {
  const outputRef = useRef<HTMLDivElement>(null);
  const [dataSetId, setDataSetId] = useState(id ?? '');
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentEnvironment] = useState<RuleEnvironment>(
    Environment.PRODUCTION
  );
  const [currentConnector, setCurrentCurrentConnector] = useState<
    ConnectorAndPluginModel | undefined
  >(connector);
  const [enableAutoSave, setEnableAutoSave] = useState(false);

  const { openWithProps: openDataSheet, closeAllLayers } = useLayer(
    <DataSetSheet id={id} connector={connector} isLive from={'viewLive'} />
  );
  const { close: closeDataSheet } = useCurrentLayer();
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [connectorList, setConnectorList] = useState<any[]>([]);
  const [activePanel, setActivePanel] = useAtom(activePanelDatasetAtom);
  const [vcListTabIndex, setVCListTabIndex] = useAtom(
    vcListTabIndexDatasetAtom
  );

  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  // Setting counter so that we can prevent create api call on first render
  const [counter, setCounter] = useState(0);

  useUpdateAttributesOnResize({
    attributes: ['height'],
    containerClassName: 'dataset-editor-container',
    dependentClassNameList: [
      'dataset-header-container',
      'dataset-footer-container',
      'dataset-tab-list-container',
      'ds-version-info',
    ],
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<CreateDataSetFormValues>({
    resolver: zodResolver(dataSetSchema),
    defaultValues: {
      ...dataSetInitialFormValues,
      query: getDataSetsQueryByName(siteConstants, connector),
    },
    mode: 'onChange',
  });

  const { data: connectorData, refetch: refetchConnectors } =
    useGetPublishedConnectors(true);

  const pluginName = connector?.plugin.name ?? '';

  useEffect(() => {
    if (!_isNil(connectorData)) {
      const publishedConnectors = connectorData.getConnector.data
        .filter((connector) => connector.plugin.name === pluginName)
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [connectorData, pluginName]);

  const connectorId = _isNil(connector) ? '' : connector.id;

  const { createDataSet, loading: isCreateDataSetLoading } =
    useCreateDataSet(connectorId);

  const { updateDataSetStatus, dataSetStatus } = useUpdateDataSetStatus();

  const { updateDataSet, loading: isUpdateDataSetLoading } = useUpdateDataSet();

  const {
    loading: isDataSetLoading,
    datasetObj,
    getDataSetByIdOnPublish,
    handleGetWorkflowAfterStateTransition,
  } = useGetDataSet(dataSetId, setValue, updateDataSetStatus, isLive, commitId);

  const isMutating = isCreateDataSetLoading || isUpdateDataSetLoading;

  const { schemas, getDataSetSchema } = useGetDataSetSchema(
    connectorId,
    currentEnvironment,
    !SCHEMA_NOT_TO_BE_FETCHED_FOR.includes(pluginName)
  );

  const [getConnectorById] = useGetConnectorById();

  const {
    dataSetName,
    description,
    query,
    params,
    productionSettings,
    isEditableValue,
    statusValue,
    versionInfo,
    approvalInfo,
  } = useWatchFields(control);

  const { fetchMappedConnectorsData } = useUpdateMappedConnectorsData();

  const {
    testDataSet,
    error: testError,
    data: testData,
    loading: isTestInProgress,
  } = useTestDataSet(
    dataSetId,
    updateDataSetStatus,
    datasetObj,
    pluginName,
    setValue,
    versionInfo?.currentVersion
  );

  const integration = watch('integration');

  const { isHide: isEditDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.datasets],
    entityStatus: statusValue,
  });

  const { appendRequest, removeRequest } = useHandleRequests({
    onResolve: async () => await autoSaveForm(),
    hasActivity: isMutating,
  });

  useEffect(() => {
    const updatedVcListTabIndex = tabList.findIndex(
      (tabObj) => tabObj.value === statusValue
    );

    setVCListTabIndex(updatedVcListTabIndex !== -1 ? updatedVcListTabIndex : 0);
  }, [statusValue]);

  useEffect(() => {
    setValue('editable', !isEditDisable);
  }, [isEditDisable]);

  useEffect(() => {
    if (!_isNil(integration) && !_isNil(currentConnector)) {
      const status =
        currentConnector.staging.isTested && currentConnector.staging.isPublish;

      setUsedConnectorMapping({
        [integration.value]: {
          status,
          source: [],
        },
      });
    }
  }, [JSON.stringify(integration), JSON.stringify(currentConnector)]);

  const validateUsedConnectors = async () => {
    let error = false;

    try {
      const response = await fetchMappedConnectorsData();

      const data = response?.getConnector?.data ?? [];

      if (!_isNil(data) && !_isEmpty(data)) {
        const connector = data[0];

        error =
          !(connector.staging.isTested as boolean) ||
          !(connector.staging.isPublish as boolean);
      }
    } catch (err) {}

    return error;
  };

  const goBacktoEditor = (currentStage: string) => {
    navigate(`/datasets/${dataSetId}?stage=${currentStage}`);
  };

  const openViewLiveSheet = () => {
    openDataSheet({ id: dataSetId });
  };

  const onSubmit = async (formValues: CreateDataSetFormValues) => {
    if (_isEmpty(dataSetId)) {
      try {
        const { data } = await createDataSet({
          ...transformDatasetData(formValues),
          params,
        });

        if (!isOnboardingCompleted(configureDatabase)) {
          updateWidgetState(configureDatabase)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }

        const dataSetId = data?.createDataSet.id;

        if (!_isNil(data)) {
          handleSetCheckSumByEntityName(
            'datasets',
            data.createDataSet.checksum
          );
        }

        setValue('createdAt', data?.createDataSet.createdAt);

        if (!_isNil(dataSetId)) {
          setDataSetId(dataSetId);
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const checksum = handleGetCheckSumByEntityName('datasets');

        const { data } = await updateDataSet(
          {
            ...transformDatasetData(formValues),
            params,
          },
          dataSetId,
          checksum ?? ''
        );
        // Resetting tested and published status to initial state
        handleSetCheckSumByEntityName('datasets', data?.updateDataSet.checksum);
        setValue('status', data?.updateDataSet.status ?? 'draft');

        setValue('publishedAt', data?.updateDataSet.publishedAt);

        setValue('versionInfo', data?.updateDataSet.versionInfo);
        setValue('approvalInfo', data?.updateDataSet.approvalInfo);
        setValue('isLive', data?.updateDataSet.isLive ?? false);
      } catch (error: unknown) {
        showGraphQlErrorToast(error);

        if (error instanceof Error) {
          if (error.message.includes(checksumMessage)) {
            setShowChecksumPopup({
              showPopup: true,
              metaData: {
                datasetId: id,
                datasetName: datasetObj?.name,
                type: datasetObj?.connector?.plugin.name,
              },
            });
          }
        }
      }
    }

    removeRequest();
  };

  const autoSaveForm = handleSubmit(
    async (formValues) => await onSubmit(formValues)
  );

  const handleEditButton = handleSubmit(
    async (formValues) =>
      await onSubmit({
        ...formValues,
        editMode: true,
      })
  );

  const selectedConnector = watch('integration');

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${pluginName}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`,
      window !== window.parent ? '_self' : '_blank'
    );
  };

  useEffect(() => {
    let submitTimeout: TimerId;
    setCounter(counter + 1);

    if (counter > 1) {
      submitTimeout = setTimeout(() => {
        if (enableAutoSave) {
          void appendRequest();
        } else {
          setEnableAutoSave(true);
        }
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    dataSetName,
    description,
    query,
    JSON.stringify(productionSettings),
    params,
    integration,
  ]);

  useEffect(() => {
    const currentStage = searchParams.get('stage') ?? 'staging';

    if (viewLiveParam === 'viewLive') {
      if (!isLive) {
        closeDataSheet();
        goBacktoEditor(currentStage ?? '');
      } else {
        openViewLiveSheet();
      }
    }
  }, [dataSetStatus]);

  const getDataCurrentConnector = async () => {
    try {
      const { data } = await getConnectorById({
        variables: {
          connectorId: integration?.value ?? connectorId,
        },
      });

      setCurrentCurrentConnector(data?.getConnector.data[0]);

      setValue('integration', {
        label: data?.getConnector.data[0].name ?? '',
        value: data?.getConnector.data[0].id ?? '',
      });
    } catch (error) {}
  };

  useEffect(() => {
    void getDataCurrentConnector();
  }, [JSON.stringify(integration), connector?.id]);

  const handleCloseSheet = () => {
    const closeSheet = searchParams.get('closeSheet');
    const queryParams = window.sessionStorage.getItem('dataSetRedirection');
    window.sessionStorage.removeItem('dataSetRedirection');

    setActivePanel('settings');

    if (
      !['viewLive', 'rules'].includes(from) &&
      !_isEmpty(from) &&
      closeSheet !== 'true'
    ) {
      if (!_isEmpty(queryParams) && !_isNil(queryParams)) {
        navigate(`/datasets${queryParams}`);
      } else {
        if (window !== window.parent) {
          window.history.back();
        } else {
          if (window !== window.parent) {
            window.history.back();
          } else {
            navigate(`/datasets`);
          }
        }
      }
    } else if (!['viewLive', 'rules'].includes(from) && closeSheet === 'true') {
      handleRedirection(queryParams);
    } else if (_isEmpty(from.trim()) && _isNil(closeSheet)) {
      handleRedirection(queryParams);
    } else {
      if (window !== window.parent) {
        window.history.back();
      } else {
        navigate(`/datasets`);
      }
    }

    closeDataSheet();
  };

  const handleRedirection = (queryParams: string | null) => {
    if (!_isEmpty(queryParams) && !_isNil(queryParams)) {
      navigate(`/datasets${queryParams}`);
    } else {
      if (window !== window.parent) {
        window.history.back();
      } else {
        navigate(`/datasets`);
      }
    }
  };

  useLayerCloseOnPath({
    entityName: location.pathname.split('/')[1] as EntityType,
    onRouteChange: () => {
      closeAllLayers();
    },
  });

  const handleAfterDatasetIsPublished = async () => {
    try {
      await getDataSetByIdOnPublish({
        variables: {
          id: dataSetId,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        showGraphQlErrorToast(error);
      }
    }
  };

  const handleFetchDataset = () => {
    setEnableAutoSave(false);
    void handleGetWorkflowAfterStateTransition();
  };

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('datasets', data.checksum);
        } else if (key === 'entityPublished') {
          void handleAfterDatasetIsPublished();
        } else if (key === 'formData') {
          const reviewFormData = data.formData.requestReview;

          if (!_isNil(reviewFormData)) {
            setValue('approvalInfo', reviewFormData);
          }
        } else if (key === 'fetchEntity') {
          void handleFetchDataset();
        } else {
          setValue(key as keyof CreateDataSetFormValues, data[key]);
        }
      });
    }
  };

  const openPublishedVersions = () => {
    setActivePanel('versionControl');
    setVCListTabIndex(2);
  };

  const vcComponentId = getValueFromObject(
    plan,
    'plan.versionControl.componentId'
  );

  return (
    <Sheet size="large" onClose={handleCloseSheet}>
      <Stack as={FormStyled} onSubmit={handleSubmit(onSubmit)}>
        <StackAsItem grow={1} gutter={0}>
          <PadBox
            className="dataset-header-container"
            padding="1.6rem"
            style={{ width: '60%' }}
          >
            <WorkflowNodeSheetHeader>
              <EntityHeader
                nameFieldKey="name"
                descriptionFieldKey="description"
                createdAtFieldKey="createdAt"
                publishedAtFieldKey="publishedAt"
                entityName="Data Source"
                status={statusValue}
                type={currentConnector?.plugin.name ?? ''}
                iconUrl={currentConnector?.plugin.imageUrl ?? ''}
                control={control}
                setValue={setValue}
                isReadOnly={!isEditableValue}
                versionInfo={versionInfo}
                openPublishedVersions={openPublishedVersions}
              />
            </WorkflowNodeSheetHeader>
          </PadBox>
          <DsVersionInfo id={dataSetId} status={statusValue} />

          <Tabs>
            <TabList styleClassName="dataset-tab-list-container">
              <Tab>Query</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <DatasetEditorContainer
                  className="dataset-editor-container"
                  $isLive={isLive}
                >
                  <EditorPanelContainer $hasMarginRight={false}>
                    <DataSetForm
                      setError={setError}
                      isQueryValidNected={isQueryValidNected}
                      setIsQueryValidNected={setIsQueryValidNected}
                      connector={currentConnector}
                      control={control}
                      currentEnvironment={currentEnvironment}
                      dataSetId={dataSetId}
                      getDataSetSchema={getDataSetSchema}
                      isDataSetLoading={isDataSetLoading}
                      isTestInProgress={isTestInProgress}
                      schemas={schemas}
                      testData={testData}
                      outputRef={outputRef}
                      testDataSet={async (ref) => {
                        if (_isNil(errors) || _isEmpty(errors)) {
                          await testDataSet(ref);
                        } else {
                          toasts.error(
                            'Error found in settings. Please check',
                            'dataset-form-error'
                          );
                        }
                      }}
                      testError={testError}
                      updateDataSetStatus={updateDataSetStatus}
                      isLive={isLive}
                      from={from}
                      setValue={setValue}
                      refreshConnectors={async () => await refetchConnectors()}
                      connectorList={connectorList}
                      editPlugin={editPlugin}
                      handleEditButton={handleEditButton}
                    />
                  </EditorPanelContainer>

                  {activePanel === 'settings' && (
                    <DynamicPanelContainer className="dataset-settings-container">
                      <StackAsItem
                        grow={1}
                        gutter={0}
                        className="dataset-settings-content"
                      >
                        <DataSetSettingForm
                          control={control}
                          key={currentEnvironment}
                          currentEnvironment={currentEnvironment}
                          isLive={isLive}
                        />
                      </StackAsItem>
                    </DynamicPanelContainer>
                  )}

                  {activePanel === 'versionControl' && (
                    <DynamicPanelContainer
                      id={vcComponentId}
                      data-premium-component-id={vcComponentId}
                      data-premium-component-trigger={getValueFromObject(
                        plan,
                        'plan.versionControl.trigger'
                      )}
                      activePanel={activePanel}
                    >
                      <VersionControl
                        entityInfo={{
                          type: ENTITY_ID.datasets,
                          status: statusValue,
                          name: dataSetName ?? '',
                          id: dataSetId,
                          subType: currentConnector?.plugin.name,
                        }}
                        handleEntityUpdate={handleEntityUpdate}
                        currentTab={vcListTabIndex}
                        modalFormdata={{
                          requestReview: approvalInfo,
                          publishModal: {
                            title: approvalInfo?.title,
                          },
                        }}
                        updateTabIndex={setVCListTabIndex}
                      />
                    </DynamicPanelContainer>
                  )}

                  {activePanel === 'faqMenu' && (
                    <DynamicPanelContainer activePanel={activePanel}>
                      <FAQMenu />
                    </DynamicPanelContainer>
                  )}

                  {activePanel === 'dependencyUsage' && (
                    <DynamicPanelContainer
                      id={getValueFromObject(
                        plan,
                        'plan.dependencyMap.componentId'
                      )}
                      data-premium-component-id={getValueFromObject(
                        plan,
                        'plan.dependencyMap.componentId'
                      )}
                      data-premium-component-trigger={getValueFromObject(
                        plan,
                        'plan.dependencyMap.trigger'
                      )}
                      activePanel={activePanel}
                    >
                      <EntityDependencyUsage
                        entityInfo={{
                          type: ENTITY_ID.datasets,
                          status: statusValue,
                          name: dataSetName ?? '',
                          id: dataSetId ?? '',
                          version: versionInfo?.currentVersion ?? 'draft',
                        }}
                      />
                    </DynamicPanelContainer>
                  )}

                  <SidePanelContainer>
                    <Panel
                      defaultActivePanel={activePanel}
                      onPanelItemClick={setActivePanel}
                    />
                  </SidePanelContainer>
                </DatasetEditorContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <DataSetFooter
            outputRef={outputRef}
            isQueryValidNected={isQueryValidNected}
            setIsQueryValidNected={setIsQueryValidNected}
            version={version}
            dataSetId={dataSetId}
            isCreatingOrUpdating={
              isCreateDataSetLoading || isUpdateDataSetLoading
            }
            dataSetStatus={dataSetStatus}
            updateDataSetStatus={updateDataSetStatus}
            from={from}
            onPublish={onPublish}
            onUnmount={onUnmount}
            datasetObj={datasetObj}
            setValue={setValue}
            control={control}
            handleEntityUpdate={handleEntityUpdate}
            handleEditButton={handleEditButton}
            isDataSetLoading={isDataSetLoading}
            isTestInProgress={isTestInProgress}
            testDataSet={async (ref) => {
              const isConnectorError = await validateUsedConnectors();

              if (isConnectorError) {
                toasts.error(
                  'Seems integration used is not published with staging config, Publish your integration staging config before testing this datasource.',
                  'dataset-connector-error'
                );

                return;
              }

              if (_isNil(errors) || _isEmpty(errors)) {
                await testDataSet(ref);
              } else {
                toasts.error(
                  'Error found in settings. Please check',
                  'dataset-form-error'
                );
              }
            }}
          />
        </StackAsItem>
      </Stack>
    </Sheet>
  );
}
