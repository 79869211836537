import { gql, useLazyQuery } from '@apollo/client';

const workflowStatusByIdQuery = gql`
  query getWorkflow($id: String) {
    getWorkflow(id: $id) {
      data {
        id
        status
      }
    }
  }
`;

export function useGetWorkflowStatusAfterTest() {
  return useLazyQuery(workflowStatusByIdQuery, {
    fetchPolicy: 'no-cache',
  });
}
