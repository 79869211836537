import styled from 'styled-components';

export const CustomAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  position: relative;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-top: 2rem;
`;

export const CustomAppEmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const HideFooter = styled.div`
  height: 51px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 0;
  background: #f5f5f5;
`;

export const GoBackButton = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 9;
`;
