import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import AceEditor from 'react-ace';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BiCopy } from 'react-icons/bi';
import {
  Attributes,
  Dataset,
  IconButton,
  TextInput,
  TooltipReact,
  Typography,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { ResponseCaching } from '../../../../../components/ResponseCaching/ResponseCaching';
import { copyToClipboard, getTooltipText } from '../../../../../utils/common';
import { isWorkflowReadOnlyAtom, workflowIdAtom } from '../../../atoms/atoms';
import {
  ApiContainer,
  Curl,
  JsonContainer,
  ResponseContainer,
  StyledLink,
} from './ApiSettings.styled';

type ApiSettingTabProps = {
  triggerUrl: string;
  staticUrl: string;
  bodyParamsApi: string;
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  dataset?: Record<string, Dataset>;
};

export function ApiSettingTab({
  staticUrl,
  triggerUrl,
  bodyParamsApi,
  control,
  setValue,
  dataset = {},
}: ApiSettingTabProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [workflowId] = useAtom(workflowIdAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const attributes = useWatch({
    control,
    name: 'attributes',
  });

  const triggerName = useWatch({
    control,
    name: 'name',
  });

  const responseParams = {
    type: 'workflow',
    entityId: workflowId ?? undefined,
  };

  const getDataSet: Record<string, Dataset> = useMemo(() => {
    const finalAttributes: Record<string, Attributes> = {};
    attributes.forEach((attr: any) => {
      finalAttributes[attr.name] = {
        dataType: attr?.selectedType?.dataType,
        name: attr?.name,
        executedValue: attr?.executedValue,
        id: attr?.name,
      };
    });

    return {
      [triggerName]: {
        name: triggerName,
        attributes: finalAttributes,
        id: triggerName,
      },
    };
  }, [JSON.stringify(attributes), triggerName]);

  return (
    <>
      <Stack gutter="0.5rem">
        <ResponseContainer>
          <ResponseCaching
            name="settings.cache"
            control={control}
            setValue={setValue}
            dataset={getDataSet}
            responseParams={responseParams}
            disabled={isWorkflowReadOnly}
          />
        </ResponseContainer>
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Base URL</Typography>
          <TooltipReact id="baseurl-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerBaseURL')}
          </TooltipReact>
        </Inline>

        <TextInput
          disabled
          value={staticUrl}
          icon={
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                void copyToClipboard(staticUrl, 'API URL copied');
              }}
            >
              <BiCopy />
            </IconButton>
          }
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Body Params</Typography>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerBodyParams')}
          </TooltipReact>
        </Inline>

        <Typography>
          <span>
            There is no need to provide an environment; the default environment
            for testing is staging.
          </span>
        </Typography>

        <JsonContainer>
          <AceEditor
            className="json-result-readonly"
            mode="json"
            theme="chrome"
            width="100%"
            fontSize={12}
            showPrintMargin={false}
            highlightActiveLine={true}
            showGutter={true}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            readOnly
            value={bodyParamsApi}
          />
        </JsonContainer>

        <Inline align="center">
          <StyledLink
            href={getTooltipText(
              siteConstants,
              'workflow',
              'howToTriggerWorkflow',
              'howToLinks'
            )}
            target={window !== window.parent ? '_self' : '_blank'}
          >
            How to trigger?
          </StyledLink>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerHowTo')}
          </TooltipReact>
        </Inline>

        <ApiContainer stretch="start">
          <Curl justify="start">
            <Typography name="paragraphSmall">
              <pre>{triggerUrl}</pre>
            </Typography>
          </Curl>

          <Inline justify="end">
            <IconButton
              onClick={async () =>
                await copyToClipboard(triggerUrl, 'API copied')
              }
            >
              <BiCopy size={20} />
            </IconButton>
          </Inline>
        </ApiContainer>
      </Stack>
    </>
  );
}
