import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { SchemaDataTypeSelectionProps } from '../types';
import { UsageLauncherContainer } from './UsageSelection.styled';
import { schemaSavingErrorAtom } from './createModuleSchemaSheet';

export const UsageSelection = ({
  control,
  index,
  setValue,
  name,
  isDisabled,
}: SchemaDataTypeSelectionProps) => {
  const ref = useRef<PopoverMethods>(null);

  const [, setSchemaSavingError] = useAtom(schemaSavingErrorAtom);

  const usageValue: string | null = useWatch({
    name: `moduleSchema.${index}.usageType`,
    control,
  });

  const usageOptions = [
    {
      label: 'Mandatory',
      value: 'mandatory',
    },
    {
      label: 'Optional',
      value: 'optional',
    },
  ];

  const handleMenuClick = (value: string) => {
    setValue(`moduleSchema.${index}.usageType`, value);

    setSchemaSavingError('you have unsaved changes');

    ref?.current?.hide();
  };

  const launcher = (
    <UsageLauncherContainer
      $isDisabled={isDisabled}
      align="center"
      gutter="1rem"
    >
      <Typography>
        {usageOptions.find((option) => option.value === usageValue)?.label ??
          'Select Usage'}
      </Typography>
      <FiChevronDown size={20} />
    </UsageLauncherContainer>
  );

  return (
    <Inline>
      <Menu ref={ref} launcher={launcher} onMenuItemClick={handleMenuClick}>
        {usageOptions.map((currentOption) => (
          <MenuItem key={currentOption.value} value={currentOption.value}>
            <Typography>{currentOption.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Inline>
  );
};
