import { gql, useLazyQuery } from '@apollo/client';

const getAllModuleAndSchemaQuery = gql`
  query getModule($perPage: Int, $page: Int) {
    getModule(perPage: $perPage, page: $page) {
      data {
        id
        name
        schemas {
          data {
            id
            name
            dataType
            usageType
            options {
              value
            }
          }
        }
      }
    }
  }
`;

export function useGetAllModuleAndSchema() {
  return useLazyQuery(getAllModuleAndSchemaQuery, {
    fetchPolicy: 'no-cache',
  });
}
