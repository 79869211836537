import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const OutputContainer = styled(PadBox)`
  min-block-size: 40rem;
  background-color: var(--color-lightGray6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const EditorContainer = styled(PadBox)`
  flex: 1;
  height: 40rem;
`;

export const JsonContainer = styled.div`
  height: 40rem;
  position: relative;
`;
