import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { LuBox } from 'react-icons/lu';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Attributes,
  Dataset,
  Image,
  OnClickRuleArgs,
  PopoverMethods,
  RulePopover,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
} from 'ui';

import { envMap } from '../../../utils/constant';
import { EmptyPredefineValueComponent } from '../EmptyPredefineValueComponent/EmptyPredefineValueComponent';
import {
  InfoComponentContainer,
  TokenSelectionContainer,
} from './TokenSelectionPopover.styled';

type TokenSelectionPopoverProps = {
  customTokenComponent?: ReactElement;
  disabled?: boolean;
  selectedSchemaId?: string | null;
  tokenDataset: Record<string, Dataset>;
  handlePredefineValueSelection?: (data: OnClickRuleArgs) => void;
  tabsToShow?: string[];
  size?: string;
};

export const TokenSelectionPopover = ({
  disabled = false,
  customTokenComponent,
  selectedSchemaId,
  tokenDataset,
  handlePredefineValueSelection,
  tabsToShow = ['token', 'predefined'],
  size = '32rem',
}: TokenSelectionPopoverProps) => {
  const ref = useRef<PopoverMethods>(null);

  const [dataset, setDataset] = useState<Record<string, Dataset>>({});
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (!_isNil(tokenDataset) && !_isEmpty(tokenDataset)) {
      const tokenKeys = Object.keys(tokenDataset);
      for (let i = 0; i < tokenKeys.length; i++) {
        let isFound = false;

        const currentAttributes = tokenDataset[tokenKeys[i]].attributes;
        const attrKeys = Object.keys(currentAttributes);

        for (let j = 0; j < attrKeys.length; j++) {
          const attributeObj = currentAttributes[attrKeys[j]];

          if (attributeObj.id === selectedSchemaId) {
            const finalAttributes =
              attributeObj?.options?.reduce(
                (finalObj: Record<string, Attributes>, currOption) => {
                  return {
                    ...finalObj,
                    [currOption.value]: {
                      name: currOption.value,
                      dataType: attributeObj.dataType,
                      executedValue: currOption.value,
                    },
                  };
                },
                {}
              ) ?? {};

            const finalDataset: Record<string, Dataset> = {
              [attributeObj.name]: {
                name: attributeObj.name,
                id: attributeObj.id ?? '',
                attributes: finalAttributes,
                emptyMessage: EmptyPredefineValueComponent({
                  moduleId: tokenKeys[i],
                  attributeId: attributeObj.id ?? '',
                }),
              },
            };

            setDataset(finalDataset);

            setShowInfo(attributeObj?.usageType === 'mandatory' ?? false);

            isFound = true;
            break;
          }
        }

        if (isFound) {
          break;
        }
      }
    }
  }, [JSON.stringify(tokenDataset), selectedSchemaId]);

  const tabList: ReactElement[] = [];
  const tabPanelList: ReactElement[] = [];

  if (tabsToShow.includes('token')) {
    tabList.push(
      <Tab
        styleClassName="tabStyled"
        childClassName="tabStyled"
        key="token"
        size="extraSmall"
        disabled={disabled}
      >
        <Inline align="center" gutter={'1rem'}>
          <LuBox />
          Token
        </Inline>
      </Tab>
    );

    tabPanelList.push(
      <TabPanel>
        <PadBox padding="0.8rem">{customTokenComponent}</PadBox>
      </TabPanel>
    );
  }

  if (tabsToShow.includes('predefined')) {
    tabList.push(
      <Tab
        styleClassName="tabStyled"
        childClassName="tabStyled"
        key="predefinedValues"
        size="extraSmall"
        disabled={disabled}
      >
        <Inline align="center" gutter={'1rem'}>
          <Image
            src={`${envMap.VITE_ASSETS_URL}website/icons/module.svg`}
            alt="attribute-icon"
          />
          Predefined
        </Inline>
      </Tab>
    );

    tabPanelList.push(
      <TabPanel>
        <PadBox padding="0.8rem">
          <RulePopover
            dataset={dataset}
            allowList={true}
            placeholder={'Search in values'}
            version="v2"
            infoComponent={
              showInfo ? (
                <InfoComponentContainer>
                  <RiErrorWarningLine size={16} />
                  <Typography name="paragraphSmall">
                    Result must match predefined Value (mandatory)
                  </Typography>
                </InfoComponentContainer>
              ) : null
            }
            footer={
              Object.keys(dataset).length === 0 ? (
                <>
                  <Typography>No Value found</Typography>
                </>
              ) : (
                false
              )
            }
            onClick={({ value, key, dataType, executedValue }) => {
              if (typeof handlePredefineValueSelection === 'function') {
                handlePredefineValueSelection({
                  value,
                  key,
                  dataType,
                  executedValue,
                });
              }
              ref.current?.hide();
            }}
          />
        </PadBox>
      </TabPanel>
    );
  }

  return (
    <TokenSelectionContainer size={size}>
      <Tabs>
        <TabList styleClassName="tabStyled">{tabList}</TabList>
        <TabPanels>{tabPanelList}</TabPanels>
      </Tabs>
    </TokenSelectionContainer>
  );
};
