import { gql, useMutation } from '@apollo/client';

const updateModuleSchemaMutation = gql`
  mutation updateModuleSchema(
    $id: String!
    $dataType: String
    $usageType: UsageTypes
    $options: [ModuleSchemaOptionInput]
    $checksum: String!
  ) {
    updateModuleSchema(
      input: {
        id: $id
        dataType: $dataType
        usageType: $usageType
        options: $options
        checksum: $checksum
      }
    ) {
      id
      name
      moduleId
      dataType
      usageType
      options {
        value
      }
      checksum
    }
  }
`;

export function useUpdateModuleSchema() {
  return useMutation(updateModuleSchemaMutation);
}
