import { BiPlus } from 'react-icons/bi';
import { Button, useLayer } from 'ui';

import { CreateModuleSchemaSheet } from './components/createModuleSchemaSheet';
import type { CreateModuleSchemaProps } from './types';

export function CreateModuleSchema({
  moduleId,
  moduleName,
  refetchSchemaList,
}: CreateModuleSchemaProps) {
  const { open: createSchemaSheet } = useLayer(
    <CreateModuleSchemaSheet
      moduleId={moduleId}
      moduleName={moduleName}
      refetchSchemaList={refetchSchemaList}
    />
  );

  const handleClick = () => {
    createSchemaSheet();
  };

  return (
    <Button onClick={handleClick} leadingIcon={<BiPlus size={20} />}>
      Add Attributes
    </Button>
  );
}
