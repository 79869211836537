import { gql, useLazyQuery } from '@apollo/client';

import type { TableDataItemModel } from '../../types';

type filtersType = {
  in: Record<string, any>;
  eq: Record<string, any>;
  nin: Record<string, any>;
  ne: Record<string, any>;
  gt: Record<string, any>;
  gte: Record<string, any>;
  lt: Record<string, any>;
  lte: Record<string, any>;
};

const getTableDataQuery = gql`
  query getModule(
    $page: Int
    $perPage: Int
    $sort: Map
    $filters: QueryOperators
  ) {
    getModule(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      data {
        checksum
        id
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        schemas(perPage: 1, page: 1) {
          paginationInfo {
            totalRecord
          }
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

export type TableResponseModel = {
  getModule: {
    data: TableDataItemModel[];
    paginationInfo: {
      perPage: number;
      totalRecord: number;
      totalPage: number;
    };
  };
};

export type TablePayloadVariables = {
  page: number;
  perPage: number;
  live?: boolean;
  sort?: Record<string, any>;
  filters?: filtersType;
};

export function useGetTableData() {
  return useLazyQuery<TableResponseModel, TablePayloadVariables>(
    getTableDataQuery
  );
}
