import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  NectedEditorField,
  useCurrentLayer,
} from 'ui';

import { editorDomain } from '../../../utils/constant';
import { InputContainer } from './TextInputModal.styled';

type TextInputModalProps = {
  onSubmit?: ({ value }: Record<string, any>) => void;
  value?: string;
  disabled?: boolean;
};

export function TextInputModal({
  onSubmit,
  value,
  disabled = false,
}: TextInputModalProps) {
  const { close: closeModal } = useCurrentLayer();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      value: value ?? '',
    },
  });

  const onTackleSubmit = (data: any) => {
    if (typeof onSubmit === 'function') {
      onSubmit(data);
    }

    closeModal();
  };

  return (
    <Modal size="extraLarge" title={'Input Field'} overflow="none">
      <form onSubmit={handleSubmit(onTackleSubmit)}>
        <ModalContent>
          <InputContainer>
            <NectedEditorField
              name="value"
              defaultValue={value}
              control={control}
              domain={editorDomain}
              mode="text"
              customSuggestions={[]}
              readOnly={disabled}
            />
          </InputContainer>
        </ModalContent>
        {!disabled && (
          <ModalFooter>
            <Button onClick={closeModal} appearance="neutral">
              Cancel
            </Button>

            <Button type="submit">Save & Close</Button>
          </ModalFooter>
        )}
      </form>
    </Modal>
  );
}
