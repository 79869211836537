import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { ruleWarningsAtom } from '../..';
import { checksumWarningAtom } from '../../../../atom';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useAxiosPrivate } from '../../../../hooks';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../../utils/common';
import { KeyValuePairList } from '../../../Integrations/types';
import { versionInfoRuleAtom } from '../../components/atom/atom';
import { useGetRuleStatusAfterTest } from '../graphql/useGetRuleStatusAfterTest';
import { useGetWarnings } from '../graphql/useGetWarnings';
import { useCIWarning } from '../useCIWarning';

export type TestRulePayload = {
  id: string;
  params: Record<string, string | number | boolean | null | Date | any[]>;
  headers?: KeyValuePairList[];
  type?: string;
  version?: string;
};

type useTestRuleRuleProps = {
  ruleName: string;
  ruleType: string;
  setValue?: UseFormSetValue<any>;
};

export function useTestRule({
  ruleName,
  ruleType,
  setValue,
}: useTestRuleRuleProps) {
  const { axiosPrivate } = useAxiosPrivate();
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [versionInfoRule] = useAtom(versionInfoRuleAtom);

  const [searchParams] = useSearchParams();

  const isPreview = (searchParams.get('type') ?? '') === 'view';

  const { handleSetWarning } = useCIWarning();

  const [getWarnings, { data: ruleData, loading }] = useGetWarnings();
  const [getRuleStatus, { data: ruleStatusData, loading: statusDataLoading }] =
    useGetRuleStatusAfterTest();

  const {
    mutate: testRule,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: TestRulePayload) => {
      const headers = (request.headers ?? []).reduce((acc, item) => {
        return {
          ...acc,
          [item.key]: item.value,
        };
      }, {});

      let requestBody: Record<string, any> = {
        id: request.id,
        params: request.params,
        checksum: handleGetCheckSumByEntityName('rule') ?? '',
      };

      if (isPreview) {
        requestBody = {
          ...requestBody,
          version: versionInfoRule?.currentVersion,
        };
      }

      if ((request.headers ?? []).length > 0) {
        requestBody = { ...requestBody, headers };
      }

      if (!_isNil(request.type)) {
        requestBody = { ...requestBody, type: request.type };
      }

      return await axiosPrivate.post('/rule/testRule', requestBody);
    },
    onSuccess(response: AxiosResponse, requestVars) {
      if (requestVars.type !== 'cron') {
        toasts.success(response.data.message, 'tested-success');
      } else if (requestVars.type === 'cron') {
        if (
          response.data.data?.failure?.count > 0 ||
          response.data.data?.limitExceed?.count > 0
        ) {
          toasts.error('CRON tested unsuccessfully', 'tested-fail');
        } else {
          toasts.success('CRON tested successfully', 'tested-success');
        }
      }

      if (!isPreview) {
        void getWarnings({
          variables: {
            id: requestVars.id,
          },
        });
      }

      if (!_isNil(response.data.checksum)) {
        handleSetCheckSumByEntityName('rule', response.data.checksum);
      }

      return response;
    },
    onError(error: AxiosError, requestPayload: TestRulePayload) {
      const { response } = error;

      if (error instanceof Error) {
        if (!_isNil(response) && !_isNil(response.data)) {
          const data = response.data as any;

          if (data.code === 'checksum_mismatched') {
            setShowChecksumPopup({
              showPopup: true,
              metaData: {
                ruleId: requestPayload.id,
                ruleName,
                type: ruleType,
              },
            });
          } else {
            toasts.error(data.message, 'error');
          }

          if (!_isNil(data.checksum)) {
            handleSetCheckSumByEntityName('rule', data.checksum);
          }
        }
      }

      if (!isPreview) {
        void getRuleStatus({
          variables: {
            id: requestPayload.id,
          },
        });
      }

      return error;
    },
  });

  useEffect(() => {
    const ruleProperty = ruleData?.getRule?.data[0];

    if (!_isNil(ruleProperty) && !loading) {
      handleSetCheckSumByEntityName('rule', ruleProperty.checksum);
      setRuleWarnings(ruleProperty.warnings ?? []);

      if (ruleType !== 'ruleSet') {
        handleSetWarning(customAttributes, ruleProperty.dataSetId);
      }

      if (!_isNil(setValue)) {
        setValue('status', ruleProperty.status);
      }
    }
  }, [ruleData, loading]);

  useEffect(() => {
    const ruleProperty = ruleStatusData?.getRule?.data[0];

    if (!_isNil(ruleProperty) && !statusDataLoading && !_isNil(setValue)) {
      setValue('status', ruleProperty.status);
    }
  }, [ruleStatusData, statusDataLoading]);

  return {
    testRule,
    isLoading,
    data,
    error,
  };
}
