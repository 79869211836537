import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {
  Control,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import {
  CheckboxField,
  TextButton,
  TextField,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import {
  predefineTokenDatasetAtom,
  siteConstantsAtom,
} from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { onClickTokenSelectionArgs } from '../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { TokenMenu } from '../../../../../components/TokenComponents/TokenMenu/TokenMenu';
import { isValidSchemaId } from '../../../../../components/TokenComponents/utils/helperFunction';
import { CaseSensitiveCheckbox } from '../../../../../components/rules/forms/CustomAttributeSheet/CaseSensitiveCheckbox';
import { FormHeader } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet.styled';
import { DataTypeSelection } from '../../../../../components/rules/forms/CustomAttributeSheet/DataTypeSelection';
import { SampleValueField } from '../../../../../components/rules/forms/CustomAttributeSheet/SampleValueField';
import { getTooltipText } from '../../../../../utils/common';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';

type ApiInputFormProps = {
  control?: Control<any>;
  triggerId: string;
  setValue: UseFormSetValue<any>;
};

export function ApiInputForm({
  control,
  triggerId,
  setValue,
}: ApiInputFormProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const customAttributeDefaultValues = {
    name: '',
    dataType: null,
    isNullable: false,
    isOptional: false,
    isCaseSensitive: false,
    selectedType: null,
    executedValue: null,
  };

  const { fields, append, remove } = useFieldArray({
    name: 'attributes',
    control,
  });

  const attr = useWatch({
    name: `attributes`,
    control,
  });

  const checkIsKeyNameUnique = (name?: string) => {
    let exists = false;

    attr.forEach((currAttribute: any) => {
      if (currAttribute.name === name) {
        exists = true;
      }
    });

    return !exists;
  };

  const handleAddConfig = (
    dataType: string,
    predefineTokenData?: onClickTokenSelectionArgs
  ) => {
    if (!_isNil(predefineTokenData)) {
      const isKeyNameUnique = checkIsKeyNameUnique(
        predefineTokenData.schemaName
      );

      if (!isKeyNameUnique) {
        toasts.infoBlue(
          'Cannot add attribute with same key name',
          'keyname-unique-wf-trigger'
        );

        return {
          isKeyNameUnique,
        };
      }
    }

    append({
      ...customAttributeDefaultValues,
      dataType: {
        label:
          dataType.charAt(0).toUpperCase() + dataType.slice(1, dataType.length),
        value: dataType,
      },
      selectedType: {
        dataType,
        value: dataType,
        key: 'primitive',
      },
      name: predefineTokenData?.schemaName ?? '',
      schemaId: predefineTokenData?.schemaId,
    });

    return {
      isKeyNameUnique: true,
    };
  };

  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Type',
    },
    {
      label: 'Test Value',
    },
    {
      label: 'Can be Null',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciCanBeNull')}
        </Typography>
      ),
    },
    {
      label: 'Case Sensitive',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsCaseSensitive')}
        </Typography>
      ),
    },
    {
      label: 'Is Optional',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsMandatory')}
        </Typography>
      ),
    },
  ];

  const handlePredefineTokenSelection = (data: onClickTokenSelectionArgs) => {
    const { isKeyNameUnique } = handleAddConfig(data.dataType, data);

    return {
      canCloseModal: isKeyNameUnique,
    };
  };

  const handlePredefineMultiTokenSelection = (
    data: onClickTokenSelectionArgs[]
  ) => {
    let isKeyNameUnique = true;

    data.forEach((predefineTokenData) => {
      const isUnique = checkIsKeyNameUnique(predefineTokenData.schemaName);

      if (!isUnique) {
        isKeyNameUnique = false;
      }
    });

    if (!isKeyNameUnique) {
      toasts.infoBlue('Cannot add schema with same key name', 'keyname-unique');

      return {
        canCloseModal: isKeyNameUnique,
      };
    }

    data.forEach((predefineTokenData) =>
      handleAddConfig(predefineTokenData.dataType, predefineTokenData)
    );

    return {
      canCloseModal: true,
    };
  };

  const addFieldLauncher = (
    <Inline style={{ width: 'max-content' }} align="center">
      {isWorkflowReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}

      <TextButton disabled={isWorkflowReadOnly}>Add Field</TextButton>
    </Inline>
  );

  return (
    <FormPanel padding={[0, '2.4rem']}>
      <Stack gutter="1rem">
        <Table>
          <TableRow>
            {formHeaders.map((header) => (
              <TableHeader key={header.label}>
                <PadBox padding="1.2rem">
                  <Inline align="center" gutter={8}>
                    <Typography>{header.label}</Typography>

                    {!_isNil(header.tooltipContent) && (
                      <TooltipReact id={header.label} placement="top-start">
                        {header.tooltipContent}
                      </TooltipReact>
                    )}
                  </Inline>
                </PadBox>
              </TableHeader>
            ))}
          </TableRow>

          {fields.map((field, index) => {
            const schemaId = attr?.[index]?.schemaId;

            const isModuleSchemaField =
              !_isNil(schemaId) &&
              !_isEmpty(schemaId) &&
              isValidSchemaId(predefineTokenDataset, schemaId);

            return (
              <TableRow key={field.id}>
                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <TextField
                      control={control}
                      rules={{ required: 'Required' }}
                      name={`attributes.${index}.name`}
                      placeholder="Property Name"
                      showErrorIcon={false}
                      disabled={isWorkflowReadOnly || isModuleSchemaField}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <DataTypeSelection
                      index={index}
                      setValue={setValue}
                      control={control}
                      name={`attributes.${index}.selectedType`}
                      isDisabled={isWorkflowReadOnly || isModuleSchemaField}
                      from="workflow"
                      hideRestApi
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <SampleValueField
                      index={index}
                      setValue={setValue}
                      control={control}
                      name={`attributes.${index}.executedValue`}
                      isDisabled={isWorkflowReadOnly}
                      from="workflow"
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CheckboxField
                      control={control}
                      name={`attributes.${index}.isNullable`}
                      useId={`${index}_isNullable`}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CaseSensitiveCheckbox
                      control={control}
                      name={`attributes.${index}.isCaseSensitive`}
                      field={`attributes.${index}.dataType`}
                      setValue={setValue}
                      index={index}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CheckboxField
                      control={control}
                      name={`attributes.${index}.isOptional`}
                      useId={`${index}_isOptional`}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <TextButton
                      disabled={isWorkflowReadOnly}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </TextButton>
                  </PadBox>
                </TableData>
              </TableRow>
            );
          })}
        </Table>

        <TokenMenu
          entity="workflow"
          disabled={isWorkflowReadOnly}
          tokenDataset={predefineTokenDataset}
          launcher={addFieldLauncher}
          handleAddCustomToken={handleAddConfig}
          handlePredefineTokenSelection={handlePredefineTokenSelection}
          showMultiSelect={true}
          handlePredefineMultiTokenSelection={
            handlePredefineMultiTokenSelection
          }
          notAllowedCustomMenuItems={['excelFormula', 'jsFormula']}
        />
      </Stack>
    </FormPanel>
  );
}
