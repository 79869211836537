import { Center } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Button, Link, TextField, Typography, toasts } from 'ui';
import { z } from 'zod';

import { axiosVidhanPrivate } from '../../api/axios';
import { AuthPagesLayout } from '../../components/authentication';
import {
  FixedWidthContainer,
  PageContentContainer,
  SignupFormContainer,
} from '../../components/authentication/layout/AuthPagesLayout.styled';
import { Salutation } from '../../components/authentication/layout/Salutation';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { getPublicIP, logEvent } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { emailValidationSchema } from '../../utils/schema';
import { SignupForm } from './components/Signup';
import { InlineStyled } from './components/Signup.styled';

type SignupFormContainerProps = {
  email: string;
};

const FormValidationSchema = z.object({
  ...emailValidationSchema,
});

export const Signup = () => {
  const isGoogleEnabled = envMap.VITE_ENABLE_GOOGLE_LOGIN === 'true';
  const isMsLoginEnabled = envMap.VITE_ENABLE_MS_LOGIN === 'true';
  const isAuthFormEnabled = envMap.VITE_ENABLE_AUTH_FORM === 'true';
  const [currentStep, setCurrentStep] = useState(
    localStorage.getItem('aB_Params_skipStep') === 'true' ? 2 : 1
  );
  const { siteMeta } = useGetSiteMeta();

  useEffect(() => {
    void logEvent('LANDED', 'email');
    void getPublicIP();
  }, []);

  const { control, handleSubmit } = useForm<SignupFormContainerProps>({
    resolver: zodResolver(FormValidationSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const isEmailDomainBanned = async (email: string) => {
    try {
      const environment =
        envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';
      const API_URL =
        'https://nected-79.nected.io/nected/workflow/2191b052-007e-443b-b455-b8b3bdb13067';
      const response = await axiosVidhanPrivate.post(API_URL, {
        environment,
        isTest: false,
        params: {
          emailDomain: email.split('@')[1],
        },
      });

      const result = response.data.data.output.isEmailBanned;

      return result;
    } catch (err) {
      return false;
    }
  };

  const onSubmit = async ({ email }: SignupFormContainerProps) => {
    if (
      localStorage.getItem('aB_Params_onlyWorkEmailAllowed') === 'true' &&
      localStorage.getItem('aB_Params_skipStep') !== 'true'
    ) {
      const response = await isEmailDomainBanned(email);

      if (response === true) {
        void logEvent('ERROR', 'email', email);
        toasts.error(`Only work emails are allowed`, 'invalid-email');
      } else {
        goToNextStep(email);
      }
    } else {
      goToNextStep(email);
    }
  };

  const goToNextStep = (email: string) => {
    window.localStorage.setItem('signup_onb', window.btoa(email));
    void logEvent('ONBOARDED', 'email', email);
    setCurrentStep(2);
  };

  return (
    <>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      {currentStep === 1 && (
        <>
          <Center centerChildren>
            <FixedWidthContainer>
              <PageContentContainer>
                <PageContentContainer gutter="3rem">
                  <Salutation from={'signup'} />
                </PageContentContainer>
                <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
                  <Stack gutter="2.4rem">
                    <Stack gutter="1.6rem">
                      <TextField name="email" control={control} label="Email" />
                      <Stack gutter="1.6rem">
                        <Button type="submit" size="large">
                          Continue
                        </Button>

                        <InlineStyled align="center">
                          <Typography name="heading3" element="span">
                            Already have an account?
                          </Typography>

                          <Link to={`/signin${window.location.search}`}>
                            Log in
                          </Link>
                        </InlineStyled>
                      </Stack>
                    </Stack>
                  </Stack>
                </SignupFormContainer>
              </PageContentContainer>
            </FixedWidthContainer>
          </Center>
        </>
      )}
      {currentStep === 2 && (
        <>
          <AuthPagesLayout
            from="signup"
            salutation
            google={isGoogleEnabled}
            microsoft={isMsLoginEnabled}
            isAuthFormEnabled={isAuthFormEnabled}
            form={<SignupForm />}
          />
        </>
      )}
    </>
  );
};
