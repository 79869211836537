import _isNil from 'lodash/isNil';
import { Typography } from 'ui';

import { Table, TableProps } from './Table';
import {
  TableContainerStyled,
  TableHeadingContainerStyled,
} from './TableContainer.styled';

type TableContainerProps = Pick<
  TableProps,
  'columns' | 'currentPage' | 'onPageChange' | 'totalPages'
> & {
  headerName?: string;
  tableData: Array<Record<string, any>>;
  isMarginReqired?: boolean;
};

export function TableContainer({
  headerName,
  columns,
  tableData,
  onPageChange,
  totalPages,
  currentPage,
  isMarginReqired = false,
}: TableContainerProps) {
  if (totalPages === 0) {
    return null;
  }

  return (
    <TableContainerStyled
      isMarginReqired={isMarginReqired}
      padding={['1rem', '1rem']}
    >
      {!_isNil(headerName) && (
        <TableHeadingContainerStyled
          justify="start"
          stretch="start"
          align="center"
        >
          <Typography name="heading2">{headerName}</Typography>
        </TableHeadingContainerStyled>
      )}

      <Table
        columns={columns}
        data={tableData}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </TableContainerStyled>
  );
}
