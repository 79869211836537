import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { toasts } from 'ui';

import { isRuleReadOnlyAtom, ruleLimitsConfigAtom } from '../../..';
import { predefineTokenDatasetAtom } from '../../../../../atom';
import { onClickTokenSelectionArgs } from '../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { TokenMenu } from '../../../../../components/TokenComponents/TokenMenu/TokenMenu';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { generateUid } from '../../../../../utils/common';
import {
  getDefaultValueByDataTypeForRhsOutput,
  getRequiredKey,
} from '../../../utils/common';
import { decisionTableNodeIdAtom } from '../DecisionTable';

type ResultAddMenuProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  total: number;
  setIndexToObserve: (index: number) => void;
};

export function ResultAddMenu({
  setValue,
  index,
  control,
  total,
  setIndexToObserve,
}: ResultAddMenuProps) {
  const results = useWatch({ control, name: 'results' });
  const rows = useWatch({ control, name: 'rows' });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const [ruleId] = useAtom(decisionTableNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleResultMenuOpen = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      action: 'add',
      element: 'result',
      action_name: '',
      nec_source: '',
    });
  };

  const checkIsKeyNameUnique = (name?: string) => {
    let exists = false;
    results.forEach((result: any) => {
      const resultKey = getRequiredKey(result, ['id']);

      if (result[resultKey].keyName === name) {
        exists = true;
      }
    });

    return !exists;
  };

  const handleAddResult = (
    dataType: string,
    predefineTokenData?: onClickTokenSelectionArgs
  ) => {
    if (!_isEmpty(limitConfig) && total < limitConfig.maxOutputVars.value) {
      const newResultId = generateUid('result_');

      sendEventToGTM({
        event: 'rule',
        ruleId,
        ruleName,
        type: 'decisionTable',
        action: 'selection',
        element: 'result',
        action_name: dataType,
        nec_source: '',
      });

      if (!_isNil(predefineTokenData)) {
        const isKeyNameUnique = checkIsKeyNameUnique(
          predefineTokenData.schemaName
        );

        if (!isKeyNameUnique) {
          toasts.infoBlue(
            'Cannot add attribute with same key name',
            'keyname-unique'
          );

          return {
            isKeyNameUnique,
          };
        }
      }

      rows?.forEach((row: any, i: number) => {
        const newOutputId = generateUid('output_');

        const rowKey = getRequiredKey(row, ['id']);

        const currentGroup = row[rowKey];

        const updatedRuleResult = currentGroup.ruleResult;
        const ruleResultToInsert = {
          [newOutputId]: {
            dataType,
            value: getDefaultValueByDataTypeForRhsOutput(dataType),
          },
        };

        updatedRuleResult.splice(index + 1, 0, ruleResultToInsert);

        if (!_isNil(currentGroup) && !_isEmpty(currentGroup)) {
          setValue(`rows.${i}.${rowKey}.ruleResult`, updatedRuleResult);
        }
      });

      const resultObjToInsert = {
        [newResultId]: {
          dataType,
          keyName: predefineTokenData?.schemaName ?? 'keyName',
          schemaId: predefineTokenData?.schemaId,
        },
      };
      results.splice(index + 1, 0, resultObjToInsert);

      setValue('results', results);

      setIndexToObserve(index + 1);
    } else if (!_isEmpty(limitConfig.maxOutputVars?.message)) {
      toasts.info(limitConfig.maxOutputVars.message, 'info');
    }

    return {
      isKeyNameUnique: true,
    };
  };

  const handlePredefineTokenSelection = (data: onClickTokenSelectionArgs) => {
    const { isKeyNameUnique } = handleAddResult(data.dataType, data);

    return {
      canCloseModal: isKeyNameUnique,
    };
  };

  return (
    <TokenMenu
      entity="rules"
      disabled={isRuleReadOnly}
      tokenDataset={predefineTokenDataset}
      onLauncherClick={handleResultMenuOpen}
      handleAddCustomToken={handleAddResult}
      handlePredefineTokenSelection={handlePredefineTokenSelection}
    />
  );
}
