import { gql, useMutation } from '@apollo/client';

const updateModuleQuery = gql`
  mutation updateModule(
    $id: String!
    $name: String!
    $description: String!
    $checksum: String!
  ) {
    updateModule(
      input: {
        id: $id
        name: $name
        description: $description
        checksum: $checksum
      }
    ) {
      id
      name
      description
      checksum
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`;
export function useUpdateModule() {
  return useMutation(updateModuleQuery);
}
