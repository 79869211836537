import { Inline } from '@bedrock-layout/inline';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import { ChangeEvent } from 'react';

import { ExecutedValueTooltip } from '../../ExecutedValueTooltip/ExecutedValueTooltip';
import { CheckboxInput } from '../../Form/CheckboxInput';
import { Typography } from '../../Typography';
import { IconByDataType } from '../RulePopover/IconByDataType';
import { LeafNodeStyled, StyledText, SymbolText } from './NodeLeaf.styled';

export type NodeLeafProps = {
  title: string;
  symbol?: string;
  onClick: () => void;
  dataType?: string;
  executedValue?: any;
  showTooltip?: boolean;
  version?: 'v1' | 'v2';
  showMultiSelect?: boolean;
  nodeKey?: string;
  checked?: boolean;
  onCheckboxClick?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const NodeLeaf = ({
  title,
  symbol = '',
  onClick,
  dataType,
  executedValue,
  showTooltip = false,
  version = 'v1',
  showMultiSelect = false,
  checked = false,
  onCheckboxClick,
  nodeKey = '',
}: NodeLeafProps) => {
  return (
    <Inline align="center" gutter="1rem">
      {showMultiSelect && (
        <CheckboxInput
          useId={nodeKey + title}
          name={nodeKey + title}
          checked={checked}
          onChange={(e) => {
            if (typeof onCheckboxClick === 'function') {
              onCheckboxClick(e);
            }
          }}
        />
      )}

      <LeafNodeStyled
        id="nodeLeaf"
        onClick={onClick}
        padding={[6, 0, 6, 8]}
        $version={version}
      >
        {showTooltip ? (
          <ExecutedValueTooltip
            id={title}
            value={executedValue}
            attribute={title}
            dataType={dataType}
            placement="top"
          >
            <Inline stretch="end" align="center" gutter={10}>
              {version === 'v2' && (
                <span>
                  <IconByDataType
                    dataType={dataType}
                    color="var(--color-paleDodgerBlue)"
                  />
                </span>
              )}
              <Inline align="center">
                {!_isEmpty(symbol) && <SymbolText>{symbol}</SymbolText>}
                <StyledText
                  id="nodeLeafText"
                  name="secondaryXsDark"
                  fontWeight={700}
                  $version={version}
                >
                  {`${title}`}
                </StyledText>
              </Inline>

              {!_isUndefined(dataType) && (
                <Typography name="secondaryXs">{dataType}</Typography>
              )}
            </Inline>
          </ExecutedValueTooltip>
        ) : (
          <Inline stretch="end" align="center" gutter={10}>
            <Inline align="center">
              {!_isEmpty(symbol) && <SymbolText>{symbol}</SymbolText>}
              {version === 'v2' && (
                <span>
                  <IconByDataType
                    dataType={dataType}
                    color="var(--color-paleDodgerBlue)"
                  />
                </span>
              )}

              <StyledText
                id="nodeLeafText"
                name="secondaryXsDark"
                fontWeight={700}
                $version={version}
              >
                {`${title}`}
              </StyledText>
            </Inline>

            {!_isUndefined(dataType) && (
              <Typography name="secondaryXs">{dataType}</Typography>
            )}
          </Inline>
        )}
      </LeafNodeStyled>
    </Inline>
  );
};
