import { gql, useMutation } from '@apollo/client';

function generateMutationString(count: number) {
  return Array(count)
    .fill(null)
    .map(
      (_, index) => `
    field${index}: createModuleSchema(
      input: $input${index}
    ) {
      checksum
      id
      name
      dataType
      options {
        value
      }
      usageType
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  `
    )
    .join('\n');
}

function generateVariablesString(count: number) {
  return Array(count)
    .fill(null)
    .map((_, index) => `$input${index}: NewModuleSchema!`)
    .join(', ');
}

export function useCreateModuleSchemas() {
  let dynamicMutation = gql`
    mutation CreateSchema($input0: NewModuleSchema!) {
      field0: createModuleSchema(input: $input0) {
        checksum
        id
        name
        dataType
        usageType
        options {
          value
        }
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  `;

  const [mutate, { loading, error, data }] = useMutation(dynamicMutation);

  const createSchemas = async (
    schemaArray: Array<Record<string, any>>,
    moduleId: string
  ) => {
    dynamicMutation = gql`
      mutation CreateSchemas(${generateVariablesString(schemaArray.length)}) {
        ${generateMutationString(schemaArray.length)}
      }
    `;

    const variables = schemaArray.reduce<
      Record<
        string,
        {
          name: string;
          dataType: string;
          moduleId: string;
          options: any[];
          usageType: string;
        }
      >
    >((acc, schema, index) => {
      acc[`input${index}`] = {
        name: schema.name,
        dataType: schema.dataType.value,
        moduleId,
        options: schema.options,
        usageType: schema.usageType,
      };

      return acc;
    }, {});

    return await mutate({
      mutation: dynamicMutation,
      variables,
    });
  };

  return {
    createSchemas,
    loading,
    error,
    data,
  };
}
