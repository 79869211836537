import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const UsageLauncherContainer = styled(Inline)<{ $isDisabled?: boolean }>`
  min-inline-size: 15rem;
  min-block-size: 2.5rem;
  border-radius: 0.4rem;
  background: var(--color-white);
  border-radius: 0.4rem;
  padding: 0.8rem;
  cursor: pointer;
  outline: 1px solid var(--color-lightGray);
  justify-content: space-between;

  ${({ $isDisabled = false }) =>
    $isDisabled && 'background: var(--color-whiteSmoke);'};
`;
