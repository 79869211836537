import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography, toasts, useLayer } from 'ui';

import { siteConstantsAtom } from '../../atom';
import { ListingComponent } from '../../components/Listing';
import { ModuleTemplatePopup } from '../../components/Listing/gettingStarted/components/moduleTemplate';
import { permissionObj } from '../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../components/PermissionComponent/hooks/useCheckPermissions';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import {
  handleGetConstantData,
  handleSetCheckSumByEntityName,
} from '../../utils/common';
import { ENTITY_ID, ENTITY_MAP } from '../../utils/constant';
import { DeleteRuleModal } from '../Rules/components/RulesTable/DeleteRuleModal/DeleteRuleModal';
import { useDeleteModule } from './hooks/graphql/useDeleteModule';
import {
  TableResponseModel,
  useGetTableData,
} from './hooks/graphql/useGetTableData';
import type { ModuleProps } from './types';

export const moduleLimitsConfigAtom = atom<Record<string, any>>({});
export function Module(props: ModuleProps) {
  const navigate = useNavigate();
  const [modal, setModal] = useState('');
  const [, setLimitConfig] = useAtom(moduleLimitsConfigAtom);
  const { subModules, limits, permissions } = props;
  const { openWithProps: openModuleTemplatePopup } = useLayer(
    <ModuleTemplatePopup
      title="Library"
      type="MODULE_TEMPLATE"
      selectedTab={0}
      subModules={subModules}
    />
  );
  const urlParams = new URLSearchParams(window.location.search);
  const paramsWsid = urlParams.get('wsid');

  if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
    window.sessionStorage.setItem('workspaceUUID', paramsWsid);
  }
  const entity = ENTITY_MAP[window.location.pathname.split('/')[1]];
  const { sendEventToGTM } = useSendEventToGTM();
  const { siteMeta } = useGetSiteMeta();
  const [listData, setListData] = useState<TableResponseModel | undefined>();
  const [siteConstant, setSiteConstant] = useAtom(siteConstantsAtom);
  const [getTableData, { data, refetch }] = useGetTableData();
  const [deleteModule] = useDeleteModule();
  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.module],
  });
  const [searchParams] = useSearchParams();
  const [refreshListData, setRefreshListData] = useState(false);

  const updateState = () => {
    setModal(searchParams.get('modal') ?? '');
  };

  useEffect(() => {
    setLimitConfig(limits);
  }, [limits]);

  useEffect(() => {
    updateState();
  }, [searchParams]);

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteRuleModal
      title="Delete Group"
      description={
        'you sure you want to delete the group? It might be used in any rules/workflow that might fail.'
      }
    />
  );

  useEffect(() => {
    if (!_isNil(data)) setListData(data);
  }, [data]);

  useEffect(() => {
    void handleGetConstantData('module', setSiteConstant, siteConstant);
  }, []);

  const handleRowClick = (data: any) => {
    handleSetCheckSumByEntityName('module', data.checksum);
    window.sessionStorage.setItem(
      'module-back-url',
      `${window.location.pathname}${window.location.search}`
    );
    navigate(
      `/module/${data.id as string}?type=edit&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`
    );
  };

  const handleDeleteModule = async (id: string, checksum: string) => {
    handleSetCheckSumByEntityName('module', checksum);

    sendEventToGTM({
      event: 'module',
      source: 'listing',
      element: '',
      action: 'delete_click',
    });

    try {
      await deleteModule({
        variables: {
          id,
          checksum,
        },
      });

      toasts.success('Module deleted successfully', 'deleted-success');
      const response = await refetch();
      setListData(response.data);
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to delete module', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleDeleteActionClick = async (data: any) => {
    await handleDeleteModule(data.id, data.checksum);
  };

  const handleActionColumnClick = useCallback((actionData: any) => {
    const { type, data } = actionData;

    if (type === 'delete') {
      openDeleteConfirmationModal({
        onDeleteClick: async () => await handleDeleteActionClick(data),
      });
    } else if (type === 'edit_access') {
      const { id } = data;
      navigate(
        `/module/${id as string}?type=edit&wsid=${
          sessionStorage.getItem('workspaceUUID') as string
        }`
      );
    }
  }, []);

  const handleCreateModule = () => {
    openModuleTemplatePopup({});
  };
  useEffect(() => {
    if (modal === 'create') {
      handleCreateModule();
    }
  }, [modal]);
  const HeaderComponent = useCallback(() => {
    return (
      <Inline gutter={25}>
        <Typography name="heading1" fontWeight={700}>
          Attribute Groups
        </Typography>
        <Button
          disabled={editDisable}
          onClick={handleCreateModule}
          leadingIcon={<BiPlus size={20} />}
        >
          Create Groups
        </Button>
      </Inline>
    );
  }, [editDisable]);

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <ListingComponent
        entity={entity}
        headerTitle="Attribute Library"
        callOnRowClick={handleRowClick}
        callOnCellClick={() => {}}
        errorTitle="No Groups found"
        HeaderComponent={HeaderComponent as any}
        isGraphQL={true}
        getListDataQuery={getTableData}
        queryListData={listData}
        handleActionItemClick={handleActionColumnClick}
        permissions={permissions}
        subModules={subModules}
        isCreateDisable={editDisable}
        refreshListData={refreshListData}
        setRefreshListData={setRefreshListData}
      />
    </PagesBodyContainer>
  );
}
