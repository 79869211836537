import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { type Control, UseFormSetError } from 'react-hook-form';
import { NectedEditorField, NectedSuggestionModel } from 'ui';

import { editorDomain } from '../../../utils/constant';
import type { Plugin } from '../../Integrations/types';
import type {
  CreateDataSetFormValues,
  DatabaseName,
  SchemasValue,
} from '../types';
import { getSuggestionsKeywordsNew } from '../utils';
import { MongoNoCodeEditor } from './MongoNoCodeEditor';

type DataSetQueryEditorArgs = {
  control: Control<CreateDataSetFormValues>;
  schemas: SchemasValue;
  databaseName?: DatabaseName;
  plugin?: Plugin;
  isLive?: boolean;
  from: string;
  setValidity?: (state: boolean) => void;
  setValue: (name: string, value: string) => void;
  lastCursorObj: Record<string, any>;
  setLastCursorObj: any;
  setError: UseFormSetError<any>;
};

type GetEditorFieldComponentArgs = Pick<
  DataSetQueryEditorArgs,
  'control' | 'databaseName' | 'isLive'
> & {
  customSuggestion: NectedSuggestionModel[];
  setValidity?: (state: boolean) => void;
  setValue: any;
  from: string;
  lastCursorObj: Record<string, any>;
  setLastCursorObj: any;
  setError: UseFormSetError<any>;
};

function getEditorField({
  control,
  databaseName = 'mysql',
  customSuggestion,
  isLive,
  setValidity,
  setValue,
  from,
  lastCursorObj,
  setLastCursorObj,
  setError,
}: GetEditorFieldComponentArgs) {
  switch (databaseName) {
    case 'mongodb':
      return (
        <MongoNoCodeEditor
          setError={setError}
          from={from}
          setValue={setValue}
          control={control}
          name="query"
          mode={databaseName}
          customSuggestions={customSuggestion}
          readOnly={isLive}
          methods="read"
          onSetEditorValidity={setValidity}
          domain={editorDomain}
          lastCursorObj={lastCursorObj}
          setLastCursorObj={setLastCursorObj}
        />
      );
    case 'mysql':
    case 'pgsql':
    case 'redshift':
    case 'snowflake':
    case 'oracle':
    case 'sqlserver':
      return (
        <NectedEditorField
          control={control}
          name="query"
          mode={databaseName}
          customSuggestions={customSuggestion}
          readOnly={isLive}
          methods="read"
          onSetEditorValidity={setValidity}
          domain={editorDomain}
        />
      );
    default:
      return null;
  }
}

export function DataSetQueryEditor({
  control,
  schemas,
  databaseName,
  plugin,
  isLive,
  setValidity,
  setValue,
  from,
  lastCursorObj,
  setLastCursorObj,
  setError,
}: DataSetQueryEditorArgs) {
  const customSuggestion = useMemo(() => {
    if (!_isNil(schemas) && !_isEmpty(schemas)) {
      return getSuggestionsKeywordsNew(schemas, plugin, databaseName);
    }

    return [];
  }, [schemas]);

  return (
    <>
      {getEditorField({
        control,
        databaseName,
        customSuggestion,
        isLive,
        setValidity,
        setValue,
        from,
        lastCursorObj,
        setLastCursorObj,
        setError,
      })}
    </>
  );
}
