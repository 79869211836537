import { gql, useMutation } from '@apollo/client';

const createModuleQuery = gql`
  mutation createModule($name: String!, $description: String!) {
    createModule(input: { name: $name, description: $description }) {
      id
      checksum
    }
  }
`;

export function useCreateModule() {
  return useMutation(createModuleQuery);
}
