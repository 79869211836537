import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Menu, Typography } from 'ui';

export const AuthenticationConfigMenu = styled(Menu)`
  width: 60rem !important;
`;

export const PillStyled = styled(PadBox)`
  border-radius: 2.5rem;
  background-color: var(--color-lightGray);
`;

export const SuccessPillStyled = styled(PadBox)`
  border-radius: 0.5rem;
  background: var(--color-statusGreen);
  color: var(--color-green);
`;

export const SaveButtonContainer = styled(PadBox)`
  border-block-start: 1px solid var(--color-lightGray);
  border-block-end: 1px solid var(--color-lightGray);
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: white;
`;

export const AuthenticationConfigKeysContainer = styled(Typography)`
  width: 18rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AuthConfigTypeContainer = styled(Inline)`
  inline-size: 30rem;
  flex-wrap: wrap;
`;

export const AuthenticationConfigValuesContainer = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const GrantTypeStyled = styled(Typography)`
  text-transform: capitalize;
`;

export const AddAuthenticationConfigSheetForm = styled.form`
  flex: 1;
  height: 100%;
`;

export const MenuItemContainer = styled.div`
  max-width: 300px;
  padding: 0.5rem;
`;
