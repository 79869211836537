import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';
import { TbDownload } from 'react-icons/tb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../../../atom';
import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { updateWidgetState } from '../../../../pages/Home/components/sub-components/UpdateWidgetState';
import { useGenrateEntityId } from '../../../../pages/Home/components/sub-components/useGenerateEntityId';
import { useGetDemoEntity } from '../../../../pages/Home/hooks/useGetHomePageData';
import { TabsContainer } from '../../../../pages/Integrations/components/common/IntegrationSheet.styled';
import {
  decisionTableInitialData,
  decisionTableInitialNodes,
} from '../../../../pages/Rules/components/DecisionTable/fixtures/sample';
import { ruleInitialState } from '../../../../pages/Rules/fixtures/ruleInitialState';
import { RuleConfigType } from '../../../../pages/Rules/types';
import { ruleTypeConfig } from '../../../../pages/Rules/utils/constant';
import {
  checkLimitExceeded,
  isOnboardingCompleted,
} from '../../../../utils/common';
import { ENTITY_ID, demoRuleCreated, envMap } from '../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../utils/validation';
import { ImportEntityModal } from '../../../Modals/ImportEntityModal/ImportEntityModal';
import { permissionObj } from '../../../PermissionComponent/constant';
import { useCheckPermissions } from '../../../PermissionComponent/hooks/useCheckPermissions';
import { Threshold } from '../../../Threshold/Threshold';
import {
  CreateRuleBlock,
  CustomClose,
  RuleTemplateFooterContainer,
  RuleTypePill,
} from '../../listing.styled';
import { useCreateDT } from '../hooks/graphql/useCreateDT';
import { useCreateRS } from '../hooks/graphql/useCreateRS';
import { useCreateSR } from '../hooks/graphql/useCreateSR';
import { DemoObjProps, StartFromTemplate } from './StartFromTemplate';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export type RuleTemplateProps = {
  title: string;
  type: 'RULE_TEMPLATE' | 'CREATE_TEMPLATE';
  selectedTab: number;
  subModules?: Record<string, boolean>;
};

type CreateRuleFormProps = {
  items: RuleConfigType[];
  control: Control<any>;
  ruleType: string;
  handleSelectRuleType: (type: string) => void;
};

export const ruleNameMap: Record<string, string> = {
  decisionTable: 'Decision Table',
  simpleRule: 'Simple Rule',
  ruleSet: 'Rule Set',
};

export const RuleTemplatePopup = ({
  title,
  type,
  selectedTab,
  subModules,
}: RuleTemplateProps) => {
  const navigate = useNavigate();
  const { sendEventToGTM } = useSendEventToGTM();
  const [searchParams] = useSearchParams();

  const [searchedText, setSearchedText] = useState('');
  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [demoRules, setDemoRules] = useState<DemoObjProps[]>([]);
  const [currentTab, setCurrentTab] = useState(selectedTab ?? 0);
  const [createSR] = useCreateSR();
  const [createDT] = useCreateDT();
  const [createRS] = useCreateRS();

  const createMap: Record<string, any> = {
    simpleRule: createSR,
    decisionTable: createDT,
    ruleSet: createRS,
  };

  const [ruleType, setRuleType] = useState('decisionTable');
  const [demoObj, setDemoObj] = useState<DemoObjProps | null>(null);
  const { generateEntityId } = useGenrateEntityId();
  const [getDemoEntityData, { data: demoRulesData }] = useGetDemoEntity();

  const { close: closePopup } = useCurrentLayer();

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="rule" from="import_list" />
  );

  const { control, getValues, setError } = useForm<Record<string, string>>({
    mode: 'onChange',
  });
  const customClosePopup = () => {
    sendEventToGTM({
      event: 'lb_popup_closed',
      action: 'click',
      type,
      title,
    });
    closePopup();
  };

  const { isHide: createDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.rules],
  });

  const { isHide: isImportDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.rules],
  });

  useEffect(() => {
    if (deploymentType !== 'onpremise') {
      void getDemoEntityData({
        variables: {
          filters: {
            eq: { ref_entity: 'rule' },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_isNil(demoRulesData)) {
      setDemoRules(demoRulesData.getDemoUseCases.data);
    }
  }, [demoRulesData]);

  useEffect(() => {
    if (searchedText === '') {
      if (!_isNil(demoRulesData)) {
        setDemoRules(demoRulesData.getDemoUseCases.data);
      }
    } else {
      if (!_isNil(demoRulesData)) {
        const searchedData = demoRulesData.getDemoUseCases.data.filter((i) =>
          i.name.toLocaleLowerCase().includes(searchedText.toLocaleLowerCase())
        );
        setDemoRules(searchedData);
      }
    }
  }, [searchedText]);

  const selectDemoRule = (obj: DemoObjProps) => {
    setDemoObj(obj);
  };

  const handleCreateDemoRule = () => {
    if (!_isNil(demoObj)) {
      let ruleURL = `/rules/ID?type=edit&stage=staging&ruleType=${
        demoObj.category
      }&closeSheet=true&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;

      if (!_isNil(searchParams.get('step'))) {
        ruleURL = `${ruleURL}&step=${searchParams.get('step') as string}`;
      }

      const entityId = demoObj?.data?.entityId;

      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        sendEventToGTM({
          event: 'lb_demo_rule_open',
          action: 'click',
          type: demoObj.category,
          title,
          entityId,
        });
        navigate(ruleURL.replace('ID', entityId));
      } else {
        sendEventToGTM({
          event: 'lb_demo_rule_created',
          action: 'click',
          type: demoObj.category,
          title,
          entityId: demoObj.id,
        });
        void updateUserState();
        void createEntityAndOpen(demoObj.id, ruleURL, '_self');
      }
    }
  };

  const handleFooterClose = () => {
    if (currentTab === 1) {
      handleCreateDemoRule();
    } else {
      handleCreateRule();
    }
  };

  const toggleTabs = (tabValue: number) => {
    setCurrentTab(tabValue);
  };

  const handleCreateRule = () => {
    const formValues = getValues();
    const requestPayload: Record<string, any> = {
      name: formValues.useCaseName,
      description: formValues.ruleDescripiton ?? '',
      type: ruleType,
      settings: {
        isEnabled: true,
        api: {
          isEnabled: true,
          isPrivate: true,
        },
      },
    };

    if (_isNil(formValues.useCaseName) || _isEmpty(formValues.useCaseName)) {
      setError('useCaseName', { message: 'Rule name is required.' });
    } else if (formValues.useCaseName.length > maxFiftyCharactersRule.value) {
      setError('useCaseName', { message: maxFiftyCharactersRule.message });
    } else {
      sendEventToGTM({
        event: 'lb_create_new_rule',
        action: 'click',
        title: formValues.useCaseName,
        type: ruleType,
      });

      if (ruleType === 'simpleRule') {
        requestPayload.conditions = {
          nodes: ruleInitialState,
          startNode: 'rule_01',
        };
        void handleCreateAPI(requestPayload);
      } else if (ruleType === 'decisionTable') {
        requestPayload.conditions = {
          nodes: decisionTableInitialNodes,
          startNode: 'rule_01',
        };
        requestPayload.decisionTable = decisionTableInitialData;
        requestPayload.policy = 'first';
        void handleCreateAPI(requestPayload);
      } else if (ruleType === 'ruleSet') {
        requestPayload.ruleSetPolicy = 'first';
        void handleCreateAPI(requestPayload);
      }
    }
  };

  const handleCreateAPI = async (payload: Record<string, any>) => {
    if (
      !_isNil(subscriptionPlan) &&
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      checkLimitExceeded('rule', subscriptionPlan)
    ) {
      toasts.warning('Rule creation limit exceded', 'limit-exceeded');

      return false;
    }
    try {
      const response = await createMap[ruleType]({
        variables: { ...payload },
      });
      let ruleURL = `/rules/ID?type=edit&stage=staging&ruleType=${ruleType}&closeSheet=true&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;
      customClosePopup();

      if (!_isNil(searchParams.get('step'))) {
        ruleURL = `${ruleURL}&step=${searchParams.get('step') as string}`;
      }

      navigate(ruleURL.replace('ID', response.data.createRule.id));
    } catch (error: any) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };

  const handleSelectRuleType = (type: string) => {
    setRuleType(type);
  };

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const items: RuleConfigType[] = [];

  function checkSubModuleExist(moduleName: string) {
    return (
      !_isNil(subModules) &&
      !_isUndefined(subModules[moduleName]) &&
      subModules[moduleName]
    );
  }

  if (checkSubModuleExist(ruleTypeConfig.decisionTable.value)) {
    items.push(ruleTypeConfig.decisionTable);
  }

  if (checkSubModuleExist(ruleTypeConfig.simpleRule.value)) {
    items.push(ruleTypeConfig.simpleRule);
  }

  if (checkSubModuleExist(ruleTypeConfig.ruleSet.value)) {
    items.push(ruleTypeConfig.ruleSet);
  }

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoRuleCreated)) {
      updateWidgetState(demoRuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const handleINeedAssistance = () => {
    sendEventToGTM({
      event: 'lb_pf_ina',
      action: 'click',
      from: `tab_${currentTab}`,
    });
  };

  const renderPopupTabs = () => {
    const tabs = ['Build your own', 'Start from template'];
    let updatedTabs = tabs;

    if (deploymentType === 'onpremise') {
      updatedTabs = tabs.filter((i) => i !== 'Start from template');
    }

    return updatedTabs.map((tab, index) => {
      return <Tab key={index}>{tab}</Tab>;
    });
  };

  return (
    <Modal hideCloseButton={true} size="doubleExtraLarge">
      <CustomClose>
        <Button
          onClick={() => openImportModal({})}
          trailingIcon={<TbDownload />}
          disabled={isImportDisable}
          appearance="filled"
        >
          Import
        </Button>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'1.5rem'}>
          <Typography name="heading2">{title} </Typography>
          <TabsContainer>
            <Tabs onTabChange={toggleTabs} defaultOpen={currentTab}>
              <TabList>{renderPopupTabs()}</TabList>
              <TabPanels>
                <TabPanel>
                  <CreateRuleForm
                    items={items}
                    control={control}
                    ruleType={ruleType}
                    handleSelectRuleType={handleSelectRuleType}
                  />
                </TabPanel>
                <TabPanel>
                  <StartFromTemplate
                    selectDemoEntity={selectDemoRule}
                    selectedDemoObj={demoObj}
                    demoEntity={demoRules}
                    searchedText={searchedText}
                    setSearchedText={setSearchedText}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabsContainer>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <RuleTemplateFooterContainer>
          {deploymentType !== 'onpremise' && (
            <Button
              appearance="neutral"
              onClick={handleINeedAssistance}
              id="i_need_assistance_list_popup"
            >
              <img src={`${envMap.VITE_ASSETS_URL}home/assist_small.png`} />I
              need assistance
            </Button>
          )}

          <Inline align="center">
            <Threshold
              entity="rule"
              current={subscriptionPlan?.usage.rule}
              max={subscriptionPlan?.plan.ruleLimit}
            />
            <Button
              disabled={
                checkLimitExceeded('rule', subscriptionPlan) || createDisable
              }
              onClick={handleFooterClose}
            >
              Next
            </Button>
          </Inline>
        </RuleTemplateFooterContainer>
      </ModalFooter>
    </Modal>
  );
};

const CreateRuleForm = ({
  items,
  control,
  ruleType,
  handleSelectRuleType,
}: CreateRuleFormProps) => {
  return (
    <>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Rule Name</Typography>
        <TextField
          size="medium"
          name="useCaseName"
          rules={{
            required: {
              value: true,
              message: 'Required Field',
            },
            maxLength: maxFiftyCharactersRule,
          }}
          showErrorIcon={false}
          control={control}
          placeholder="Enter name"
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Rule Description (Optional)</Typography>
        <TextAreaField
          isDefaultOutlineVisible={true}
          isShowingEllipsis={true}
          control={control}
          name="ruleDescripiton"
          placeholder="Enter description"
          size="large"
        />
      </Stack>
      <Typography name="heading4">Select rule type</Typography>
      <Inline>
        {items.map((i, index) => {
          const ruleTypeObj = i;

          return (
            <PadBox
              onClick={() => handleSelectRuleType(ruleTypeObj.value)}
              key={index}
              padding="1.5rem"
              gutter="1.5rem"
              as={CreateRuleBlock}
              type={ruleTypeObj.value}
              selected={ruleType === ruleTypeObj.value}
            >
              <Stack>
                <RuleTypePill type={ruleTypeObj.value} name={'heading4'}>
                  {ruleTypeObj.label}
                </RuleTypePill>
                <Typography name="secondary2" fontWeight={400}>
                  {ruleTypeObj.description}
                </Typography>
              </Stack>
            </PadBox>
          );
        })}
      </Inline>
    </>
  );
};
