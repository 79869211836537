import { ApolloError } from '@apollo/client';
import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _upperFirst from 'lodash/upperFirst';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Sheet, Typography, toasts, useCurrentLayer, useLayer } from 'ui';
import { z } from 'zod';

import { FormWrapper } from '../../../components/rules/forms/FormWrapper';
import { showGraphQlErrorToast } from '../../../utils/common';
import { stopPropagate } from '../../../utils/form';
import { useCreateModuleSchemas } from '../hooks/graphql/useCreateModuleSchema';
import { useDeleteModuleSchema } from '../hooks/graphql/useDeleteModuleSchema';
import { useUpdateModuleSchema } from '../hooks/graphql/useUpdateModuleSchema';
import { moduleSchema } from '../schema';
import type {
  CreateModuleSchemaSheetProps,
  ModuleSchemaFormValues,
} from '../types';
import { schemaFormValidation } from '../validation';
import { SchemaClose } from './schemaExitModal';
import { SchemaForm } from './schemaForm';

export const schemaSavingErrorAtom = atom('');
export const CreateModuleSchemaSheet = ({
  moduleId,
  moduleName,
  refetchSchemaList,
  schemaData,
}: CreateModuleSchemaSheetProps) => {
  const { createSchemas, error } = useCreateModuleSchemas();
  const [updateSchema] = useUpdateModuleSchema();
  const [deleteModuleSchema] = useDeleteModuleSchema();

  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  const { close: closeSchemaSheet } = useCurrentLayer();

  const [schemaSavingError, setSchemaSavingError] = useAtom(
    schemaSavingErrorAtom
  );

  const hasSchemaId = !_isNil(schemaData) && !_isNil(schemaData.id);

  const closeSheet = () => {
    setSchemaSavingError('');
    closeSchemaSheet();

    if (action === 'add') {
      window.history.back();
    }
  };

  const { openWithProps: openSchemaExitModal } = useLayer(
    <SchemaClose onClose={closeSheet} title="Exit Attribute" />
  );

  const setSchemaSavedFalse = () => {
    setSchemaSavingError('');
    closeSchemaSheet();

    if (action === 'add') {
      window.history.back();
    }
  };

  const sheetCloseHandler = () => {
    openSchemaExitModal({
      description: (
        <span>
          Are you sure you want to exit? You will lose the recent changes
          because {schemaSavingError}
        </span>
      ),
    });
  };

  const FormValidationSchema = z.object({
    moduleSchema: z.array(moduleSchema),
  });

  const { control, setValue, handleSubmit, setError, watch } =
    useForm<ModuleSchemaFormValues>({
      resolver: zodResolver(FormValidationSchema),
      defaultValues: {
        moduleSchema: [],
      },
      mode: 'onChange',
    });

  const { fields, append, remove } = useFieldArray({
    name: 'moduleSchema',
    control,
  });

  useEffect(() => {
    if (!_isNil(schemaData)) {
      setValue('moduleSchema', [
        {
          ...schemaData,
          dataType: {
            label: _upperFirst(schemaData.dataType),
            value: schemaData.dataType,
          },
          value:
            schemaData?.options.map((obj: Record<string, any>) => obj.value) ??
            [],
        },
      ]);
    }
  }, [JSON.stringify(schemaData)]);

  const handleCreate = async (data: ModuleSchemaFormValues) => {
    try {
      if (data.moduleSchema.length === 0) {
        toasts.error('Add at least one attribute to save', 'schema-save-error');

        return;
      }

      const isValid = schemaFormValidation(data, setError);

      if (!isValid) {
        return;
      }

      const schemaArray = data.moduleSchema.map((obj, index: number) => {
        const finalOption = obj?.value?.map((v: any) => ({ value: v })) ?? [];

        return {
          ...obj,
          options: finalOption,
        };
      });

      const result: any = await createSchemas(schemaArray, moduleId);

      if (!_isNil(result) && !_isEmpty(result)) {
        setSchemaSavingError('');
        closeSchemaSheet();
        refetchSchemaList();

        if (action === 'add') {
          window.history.back();
        }
      }
    } catch (err) {}
  };

  const handleUpdate = async (data: ModuleSchemaFormValues) => {
    try {
      const schemaData = data.moduleSchema?.[0] ?? {};

      const isValid = schemaFormValidation(data, setError);

      if (!isValid) {
        return;
      }

      if (!isValid) {
        return;
      }

      const result = await updateSchema({
        variables: {
          ...schemaData,
          dataType: schemaData.dataType.value,
          options: schemaData?.value?.map((v: any) => ({ value: v })) ?? [],
        },
      });

      if (!_isNil(result) && !_isEmpty(result)) {
        setSchemaSavingError('');
        closeSchemaSheet();
        refetchSchemaList();
      }
    } catch (err) {}
  };

  const onSubmit = (data: ModuleSchemaFormValues) => {
    if (_isNil(schemaData) || _isEmpty(schemaData)) {
      void handleCreate(data);
    } else {
      void handleUpdate(data);
    }
  };

  const handleDeleteSchema = async () => {
    const id = schemaData?.id;
    const checksum = schemaData?.checksum;

    try {
      await deleteModuleSchema({
        variables: {
          id,
          checksum,
        },
      });

      toasts.success('Attribute deleted successfully', 'deleted-success');
      setSchemaSavingError('');
      closeSchemaSheet();
      refetchSchemaList();
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to schema module', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      if (error.graphQLErrors.length > 1) {
        error.graphQLErrors.forEach((i, index) => {
          control.setError(`moduleSchema.${index}.name`, {
            type: 'manual',
            message: i.message,
          });
        });
      } else {
        showGraphQlErrorToast(error);
      }
    }
  }, [error]);

  const handleSaveData = stopPropagate(handleSubmit((data) => onSubmit(data)));

  return (
    <Sheet
      size="medium"
      onClose={
        _isEmpty(schemaSavingError) ? setSchemaSavedFalse : sheetCloseHandler
      }
    >
      <FormWrapper>
        <Stack gutter={15}>
          <Inline stretch="start">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <Inline align="center" gutter="1.6rem" justify="start">
                <Typography name="heading2">
                  {hasSchemaId
                    ? schemaData.name
                    : `Add Attributes : (${moduleName})`}
                </Typography>
              </Inline>
            </Stack>
          </Inline>
        </Stack>
        <SchemaForm
          fields={fields}
          append={append}
          remove={remove}
          control={control}
          watch={watch}
          setValue={setValue}
          hasSchemaId={hasSchemaId}
          onSubmit={handleSaveData}
          handleDeleteSchema={handleDeleteSchema}
        />
      </FormWrapper>
    </Sheet>
  );
};
