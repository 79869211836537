import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { updateWidgetState } from '../../../../pages/Home/components/sub-components/UpdateWidgetState';
import { useGenrateEntityId } from '../../../../pages/Home/components/sub-components/useGenerateEntityId';
import {
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
} from '../../../../utils/common';
import { demoModuleCreated, envMap } from '../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../utils/validation';
import { StackAsItem } from '../../../layouts/Stack.styled';
import { CustomClose, RuleTemplateFooterContainer } from '../../listing.styled';
import { useCreateModule } from '../hooks/graphql/useCreateModule';
import { DemoObjProps, StartFromTemplate } from './StartFromTemplate';

type CreateModuleFormProps = {
  control: Control<any>;
};

type ModuleTemplatePopupProps = {
  title: string;
  type: 'MODULE_TEMPLATE';
  selectedTab: number;
  subModules?: Record<string, boolean>;
};
const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export const ModuleTemplatePopup = ({
  title,
  type,
  selectedTab,
  subModules,
}: ModuleTemplatePopupProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState(selectedTab ?? 0);
  const [searchedText, setSearchedText] = useState('');

  const [selectedDemoObj, setSelectedDemoObj] = useState<DemoObjProps | null>(
    null
  );
  const { control, watch, setError } = useForm<Record<string, string>>({
    mode: 'onChange',
  });

  const { close: closePopup } = useCurrentLayer();
  const { sendEventToGTM } = useSendEventToGTM();

  const { generateEntityId } = useGenrateEntityId();
  const [createModule] = useCreateModule();

  const formValues = watch();

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const customClosePopup = () => {
    sendEventToGTM({
      event: 'lb_popup_closed',
      action: 'click',
      type,
      title,
    });
    closePopup();
  };

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoModuleCreated)) {
      updateWidgetState(demoModuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const handleCreateModule = async () => {
    if (_isNil(formValues.useCaseName) || _isEmpty(formValues.useCaseName)) {
      setError('useCaseName', { message: 'name is required.' });

      return;
    } else if (formValues.useCaseName.length > maxFiftyCharactersRule.value) {
      setError('useCaseName', { message: maxFiftyCharactersRule.message });

      return;
    }

    try {
      const payload: Record<string, any> = {
        name: formValues.useCaseName,
        description: formValues.moduleDescripiton ?? '',
      };
      const response = await createModule({
        variables: { ...payload },
      });

      const moduleURL = `/module/ID?type=edit&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;
      customClosePopup();
      handleSetCheckSumByEntityName(
        'module',
        response.data.createModule.checksum
      );
      navigate(moduleURL.replace('ID', response.data.createModule.id));
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };

  const handleCreateDemoWorkflow = () => {
    if (!_isNil(selectedDemoObj)) {
      let moduleURL = `/module/ID?type=edit&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;

      if (!_isNil(searchParams.get('step'))) {
        moduleURL = `${moduleURL}&step=${searchParams.get('step') as string}`;
      }

      const entityId = selectedDemoObj?.data?.entityId;

      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        sendEventToGTM({
          event: 'lb_demo_workflow_open',
          action: 'click',
          type: selectedDemoObj.category,
          title,
          entityId,
        });
        navigate(moduleURL.replace('ID', entityId));
      } else {
        sendEventToGTM({
          event: 'lb_demo_workflow_created',
          action: 'click',
          type: selectedDemoObj.category,
          title,
          entityId: selectedDemoObj.id,
        });
        void updateUserState();
        void createEntityAndOpen(selectedDemoObj.id, moduleURL, '_self');
      }
    }
  };

  const handleFooterClose = () => {
    if (currentTab === 1) {
      handleCreateDemoWorkflow();
    } else {
      void handleCreateModule();
    }
  };

  const RenderPopupTabs = useMemo(() => {
    const tabs = ['Add'];
    let updatedTabs = tabs;

    if (deploymentType === 'onpremise') {
      updatedTabs = tabs.filter((i) => i !== 'Start from template');
    }

    return updatedTabs.map((tab, index) => {
      return <Tab key={index}>{tab}</Tab>;
    });
  }, [deploymentType]);

  const handleINeedAssistance = () => {
    sendEventToGTM({
      event: 'lb_pf_ina',
      action: 'click',
      from: `tab_${currentTab}`,
    });
  };

  return (
    <Modal hideCloseButton={true} size="extraLargeXS">
      <CustomClose>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'1.5rem'}>
          <Typography name="heading2">{title} </Typography>
          <StackAsItem>
            <Tabs onTabChange={setCurrentTab} defaultOpen={currentTab}>
              <TabList>{RenderPopupTabs}</TabList>
              <TabPanels>
                <TabPanel>
                  <CreateModuleForm control={control} />
                </TabPanel>
                <TabPanel>
                  <StartFromTemplate
                    selectDemoEntity={setSelectedDemoObj}
                    selectedDemoObj={selectedDemoObj}
                    demoEntity={[]}
                    searchedText={searchedText}
                    setSearchedText={setSearchedText}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </StackAsItem>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <RuleTemplateFooterContainer>
          {deploymentType !== 'onpremise' && (
            <Button
              appearance="neutral"
              onClick={handleINeedAssistance}
              id="i_need_assistance_list_popup"
            >
              <img src={`${envMap.VITE_ASSETS_URL}home/assist_small.png`} />I
              need assistance
            </Button>
          )}

          <Inline align="center">
            <Button onClick={handleFooterClose}>Next</Button>
          </Inline>
        </RuleTemplateFooterContainer>
      </ModalFooter>
    </Modal>
  );
};

const CreateModuleForm = ({ control }: CreateModuleFormProps) => {
  return (
    <>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Name</Typography>
        <TextField
          size="medium"
          name="useCaseName"
          rules={{
            required: {
              value: true,
              message: 'Required Field',
            },
            maxLength: maxFiftyCharactersRule,
          }}
          showErrorIcon={false}
          control={control}
          placeholder="Enter name"
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Description (Optional)</Typography>
        <TextAreaField
          isDefaultOutlineVisible={true}
          isShowingEllipsis={true}
          control={control}
          name="moduleDescripiton"
          placeholder="Enter description"
          size="medium"
        />
      </Stack>
    </>
  );
};
