import { gql, useLazyQuery } from '@apollo/client';

const getRuleStatus = gql`
  query getRule($id: String) {
    getRule(id: $id) {
      data {
        id
        status
      }
    }
  }
`;

export function useGetRuleStatusAfterTest() {
  return useLazyQuery(getRuleStatus, {
    fetchPolicy: 'no-cache',
  });
}
