import { gql, useLazyQuery } from '@apollo/client';

import { TableDataItemModel } from '../../types';

type filtersType = {
  in: Record<string, any>;
  eq: Record<string, any>;
  nin: Record<string, any>;
  ne: Record<string, any>;
  gt: Record<string, any>;
  gte: Record<string, any>;
  lt: Record<string, any>;
  lte: Record<string, any>;
};

const getSchemaData = gql`
  query modSchema(
    $id: String
    $page: Int
    $perPage: Int
    $sort: Map
    $filters: QueryOperators
  ) {
    getModuleSchema(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      filters: $filters
    ) {
      data {
        checksum
        id
        name
        usageType
        options {
          value
        }
        moduleId
        dataType
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

export type TableSchemaResponseModel = {
  getModuleSchema: {
    data: TableDataItemModel[];
    paginationInfo: {
      perPage: number;
      totalRecord: number;
      totalPage: number;
    };
  };
};

export type TablePayloadVariables = {
  page: number;
  perPage: number;
  live?: boolean;
  sort?: Record<string, any>;
  filters?: filtersType;
};

export function useGetSchemaData() {
  return useLazyQuery<TableSchemaResponseModel, TablePayloadVariables>(
    getSchemaData
  );
}
