import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useState } from 'react';
import {
  Button,
  Dataset,
  Modal,
  ModalContent,
  OnClickRuleArgs,
  Typography,
  useCurrentLayer,
} from 'ui';
import { ModalCloseButton } from 'ui/src/Modal/ModalCloseButton';

import {
  PredefineTokenPopover,
  onClickTokenSelectionArgs,
} from '../PredefineTokenPopover/PredefineTokenPopover';

type PredefineTokenModalProps = {
  tokenDataset: Record<string, Dataset>;
  onTokenSelection?: (data: onClickTokenSelectionArgs) => {
    canCloseModal: boolean;
  };
  onMultiTokenSelection?: (data: onClickTokenSelectionArgs[]) => {
    canCloseModal: boolean;
  };
  showMultiSelect?: boolean;
};

export const PredefineTokenModal = ({
  tokenDataset,
  onTokenSelection,
  showMultiSelect = false,
  onMultiTokenSelection,
}: PredefineTokenModalProps) => {
  const { close: closeModal } = useCurrentLayer();
  const [selectedCheckboxData, setSelectedCheckboxData] = useState<
    Record<string, OnClickRuleArgs>
  >({});

  const handleImportAttr = () => {
    if (typeof onMultiTokenSelection === 'function') {
      const selectedAttrlist: onClickTokenSelectionArgs[] = [];

      Object.keys(selectedCheckboxData).forEach((key) => {
        const currObj = selectedCheckboxData[key];

        let schemaId;
        Object.keys(tokenDataset[currObj.key].attributes).forEach((attrKey) => {
          if (attrKey === currObj.value) {
            schemaId = tokenDataset[currObj.key].attributes[attrKey].id;
          }
        });

        selectedAttrlist.push({
          schemaId,
          moduleName: currObj.key,
          schemaName: currObj?.value,
          dataType: currObj.dataType,
        });
      });

      const { canCloseModal } = onMultiTokenSelection(selectedAttrlist);

      if (canCloseModal) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      size="large"
      padding={0}
      hideCloseButton={true}
      title={
        <PadBox
          padding="1.5rem"
          style={{ borderBottom: '1px solid var(--color-darkGray)' }}
        >
          <Inline align="center" style={{ justifyContent: 'space-between' }}>
            <Typography name="heading2">Attribute Library</Typography>
            <ModalCloseButton onClick={closeModal} />
          </Inline>
        </PadBox>
      }
      overflow="none"
    >
      <ModalContent>
        <div
          style={{
            maxHeight: '50rem',
            overflow: 'auto',
          }}
        >
          <PadBox padding={[10, 15]}>
            <PredefineTokenPopover
              dataset={tokenDataset}
              onTokenSelection={(data) => {
                if (typeof onTokenSelection === 'function') {
                  const { canCloseModal } = onTokenSelection(data);

                  if (canCloseModal) {
                    closeModal();
                  }
                } else {
                  closeModal();
                }
              }}
              showMultiSelect={showMultiSelect}
              selectedCheckboxData={selectedCheckboxData}
              setSelectedCheckboxData={setSelectedCheckboxData}
            />
          </PadBox>
        </div>
      </ModalContent>
      {showMultiSelect && (
        <PadBox
          padding={[10, 15]}
          style={{ borderTop: '0.5px solid var(--color-darkGray)' }}
        >
          <Button onClick={handleImportAttr}>Import to input Attributes</Button>
        </PadBox>
      )}
    </Modal>
  );
};
