import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RxCrossCircled } from 'react-icons/rx';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Typography } from 'ui';

import { RuleExecutionOutput } from '../../../../components/jsonOutputField';
import { PaddingAsPropType } from '../../../../types';
import { RULE_TEST_RESPONSE_DEFAULT } from '../../fixtures/rules';
import type { RuleTestResponse } from '../../hooks/useServerResponse';
import type { ActionInfoObject, RuleTestActionObject } from '../../types';
import { RuleExecutionAction } from '../RuleExecutionAction/RuleExecutionAction';
import { GetActionTitle } from './GetActionTitle';
import {
  RuleExecutionTestStyled,
  StyledLink,
} from './RuleExecutionTest.styled';

type RuleExecutionTestProps = {
  serverResponse: RuleTestResponse;
  isTesting: boolean;
  actionResponse: RuleTestActionObject[];
  actionInfo: ActionInfoObject;
  handleExpandIconClick: (actionName: string) => void;
  overrideOutput?: Record<string, any>;
  forceStatus?: boolean;
  disableAction?: boolean;
  link?: string;
};

export function RuleExecutionTest({
  serverResponse = RULE_TEST_RESPONSE_DEFAULT,
  isTesting,
  actionResponse,
  actionInfo,
  handleExpandIconClick,
  overrideOutput,
  forceStatus,
  disableAction = false,
  link,
}: RuleExecutionTestProps) {
  const { executionTime, json } = serverResponse;

  return (
    <RuleExecutionTestStyled>
      <Tabs>
        <TabList
          tabEnd={
            <Inline
              justify="end"
              align="center"
              style={{
                height: '4rem',
                padding: '0.6rem',
              }}
            >
              {_isEmpty(executionTime) && _isNil(forceStatus) && (
                <Inline align="center" gutter={5}>
                  <RxCrossCircled color="var(--color-fireEngineRed)" />

                  <Typography name="error">Failed</Typography>
                </Inline>
              )}

              {forceStatus === true ||
                (!_isEmpty(executionTime) && (
                  <Inline align="center" gutter={5}>
                    <BsCheckCircleFill fill="green" />

                    <Typography name="success">
                      Success {executionTime ?? ''}
                    </Typography>
                  </Inline>
                ))}
            </Inline>
          }
        >
          <Tab>Output</Tab>
          <Tab disabled={disableAction}>
            <GetActionTitle actionInfo={actionInfo} />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {!_isNil(link) && !_isEmpty(link) && (
              <StyledLink to={link} target="_blank">
                View records in audit trail
              </StyledLink>
            )}
            <RuleExecutionOutput
              executionTime={executionTime}
              isTesting={isTesting}
              responseJson={
                !_isNil(overrideOutput)
                  ? JSON.stringify(overrideOutput ?? {}, null, 2)
                  : json
              }
              forceStatus={forceStatus}
            />
          </TabPanel>
          <TabPanel>
            <RuleExecutionAction
              handleExpandIconClick={handleExpandIconClick}
              actionResponse={actionResponse}
              padding={['1.6rem', '1rem'] as PaddingAsPropType}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </RuleExecutionTestStyled>
  );
}
