import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ApiSettingsContainer = styled(PadBox)`
  max-block-size: 100vh;
  overflow-y: auto;
`;

export const JsonContainerStyled = styled(PadBox)`
  height: 40rem;
  border: 1px solid var(--color-primary2);
`;

export const CodeStyled = styled.code`
  background-color: var(--color-whiteSmoke);
`;

export const CreateConnector = styled(Inline)`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);
`;

export const AuthKeyContainer = styled(Inline)`
  width: 50%;
  border-radius: 4px;
  flex-grow: 1;
  max-width: 65rem;
  align-items: center;
`;

export const InlineStyled = styled(Inline)`
  justify-content: space-between;
  background-color: var(--color-solitude);
  flex-grow: 1;
  padding: 0rem 1.2rem;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
`;

export const KeyValueStyled = styled.p`
  word-break: break-all;
  color: var(--color-darkGray);
`;
