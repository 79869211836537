import { PadBox } from '@bedrock-layout/padbox';
import { Column, Columns, Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import { Control, UseFormSetValue, useForm, useWatch } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Button,
  Dataset,
  DropdownInput,
  ExpandingTextField,
  Image,
  NectedSuggestionModel,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  formatCustomAttributes,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom, usedConnectorMappingAtom } from '../../atom';
import { nodeSqlParserSupportedDatabases } from '../../pages/DataSets/components/DataSetForm';
import { SchemaContainer } from '../../pages/DataSets/components/DataSetForm.styled';
import { useGetDataSetSchema } from '../../pages/DataSets/hooks/useGetDataSetSchema';
import { useGetPublishedConnectors } from '../../pages/DataSets/hooks/useGetPublishedConnectors';
import type {
  DatabaseName,
  EditorConfigurationDetails,
} from '../../pages/DataSets/types';
import {
  getSuggestionsKeywordsNew,
  isMongoActionValid,
  nodeSqlParser,
  timeToExpireUnits,
} from '../../pages/DataSets/utils';
import {
  ConnectorAndPluginModel,
  KeyValuePairList,
} from '../../pages/Integrations/types';
import {
  createRuleSheetAtom,
  isRuleReadOnlyAtom,
  selectedDataSetAtom,
  simpleRuleNodeIdAtom,
} from '../../pages/Rules';
import {
  RuleTypes,
  dataSetParamsAtom,
  sectionAtom,
} from '../../pages/Rules/components/CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodeIdAtom } from '../../pages/Rules/components/DecisionTable/DecisionTable';
import { additionalDatasetAtom } from '../../pages/Rules/components/DecisionTable/components/DecisionTableActions';
import {
  DecisionTableResultRow,
  PublishedConnectors,
} from '../../pages/Rules/components/DecisionTable/types';
import { ruleSetNodeId } from '../../pages/Rules/components/RuleSet/RuleSet';
import type { ResultActionType } from '../../pages/Rules/components/SimpleRule/Results';
import { outputDatasetAtom } from '../../pages/Rules/components/SimpleRule/Results/DataActions';
import { ResultAddDataModel } from '../../pages/Rules/components/SimpleRule/models';
import { useUpdateExecutedValueRules } from '../../pages/Rules/hooks/useUpdateExecutedValueRules';
import type { ResultAction } from '../../pages/Rules/types';
import {
  filterDataSetSuggestionObjBySection,
  filterDataSetSuggestionsBySection,
  getDataSetsQueryByName,
  getFilteredDataSetObjWithoutOptional,
  getFilteredDataSetWithoutOptional,
  getRequiredKey,
  getUpdatedTokensByType,
  updateDataSetOnChange,
  validateRestApiAction,
} from '../../pages/Rules/utils/common';
import { getDtOutputRows } from '../../pages/Rules/utils/decisionTable';
import { queryOptions } from '../../pages/Workflow/components/Sheets/ConnectorActionSheet/constant';
import { useGenerateDataset } from '../../pages/Workflow/hooks/useGenerateDataset';
import { sanitizedStringV2 } from '../../pages/Workflow/utils/common';
import {
  generateUid,
  isValidImageURL,
  transformResponseCache,
} from '../../utils/common';
import { SQL_COMMANDS, TokenScores } from '../../utils/constant';
import { KEY_REGEX, REMOVE_JAVASCRIPT_COMMENTS_REGEX } from '../../utils/regex';
import {
  connectorPluginsToIgnoreForActions,
  publishedConnectorsAtom,
} from '../CreateAction/CreateAction';
import type { FormFields, Option } from '../Form/types';
import {
  getInitialFormValuesBasedOnFields,
  getTransformedSortedFormFields,
  getUpdatedApiDataBasedOnFormConfigForNestedObject,
  parseExpression,
  parseFormDataForNestedData,
  validateFormData,
} from '../Form/utlis';
import { HowToLink } from '../HowToLink/HowToLink';
import { IntegrationField } from '../IntegrationField/IntegrationField';
import { QueryEditor } from '../QueryEditor/QueryEditor';
import { ResponseCaching } from '../ResponseCaching/ResponseCaching';
import SchemaViewer from '../SchemaViewer';
import { StackAsItem } from '../layouts/Stack.styled';
import { customAttributesAtom } from '../rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  ActionBody,
  ActionBodyContainer,
  ActionFooter,
  ActionNamePill,
  ColumnStyled,
  SyntaxErrorContainer,
} from './ActionSheet.styled';
import { EditorContainer } from './ActionSheetPill.styled';
import { ActionForm } from './components/ActionForm/ActionForm';
import { RestAPI } from './components/RestAPI/RestAPI';
import { useGetActionMethods } from './hooks/useGetActionMethods';

type ActionSheetProps = {
  connectorId?: string;
  suggestions?: string[];
  index: number;
  sheetMode: string;
  section?: ResultActionType;
  control?: Control<any>;
  field?: Record<string, any>;
  type?: DatabaseName | 'restAPI';
  editorDetails?: EditorConfigurationDetails | null;
  setValue?: UseFormSetValue<any>;
  connector?: ConnectorAndPluginModel | null;
  suggestionsObjs?: NectedSuggestionModel[];
};

export default function ActionSheet({
  section = 'thenActionParams',
  suggestions = [],
  control,
  connectorId,
  field,
  index,
  type = 'mysql',
  editorDetails,
  setValue,
  connector,
  suggestionsObjs = [],
  sheetMode = '',
}: ActionSheetProps) {
  const [disabledHeaders, setDisabledHeaders] = useState<KeyValuePairList[]>(
    []
  );
  const [lastCursorObj, setLastCursorObj] = useState({
    row: 0,
    col: 0,
    name: '',
  });
  const [disabledQueryParams, setDisabledQueryParams] = useState<
    KeyValuePairList[]
  >([]);
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [connectorList, setConnectorList] = useState<any[]>([]);

  const [formJson, setFormJson] = useState<Record<string, any>>({});
  const [formFields, setFormFields] = useState<FormFields[]>([]);
  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const { ruleId } = useParams();
  const [searchParams] = useSearchParams();

  const [parentFormData, setParentFormData] = useState<Record<string, any>>({});

  const [siteConstants] = useAtom(siteConstantsAtom);

  const DATA_BASE_ACTION_TYPE = [
    'mysql',
    'pgsql',
    'mongodb',
    'snowflake',
    'oracle',
    'redshift',
    'sqlserver',
  ];
  const formKeyPrefix = 'action.config.';

  const showDataBase = DATA_BASE_ACTION_TYPE.includes(type);
  const showRestAPI = type === 'restAPI';

  const isSheetSizeLarge = DATA_BASE_ACTION_TYPE.includes(type);
  const showActionForm =
    !DATA_BASE_ACTION_TYPE.includes(type) && type !== 'restAPI';

  const [getActionMethods, { data: actionMethodsQueryData }] =
    useGetActionMethods();

  const { data: connectorData, refetch: refetchConnectors } =
    useGetPublishedConnectors();

  const sectionData = useWatch({ control, name: `${section}.${index}` });

  const {
    handleSubmit,
    setValue: setLocalValue,
    control: localControl,
    watch: localWatch,
    setError,
    clearErrors,
  } = useForm<any>({
    defaultValues: {
      action: {
        name: sectionData?.name ?? '',
        integration: null,
        config: {
          query: getDataSetsQueryByName(siteConstants, connector),
          headers: null,
          method: null,
          queryParams: null,
          bodyParams: null,
          body: null,
          path: null,
          settings: {},
        },
      },
    },
    reValidateMode: 'onChange',
  });

  const selectedConnector = localWatch('action.integration');

  const selectedConnectorId = selectedConnector?.value ?? connectorId ?? '';

  const { schemas, getDataSetSchema } = useGetDataSetSchema(
    selectedConnectorId,
    'staging',
    showDataBase
  );

  const [publishedConnectors, setPublishConnectors] = useAtom(
    publishedConnectorsAtom
  );
  const [customAttributes] = useAtom(customAttributesAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [currentSection] = useAtom(sectionAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [outputDataset] = useAtom(outputDatasetAtom);
  const [additionalDataSet] = useAtom(additionalDatasetAtom);

  const [usedConnectorMapping, setUsedConnectorMapping] = useAtom(
    usedConnectorMappingAtom
  );
  const [dtId] = useAtom(decisionTableNodeIdAtom);
  const [srId] = useAtom(simpleRuleNodeIdAtom);
  const [rsId] = useAtom(ruleSetNodeId);

  const { close } = useCurrentLayer();

  const query = useWatch({
    name: `action.config.query`,
    control: localControl,
  });

  const fields = useWatch({
    name: currentSection,
    control,
  });

  const results: DecisionTableResultRow[] = useWatch({
    name: 'results',
    control,
  });

  const selectedActionMethod: Option = useWatch({
    name: `action.config.actionMethod`,
    control: localControl,
  });

  const initialize = (
    data: any,
    name: string,
    setData: UseFormSetValue<any>,
    keepUnit: boolean = true
  ) => {
    if (!_isNil(data)) {
      setLocalValue('action.integration', {
        label: publishedConnectors[data.connectorId]?.name,
        value: data.connectorId,
      });

      setData(`${name}.connectorId`, data?.integration?.value ?? null);

      if (showActionForm) {
        for (const key in data.config) {
          if (!_isNil(data.config[key])) {
            setData(`${name}.config.${key}`, data.config[key]);
          }
        }

        if (!_isNil(data.name)) {
          setData(`${name}.name`, data.name);
        }
      } else {
        if (!_isNil(data.name)) {
          setData(`${name}.name`, data.name);
        }

        if (!_isNil(data.config?.query) && !_isEmpty(data.config.query)) {
          setData(`${name}.config.query`, data.config.query);
        }

        if (!_isNil(data.config?.queryParams)) {
          setData(`${name}.config.queryParams`, data.config.queryParams);
        }

        if (!_isNil(data.config?.headers)) {
          setData(`${name}.config.headers`, data.config.headers);
        }

        if (!_isNil(data.config?.body)) {
          setData(`${name}.config.body`, data.config.body);
        }

        if (!_isNil(data.config?.bodyParams)) {
          setData(`${name}.config.bodyParams`, data.config.bodyParams);
        }

        if (!_isNil(data.config?.method)) {
          setData(`${name}.config.method`, data.config.method);
        }

        if (!_isNil(data.config?.path)) {
          setData(`${name}.config.path`, data.config.path);
        }

        if (!_isNil(data.config?.contentType)) {
          setData(`${name}.config.contentType`, data.config.contentType);
        }

        if (!_isNil(data.config?.settings)) {
          const cache = keepUnit
            ? {
                enabled: data.config?.settings?.cache?.enabled ?? false,
                duration: {
                  unit:
                    timeToExpireUnits.find(
                      (u) =>
                        u.value === data.config?.settings?.cache?.duration?.unit
                    ) ?? timeToExpireUnits[0],
                  value:
                    !_isNil(data.config?.settings?.cache?.duration?.value) &&
                    data.config?.settings?.cache?.duration?.value !== ''
                      ? data.config?.settings?.cache?.duration?.value
                      : 0,
                },
                cacheKeys: data.config?.settings?.cache?.cacheKeys ?? '',
              }
            : transformResponseCache(data.config.settings?.cache);

          setData(`${name}.config.settings`, {
            ...data.config.settings,
            cache,
          });
        }
      }
    }
  };

  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [customSuggestions, setCustomSuggestions] = useState<string[]>([]);
  const [customSuggestionObj, setCustomSuggestionObj] = useState<
    NectedSuggestionModel[]
  >([]);

  const [, setIsValidQuery] = useState(true);
  const [, setDataSetResults] = useState<ResultAddDataModel[]>([]);

  const additionalData = useWatch({ name: 'additionalData', control });

  const stringWithReplacedToken = !_isEmpty(query)
    ? sanitizedStringV2(query, updatedDataset)
    : query;

  const updateParentFormData = () => {
    const formType = searchParams.get('type') ?? '';
    const ruleType = (searchParams.get('ruleType') as RuleTypes) ?? '';
    const ruleEnvironment = searchParams.get('stage') ?? '';

    setParentFormData({
      formType,
      ruleType,
      currentEnvironment: ruleEnvironment,
      ruleId,
      connectorId: selectedConnectorId,
    });
  };

  const localFormValues = localWatch();

  useEffect(() => {
    clearErrors();
  }, [JSON.stringify(localFormValues)]);

  useEffect(() => {
    updateParentFormData();
  }, [searchParams, ruleId, selectedConnectorId]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionObjList: NectedSuggestionModel[] = [];

    additionalData?.forEach((data: any) => {
      suggestionList.push(`{{.additionalData.${data.name as string}}}`);
      suggestionObjList.push({
        name: `{{.additionalData.${data.name as string}}}`,
        value: `{{.additionalData.${data.name as string}}}`,
        score: TokenScores.additionalData,
        meta: data.returnType,
        executedValue: data.executedValue ?? undefined,
      });
    });

    setCustomSuggestions(suggestionList);
    setCustomSuggestionObj(suggestionObjList);
  }, [additionalData]);

  useEffect(() => {
    const resultAddDataModels: ResultAddDataModel[] = [];

    results?.forEach((field) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      resultAddDataModels.push({
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        value: '',
      });
    });

    if (ruleType === 'decisionTable') {
      setDataSetResults(resultAddDataModels);
    }
  }, [results, ruleType]);

  useEffect(() => {
    if (ruleType === 'simpleRule') {
      setDataSetResults(fields);
    }
  }, [fields, ruleType]);

  useEffect(() => {
    const newSuggestions = [...suggestions];
    const newSuggestionObjs: NectedSuggestionModel[] = [...suggestionsObjs];

    if (!_isNil(schemas) && !_isEmpty(schemas)) {
      Object.keys(schemas).forEach((key) => {
        if (!(key in newSuggestions)) {
          newSuggestions.push(key);
        }

        if (type === 'mongodb') {
          newSuggestionObjs.push({
            name: `${key}`,
            value: `${key}`,
            score: 999,
            meta: 'collection',
          });
        } else {
          newSuggestionObjs.push({
            name: `${key}`,
            value: `${key}`,
            score: 999,
            meta: 'table',
          });
        }

        const attributes = schemas[key].attributes;

        Object.keys(attributes).forEach((attributeKey) => {
          if (type !== 'mongodb') {
            newSuggestions.push(`${key}.${attributes[attributeKey].name}`);

            if (attributes[attributeKey].dataType === 'json') {
              newSuggestionObjs.push({
                name: `${key}.${attributes[attributeKey].name}`,
                value: `${key}.${attributes[attributeKey].name}`,
                score: TokenScores[key] ?? 998,
                meta: attributes[attributeKey].dataType,
              });
            }
          } else {
            if (attributes[attributeKey].dataType === 'json') {
              newSuggestions.push(`${attributes[attributeKey].name}`);
              newSuggestionObjs.push({
                name: `${attributes[attributeKey].name}`,
                value: `${attributes[attributeKey].name}`,
                score: TokenScores[key] ?? 998,
                meta: attributes[attributeKey].dataType,
              });
            }
          }
        });
      });
    }

    setCustomSuggestions((prev) => {
      const validSuggestions: string[] = [];

      newSuggestions.forEach((sug) => {
        if (!prev.includes(sug)) {
          validSuggestions.push(sug);
        }
      });

      return [...prev, ...validSuggestions];
    });

    setCustomSuggestionObj((prev) => {
      const validSuggestions: NectedSuggestionModel[] = [];

      newSuggestionObjs.forEach((sug) => {
        if (prev.find((prevSug) => prevSug.value === sug.value) == null) {
          validSuggestions.push(sug);
        }
      });

      return [...prev, ...validSuggestions];
    });
  }, [schemas]);

  const rows = useWatch({ control, name: 'rows' });

  const updatedDatasetDt = useMemo(() => {
    if (ruleType !== 'decisionTable') {
      return updatedDataset;
    }

    const newDs = { ...updatedDataset };

    const finalRows = getDtOutputRows(results, rows, dataSetVariables);

    const outputData: Dataset = {
      attributes: {
        output: {
          dataType: 'list',
          name: 'output',
          executedValue: finalRows,
        },
      },
      name: 'Output Data Attributes',
      id: 'output_data_attributes',
    };

    return {
      ...newDs,
      outputData,
    };
  }, [JSON.stringify(updatedDataset)]);

  const { tokens: dataSetSuggestionsObjs } = useGenerateDataset({
    updatedDataset: updatedDatasetDt,
  });

  const { executedValue, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    let filteredDataSetVariablesObj = filterDataSetSuggestionObjBySection(
      dataSetSuggestionsObjs,
      section === 'thenActionParams' ? 'thenDataParams' : 'elseDataParams'
    );

    filteredDataSetVariablesObj = getFilteredDataSetObjWithoutOptional(
      filteredDataSetVariablesObj,
      customAttributes
    );

    setCustomSuggestionObj((prev) => {
      const validSuggestions: NectedSuggestionModel[] = [];

      [...filteredDataSetVariablesObj, ...suggestionsObjs].forEach((sug) => {
        if (prev.find((prevSug) => prevSug.value === sug.value) == null) {
          validSuggestions.push(sug);
        }
      });

      return [...prev, ...validSuggestions];
    });
  }, [JSON.stringify(dataSetSuggestionsObjs)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = getUpdatedTokensByType(dataSetVariables);

      const finalDataSet = {
        ...outputDataset,
        ...additionalDataSet,
        ...updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected,
          true
        ),
      };

      setUpdatedDataset(finalDataSet);

      let filteredDataSetVariables = filterDataSetSuggestionsBySection(
        dataSetSuggestions,
        section === 'thenActionParams' ? 'thenDataParams' : 'elseDataParams'
      );

      filteredDataSetVariables = getFilteredDataSetWithoutOptional(
        filteredDataSetVariables,
        customAttributes
      );

      setCustomSuggestions((prev) => {
        const validSuggestions: string[] = [];

        filteredDataSetVariables.forEach((sug) => {
          if (!prev.includes(sug)) {
            validSuggestions.push(sug);
          }
        });

        return [...prev, ...validSuggestions];
      });
    }
  }, [
    dataSetVariables,
    suggestions,
    suggestionsObjs,
    customAttributes,
    outputDataset,
    additionalDataSet,
  ]);

  const checkDbValidSyntax = async () => {
    if (!_isNil(editorDetails)) {
      if (type === 'mongodb') {
        if (
          isMongoActionValid(
            stringWithReplacedToken.replaceAll(
              REMOVE_JAVASCRIPT_COMMENTS_REGEX,
              ''
            ) ?? ''
          )
        ) {
          setIsValidQuery(true);
        } else {
          setIsValidQuery(false);
        }
      } else if (
        !showRestAPI &&
        nodeSqlParserSupportedDatabases.includes(type)
      ) {
        try {
          const module = await nodeSqlParser(type);
          const { Parser } = module;
          const parser = new Parser();

          try {
            const ast = parser.astify(stringWithReplacedToken, {
              database: editorDetails.databaseLabel,
            });

            if (Array.isArray(ast)) {
              setIsValidQuery(
                SQL_COMMANDS.includes(ast[0].type.toLocaleLowerCase())
              );
            } else {
              setIsValidQuery(ast.type === 'select');
            }
          } catch (error) {
            setIsValidQuery(false);
          }
        } catch (error: unknown) {}
      }
    }
  };

  useEffect(() => {
    void checkDbValidSyntax();
  }, [stringWithReplacedToken]);

  useEffect(() => {
    if (!_isNil(formJson) && !_isEmpty(formJson)) {
      const fields = getTransformedSortedFormFields(formJson, 'action.config.');
      setFormFields(fields);
    }
  }, [JSON.stringify(formJson)]);

  useEffect(() => {
    if (!_isUndefined(actionMethodsQueryData)) {
      const data = actionMethodsQueryData.getConnector.data;

      if (!_isNil(data) && data.length > 0) {
        const json = data[0].plugin.schema.action;
        setFormJson(structuredClone({ actionMethod: json.methods }));
      }
    }
  }, [JSON.stringify(actionMethodsQueryData)]);

  useEffect(() => {
    if (
      !_isNil(selectedActionMethod) &&
      !_isEmpty(selectedActionMethod) &&
      !_isNil(formJson) &&
      !_isEmpty(formJson)
    ) {
      const selectedAction = structuredClone(
        formJson.actionMethod.methodSchema[selectedActionMethod.value]
      );

      const prevForm = structuredClone(formJson);

      if (!_isNil(selectedAction)) {
        setFormJson({
          ...prevForm,
          ...selectedAction.params,
        });
      }
    }
  }, [selectedActionMethod, JSON.stringify(formJson)]);

  useEffect(() => {
    if (!showActionForm) initialize(sectionData, 'action', setLocalValue);
  }, [sectionData]);

  useEffect(() => {
    if (showActionForm && formFields.length > 0 && !_isNil(sectionData)) {
      if (sheetMode === 'edit') {
        const updatedSectionData =
          getUpdatedApiDataBasedOnFormConfigForNestedObject(formFields, {
            action: structuredClone(sectionData),
          });

        // Creating config data if field is hidden then consider value present in field
        // otherwise consider value from API Data.
        const finalConfigData = _reduce(
          formFields,
          (configData: Record<string, any>, field) => {
            const { hidden, key, value } = field;
            const isFieldHidden = parseExpression(
              updatedSectionData,
              parentFormData,
              hidden
            );

            const actualKey = key.replace(formKeyPrefix, '');

            return {
              ...configData,
              [actualKey]: (isFieldHidden as boolean)
                ? value
                : updatedSectionData.action.config[actualKey],
            };
          },
          {}
        );

        initialize(
          {
            ...updatedSectionData.action,
            name: localFormValues?.action?.name ?? '',
            config: {
              ...updatedSectionData.action.config,
              ...finalConfigData,
              actionMethod: !_isEmpty(finalConfigData.actionMethod)
                ? finalConfigData.actionMethod
                : selectedActionMethod,
            },
          },
          'action',
          setLocalValue
        );
      } else if (sheetMode === 'create') {
        const updatedSectionData =
          getUpdatedApiDataBasedOnFormConfigForNestedObject(formFields, {
            action: structuredClone(sectionData),
          });

        const defaultData = _reduce(
          JSON.parse(
            JSON.stringify(getInitialFormValuesBasedOnFields(formFields))
          ),
          (finalData: Record<string, any>, value, key) => {
            return {
              ...finalData,
              [key.replace(formKeyPrefix, '')]: value,
            };
          },
          {}
        );

        // Updating Form with default values of form fields and also retaining the selcted values in form.

        initialize(
          {
            ...updatedSectionData.action,
            name: localFormValues?.action?.name ?? '',
            config: {
              ...defaultData,
              actionMethod: selectedActionMethod,
            },
          },
          'action',
          setLocalValue
        );
      }
    }
  }, [JSON.stringify(formFields)]);

  useEffect(() => {
    if (!_isEmpty(selectedConnectorId) && showActionForm) {
      void fetchActionMethods();
    }

    // Update Connector Status in Used Connector Config
    if (!_isEmpty(selectedConnectorId)) {
      const connectorStatus =
        (publishedConnectors[selectedConnectorId].staging?.isTested ?? true) &&
        (publishedConnectors[selectedConnectorId].staging?.isPublish ?? true);

      const originalSource =
        usedConnectorMapping?.[selectedConnectorId]?.source ?? [];

      const source = originalSource?.includes('action')
        ? originalSource
        : [...originalSource, 'action'];

      setUsedConnectorMapping({
        ...usedConnectorMapping,
        [selectedConnectorId]: {
          status: connectorStatus,
          source,
        },
      });
    }
  }, [selectedConnectorId]);

  const fetchActionMethods = async () => {
    try {
      await getActionMethods({
        variables: {
          connectorId: connectorId ?? '',
        },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {}
  };

  const onSubmit = async (data: { action: ResultAction }) => {
    let isValid = true;

    if (type === 'restAPI') {
      isValid = validateRestApiAction(
        data.action,
        setError,
        'action',
        updatedDataset
      );
    }

    if (type !== 'restAPI' && !showActionForm && !isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'query-error'
      );

      return;
    }

    if (
      type !== 'restAPI' &&
      !showActionForm &&
      (!isQueryValidNected || _isEmpty(query))
    ) {
      isValid = false;
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'query-error'
      );
    }

    if (showActionForm) {
      isValid = validateFormData(data, formFields, setError);
    }

    if (
      !KEY_REGEX.test(data.action.name) ||
      data.action.name.trim().length === 0
    ) {
      setError(`action.name`, {
        message: 'Invalid Action Name',
        type: 'validate',
      });

      isValid = false;
    }

    if (!KEY_REGEX.test(data.action.name)) {
      setError('action.name', {
        message: 'Incorrect action name',
      });

      return;
    }

    if (!_isNil(setValue) && isValid && type === 'restAPI') {
      initialize(data.action, `${section}.${index}`, setValue, false);
      close();
    } else if (
      !showRestAPI &&
      !showActionForm &&
      !_isNil(setValue) &&
      isValid
    ) {
      initialize(data.action, `${section}.${index}`, setValue, false);
      close();
    } else if (showActionForm && !_isNil(setValue) && isValid) {
      const parsedData = parseFormDataForNestedData(formFields, data);
      initialize(parsedData.action, `${section}.${index}`, setValue, false);
      close();
    }
  };

  useEffect(() => {
    if (
      !_isNil(connector) &&
      type === 'restAPI' &&
      !_isNil(connector.staging.conf)
    ) {
      if (Array.isArray(connector.staging.conf.headers)) {
        setDisabledHeaders(connector.staging.conf.headers);
      } else {
        setDisabledHeaders(
          _map(connector.staging.conf.headers, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }

      if (Array.isArray(connector.staging.conf.queryParams)) {
        setDisabledQueryParams(connector.staging.conf.queryParams);
      } else {
        setDisabledQueryParams(
          _map(connector.staging.conf.queryParams, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }
    }
  }, [connector]);

  const pluginName = publishedConnectors[selectedConnectorId]?.plugin?.name;

  const schemaSuggestions = useMemo(() => {
    if (!_isNil(schemas) && !_isEmpty(schemas)) {
      return getSuggestionsKeywordsNew(
        schemas,
        connector?.plugin,
        pluginName as DatabaseName
      );
    }

    return [];
  }, [JSON.stringify(schemas)]);

  useEffect(() => {
    if (!_isNil(connectorData)) {
      const publishedConnectors = connectorData.getConnector.data
        .filter((connector) => connector.plugin.name === pluginName)
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      const publishedConnectorList = connectorData.getConnector.data.filter(
        (connector) => {
          const pluginName = connector.plugin.name;

          if (
            pluginName in connectorPluginsToIgnoreForActions &&
            connectorPluginsToIgnoreForActions[pluginName]
          ) {
            return false;
          }

          return true;
        }
      );

      const currentData: Record<string, Dataset> = {};
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectorList.length > 0) {
        publishedConnectorList.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;

          const name = connector.plugin.name;

          if (!_isNil(currentData[name])) {
            currentData[connector.plugin.name] = {
              ...currentData[connector.plugin.name],
              attributes: {
                ...currentData[connector.plugin.name].attributes,
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          } else {
            currentData[connector.plugin.name] = {
              name: connector.plugin.name,
              id: generateUid('connector_'),
              image: isValidImageURL(connector.plugin.imageUrl),
              attributes: {
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          }
        });
      }

      setPublishConnectors(publishConnectorIdAndPlugin);

      setConnectorList(publishedConnectors);
    }
  }, [connectorData, pluginName]);

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${pluginName}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`,
      window !== window.parent ? '_self' : '_blank'
    );
  };

  const ruleObject = useMemo(() => {
    if (ruleType === 'decisionTable') {
      return {
        type: 'rule',
        entityId: dtId,
      };
    } else if (ruleType === 'simpleRule') {
      return {
        type: 'rule',
        entityId: srId,
      };
    } else {
      return {
        type: 'rule',
        entityId: rsId,
      };
    }
  }, [srId, rsId, dtId, ruleType]);

  const handleQueryGenerated = (value: string) => {
    setLocalValue('action.config.query', value);
  };

  const handleColumnClick = (tableName: string, columnName: string) => {};

  const tabList = [<Tab key="config">Config</Tab>];

  if (showRestAPI) {
    tabList.push(<Tab key="settings">Settings</Tab>);
  }

  return (
    <Sheet size={showRestAPI || !isSheetSizeLarge ? 'small' : 'large'}>
      <ActionBody as="form" onSubmit={handleSubmit(onSubmit)}>
        <PadBox padding="1rem">
          <Inline stretch="start" align="center">
            <Typography name="heading2">
              <Inline align="center">
                <Inline>
                  <ExpandingTextField
                    control={localControl}
                    name={`action.name`}
                    disabled={isRuleReadOnly}
                  />
                </Inline>

                <ActionNamePill align="center" gutter="0.8rem">
                  {!_isNil(publishedConnectors[selectedConnector?.value]) && (
                    <>
                      <Image
                        src={isValidImageURL(
                          publishedConnectors[selectedConnector?.value]?.plugin
                            ?.imageUrl
                        )}
                        alt="connector"
                      />

                      <Typography>
                        {publishedConnectors[selectedConnector?.value]?.name}
                      </Typography>
                    </>
                  )}
                </ActionNamePill>
              </Inline>
            </Typography>

            <HowToLink />
          </Inline>
        </PadBox>
        <Tabs>
          <TabList>{tabList}</TabList>
          <TabPanels>
            <TabPanel>
              <ActionBodyContainer padding="1rem">
                <Stack gutter={10}>
                  {showDataBase && DATA_BASE_ACTION_TYPE.includes(type) && (
                    <EditorContainer databaseName={pluginName}>
                      <StackAsItem grow={1} as={Columns} columns={12}>
                        <ColumnStyled span={8}>
                          <IntegrationField
                            editPlugin={editPlugin}
                            refreshPlugins={async () =>
                              await refetchConnectors()
                            }
                            control={localControl}
                            name="action.integration"
                            connectorList={connectorList}
                            pluginId={connector?.plugin.id}
                            disabled={isRuleReadOnly}
                            connectorId={selectedConnectorId}
                          />

                          {showDataBase && pluginName !== 'mongodb' && (
                            <div>
                              <Inline gutter={8}>
                                <Typography fontWeight={700}>Action</Typography>
                              </Inline>
                              <DropdownInput
                                options={queryOptions}
                                value={queryOptions.find(
                                  (query) => query.value === 1
                                )}
                                disabled
                              />
                            </div>
                          )}
                          <QueryEditor
                            setError={setError}
                            from="actions"
                            control={localControl}
                            name={`action.config.query`}
                            customSuggestion={customSuggestions}
                            customSuggestionObj={[
                              ...customSuggestionObj,
                              ...schemaSuggestions,
                            ]}
                            databaseName={type as DatabaseName}
                            disabled={isRuleReadOnly}
                            setValidity={setIsQueryValidNected}
                            execValues={executedValue}
                            handleGetExecutionValues={handleGetExecutionValues}
                            setValue={setLocalValue}
                            lastCursorObj={lastCursorObj}
                            setLastCursorObj={setLastCursorObj}
                          />
                        </ColumnStyled>

                        <Column span={4}>
                          {showDataBase && (
                            <SchemaContainer>
                              <SchemaViewer
                                schema={schemas}
                                dbType={editorDetails?.databaseName ?? 'mysql'}
                                onQueryGenerated={handleQueryGenerated}
                                onColumnClick={handleColumnClick}
                                getDataSetSchema={getDataSetSchema}
                                method="read"
                                schemaHeading={'Schema'}
                                placeholder="Search tables and columns"
                              />
                            </SchemaContainer>
                          )}
                        </Column>
                      </StackAsItem>
                    </EditorContainer>
                  )}

                  {showRestAPI && (
                    <RestAPI
                      sectionName={section}
                      index={index}
                      control={localControl}
                      mainControl={control}
                      connectorId={
                        selectedConnector?.value ?? connectorId ?? ''
                      }
                      disabledHeaders={disabledHeaders}
                      disabledQueryParams={disabledQueryParams}
                      connector={
                        publishedConnectors[selectedConnector?.value] ??
                        connector
                      }
                      disabled={isRuleReadOnly}
                      setValue={setLocalValue}
                    />
                  )}

                  {showActionForm && (
                    <ActionForm
                      sectionName={section}
                      pluginName={type}
                      index={index}
                      control={localControl}
                      mainControl={control}
                      watch={localWatch}
                      formFields={formFields}
                      id={selectedConnectorId}
                      parentFormData={parentFormData}
                      formJson={formJson}
                      setValue={setLocalValue}
                      formKeyPrefix={formKeyPrefix}
                      newCustomSuggestions={formatCustomAttributes(
                        customSuggestionObj
                      )}
                      execValues={executedValue}
                      handleGetExecutionValues={handleGetExecutionValues}
                      dataSet={updatedDatasetDt}
                    />
                  )}
                </Stack>
                <Stack>
                  {!isQueryValidNected && !_isEmpty(query) && (
                    <SyntaxErrorContainer padding="1rem">
                      <Typography>
                        The statement written here is syntactically incorrect
                      </Typography>
                    </SyntaxErrorContainer>
                  )}
                </Stack>
              </ActionBodyContainer>

              <ActionFooter padding={[4, 8]}>
                <Inline justify="end">
                  <Button
                    appearance="contained"
                    type="submit"
                    disabled={isRuleReadOnly}
                  >
                    Save
                  </Button>
                </Inline>
              </ActionFooter>
            </TabPanel>
            <TabPanel>
              {showRestAPI && (
                <>
                  <ActionBodyContainer padding="1rem">
                    <ResponseCaching
                      control={localControl}
                      name="action.config.settings.cache"
                      setValue={setLocalValue}
                      dataset={updatedDataset}
                      responseParams={ruleObject}
                      disabled={isRuleReadOnly}
                    />
                  </ActionBodyContainer>

                  <ActionFooter padding={[4, 8]}>
                    <Inline justify="end">
                      <Button
                        appearance="contained"
                        type="submit"
                        disabled={isRuleReadOnly}
                      >
                        Save
                      </Button>
                    </Inline>
                  </ActionFooter>
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ActionBody>
    </Sheet>
  );
}
