import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAxiosPrivate } from '../../../../hooks';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../../utils/common';
import { CustomAxiosError } from '../../../../utils/response/types';
import { KeyValuePairList } from '../../../Integrations/types';
import { versionInfoWorkflowAtom, workflowStatusAtom } from '../../atoms/atoms';
import { useGetWorkflowStatusAfterTest } from '../graphql/useGetWorkflowStatusAtferTest';

export type TestWorkflowPayload = {
  id: string;
  nodeId?: string;
  headers?: KeyValuePairList[];
  type?: string;
  params?: Record<string, any>;
  additionalParams?: Record<string, any>;
  version?: string;
};

export function useTestWorkflow(isOverallTest = false) {
  const { axiosPrivate } = useAxiosPrivate();
  const [, setWorkflowStatus] = useAtom(workflowStatusAtom);
  const [versionInfoWorkflow] = useAtom(versionInfoWorkflowAtom);

  const [searchParams] = useSearchParams();

  const isPreview = (searchParams.get('type') ?? '') === 'view';

  const [getWfStatus, { data: wfStatusData, loading }] =
    useGetWorkflowStatusAfterTest();

  useEffect(() => {
    const wfdata = wfStatusData?.getWorkflow?.data[0];

    if (!_isNil(wfdata) && !loading) {
      setWorkflowStatus(wfdata.status);
    }
  }, [wfStatusData, loading]);

  const {
    mutate: testWorkflow,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: TestWorkflowPayload) => {
      const headers = (request.headers ?? []).reduce((acc, item) => {
        return {
          ...acc,
          [item.key]: item.value,
        };
      }, {});

      let requestBody: Record<string, any> = {
        id: request.id,
        checksum: handleGetCheckSumByEntityName('workflow') ?? '',
      };

      if (isPreview) {
        requestBody = {
          ...requestBody,
          version: versionInfoWorkflow?.currentVersion,
        };
      }

      if ((request.headers ?? []).length > 0) {
        requestBody = { ...requestBody, headers };
      }

      if (!_isNil(request.type)) {
        requestBody = { ...requestBody, type: request.type };
      }

      if (!_isNil(request.nodeId)) {
        requestBody = { ...requestBody, nodeId: request.nodeId };
      }

      if (!_isNil(request.params)) {
        requestBody = { ...requestBody, params: request.params };
      }

      if (!_isNil(request.additionalParams)) {
        requestBody = {
          ...requestBody,
          additionalParams: request.additionalParams,
        };
      }

      return await axiosPrivate.post('/workflow/test', requestBody);
    },
    onSuccess(response: AxiosResponse, requestPayload: TestWorkflowPayload) {
      if (!_isNil(response?.data.checksum)) {
        handleSetCheckSumByEntityName('workflow', response.data.checksum);
      }

      if (!isPreview && !isOverallTest) {
        void getWfStatus({
          variables: {
            id: requestPayload.id,
          },
        });
      }

      return response;
    },
    onError(
      error: AxiosError<CustomAxiosError>,
      requestPayload: TestWorkflowPayload
    ) {
      const { response } = error;

      if (error instanceof Error) {
        if (!_isNil(response) && !_isNil(response.data)) {
          const data = response.data as any;

          if (!_isNil(data.checksum)) {
            handleSetCheckSumByEntityName('workflow', data.checksum);
          }
        }
      }

      if (!isPreview) {
        void getWfStatus({
          variables: {
            id: requestPayload.id,
          },
        });
      }

      return error;
    },
  });

  return {
    testWorkflow,
    isLoading,
    data,
    error,
  };
}
