import { PadBox } from '@bedrock-layout/padbox';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer } from 'ui';

import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { OutputContainer } from '../../ApiTriggerSheet/ApiTestResult.styled';
import { JsonContainer, JsonTreeContainer } from './ResponseNodeTest.styled';

type ResponseNodeTestProps = {
  output?: Record<string, any>;
};

export function ResponseNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
}: ResponseNodeTestProps) {
  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <ExecutionView>
          <JsonContainer>
            <AceEditor
              className="json-result-readonly"
              mode="json"
              theme="chrome"
              width="100%"
              fontSize={12}
              showPrintMargin={false}
              highlightActiveLine={true}
              showGutter={true}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              readOnly
              value={JSON.stringify(output, null, 2)}
            />
          </JsonContainer>

          <JsonTreeContainer>
            <JsonHeroTreeViewer json={output} />
          </JsonTreeContainer>
        </ExecutionView>
      </OutputContainer>
    </PadBox>
  );
}
