import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const ActionPill = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 6rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background: var(--color-solitudeSecondary);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError
        ? 'var(--color-fireEngineRed)'
        : 'var(--color-solitudeSecondary)'};
`;

export const EditorContainer = styled.div<{ databaseName: string }>`
  border-radius: 6px;
  max-height: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 5px;
  min-height: 50rem;
  padding: 1rem;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -1rem;
`;
