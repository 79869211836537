import { z } from 'zod';

import { translations } from '../../translations/en-US';
import { KEY_REGEX } from '../../utils/regex';

export const moduleSchema = z.object({
  name: z
    .string()
    .min(1, 'Key name cannot be empty')
    .max(50, translations['validation.max_characters'])
    .regex(KEY_REGEX, 'name can contain alphabets, numbers, and underscore'),
  dataType: z.object(
    {
      label: z.string(),
      value: z.string(),
    },
    {
      invalid_type_error: 'Please select a value',
    }
  ),
  options: z.optional(z.array(z.object({ value: z.any() }))),
  usageType: z.enum(['mandatory', 'optional']),
  value: z.optional(z.array(z.any())),
  id: z.optional(z.string()),
  checksum: z.optional(z.string()),
});
