import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  CheckboxField,
  Dataset,
  TextField,
  TooltipReact,
  Typography,
} from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../../../../atom';
import { TabSelectionApiHook } from '../../../../../components/TabSelectionApiHook/TabSelectionApiHook';
import { roleJsonAtom } from '../../../../../components/authentication/router/AuthProvider';
import { useGetPermissionsByEntity } from '../../../../../hooks/restApi/useGetPermissionsByEntity';
import {
  convertCaSampleValues,
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import { AuthenticationDropDown } from '../../../../Rules/components/Triggers/AuthenticationDropDown';
import { AuthKeyContainer } from '../../../../Rules/components/Triggers/TriggerSheet/TriggerSheet.styled';
import { currentWorkspaceDetailAtom } from '../../../../Workspace/atom';
import {
  isWorkflowReadOnlyAtom,
  versionInfoWorkflowAtom,
  workflowEnvironmentAtom,
  workflowStaticUrlAtom,
  workflowStatusAtom,
} from '../../../atoms/atoms';
import { ApiSettingTab } from './ApiSettingTab';
import { ApiWebhookContainer } from './ApiSettings.styled';
import { WebhookSettings } from './WebhookSettings';

type ApiSettingsProps = {
  control?: Control<any>;
  watch: UseFormWatch<Record<string, any>>;
  setValue: UseFormSetValue<any>;
  dataset?: Record<string, Dataset>;
};

export function ApiSettings({
  control,
  watch,
  setValue,
  dataset = {},
}: ApiSettingsProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [, setLimitConfig] = useState<Record<string, any>>({});
  const [triggerUrl, setTriggerUrl] = useState('');
  const [triggerUrlHook, setTriggerUrlHook] = useState('');
  const [bodyParams, setBodyParams] = useState('');
  const [bodyParamsWebhook, setBodyParamsWebhook] = useState('');

  const [roleJson, setRoleJson] = useAtom(roleJsonAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [userProfile] = useAtom(userProfileAtom);
  const [staticUrl] = useAtom(workflowStaticUrlAtom);
  const [workflowEnvironment] = useAtom(workflowEnvironmentAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const [versionInfoWorkflow] = useAtom(versionInfoWorkflowAtom);

  const currentLiveVersion = versionInfoWorkflow?.currentLiveVersion;

  let credentialKey = '';

  if (!_isNil(currentWorkspace)) {
    credentialKey = currentWorkspace.apiKey;
  }

  const { getUserPermissionsByEntity } = useGetPermissionsByEntity();

  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  useEffect(() => {
    setLimitConfig(roleJson?.internals?.credentials?.limits ?? {});
  }, [roleJson]);

  const handleGetPermissionByEntity = async () => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    const user = window.btoa(email ?? '');

    await getUserPermissionsByEntity(
      user,
      role,
      wid,
      'credentials',
      setRoleJson
    );
  };

  useEffect(() => {
    if (!_isEmpty(email)) {
      void handleGetPermissionByEntity();
    }
  }, []);

  const isPrivate = watch('settings.authType')?.value === 'private';

  // @ts-expect-error
  const bodyExample: any = watch('attributes')?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: convertCaSampleValues(
        curr.selectedType?.value ?? 'string',
        curr.executedValue ?? null
      ),
    };
  }, {});

  useEffect(() => {
    const params: Record<string, any> = {
      environment: workflowStatus === 'published' ? 'production' : 'staging',
      params: bodyExample,
    };

    if (workflowStatus === 'published') {
      params.version = currentLiveVersion;
    }

    setBodyParams(JSON.stringify(params, null, 2));

    setBodyParamsWebhook(JSON.stringify(bodyExample, null, 2));
  }, [workflowEnvironment, bodyExample, workflowStatus]);

  useEffect(() => {
    if (!_isEmpty(credentialKey) && isPrivate) {
      setTriggerUrl(
        `curl -X "POST" "${staticUrl}"\\
        -H 'Content-Type: application/json'\\
        -H 'nected-api-key: ${'XXXX-XXXX-XXXX-XXXX'}'\\
        -d '${bodyParams}'`
      );
    } else {
      setTriggerUrl(
        `curl -X "POST" "${staticUrl}"\\
          -H 'Content-Type: application/json'\\
          -d '${bodyParams}'`
      );
    }
  }, [bodyParams, isPrivate]);

  useEffect(() => {
    if (!_isEmpty(credentialKey) && isPrivate) {
      setTriggerUrlHook(
        `curl -X "POST" "${handleChangeApiToWebhookUrl(
          staticUrl,
          workflowStatus === 'published' ? 'production' : 'staging',
          currentLiveVersion
        )}"\\
        -H 'Content-Type: application/json'\\
        -H 'nected-api-key: ${`XXXX-XXXX-XXXX-XXXX`}'\\
        -d '${bodyParamsWebhook}'`
      );
    } else {
      setTriggerUrlHook(
        `curl -X "POST" "${handleChangeApiToWebhookUrl(
          staticUrl,
          workflowStatus === 'published' ? 'production' : 'staging',
          currentLiveVersion
        )}"\\
        -H 'Content-Type: application/json'\\
        -d $'${bodyParamsWebhook}'`
      );
    }
  }, [bodyParamsWebhook, isPrivate, currentLiveVersion, workflowStatus]);

  return (
    <Inline minItemWidth="30rem">
      <Stack gutter="2rem">
        <Stack gutter="0.5rem">
          <Inline gutter={8} align="center">
            <Typography fontWeight={700}>Authentication</Typography>
            <TooltipReact id="auth-rule-id">
              {getTooltipText(siteConstants, 'rules', 'triggerAuthentication')}
            </TooltipReact>
          </Inline>

          <AuthenticationDropDown
            control={control}
            name="settings.authType"
            isDisabled={isWorkflowReadOnly}
            MenuListFooter={undefined}
          />
        </Stack>

        {isPrivate && (
          <Stack>
            {!_isNil(currentWorkspace) && !_isEmpty(currentWorkspace) && (
              <Inline align="center">
                <AuthKeyContainer align="top">
                  <TextField
                    size="medium"
                    name="nected-api-key"
                    defaultValue="nected-api-key"
                    showErrorIcon={false}
                    control={control}
                    disabled
                  />
                  <TextField
                    size="medium"
                    name="XXXX-XXXX-XXXX-XXXX"
                    defaultValue="XXXX-XXXX-XXXX-XXXX"
                    showErrorIcon={false}
                    control={control}
                    disabled
                  />
                </AuthKeyContainer>
              </Inline>
            )}
          </Stack>
        )}

        <Inline align="center">
          <Inline align="center" gutter={8}>
            <Typography fontWeight={700}>Status</Typography>

            <TooltipReact id="status-trigger" placement="bottom-start">
              <Typography>
                This indicates whether the workflow is active or not when called
                via API
              </Typography>
            </TooltipReact>
          </Inline>

          <CheckboxField
            useId="settings.isEnabled"
            name="settings.isEnabled"
            appearance="switch"
            control={control}
            disabled={isWorkflowReadOnly}
          />
        </Inline>
        <ApiWebhookContainer gutter="1rem">
          <TabSelectionApiHook>
            <ApiSettingTab
              bodyParamsApi={bodyParams}
              staticUrl={staticUrl}
              triggerUrl={triggerUrl}
              control={control}
              setValue={setValue}
              dataset={dataset}
            />

            <WebhookSettings
              bodyParamsHook={bodyParamsWebhook}
              staticUrl={staticUrl}
              triggerUrl={triggerUrlHook}
            />
          </TabSelectionApiHook>
        </ApiWebhookContainer>
      </Stack>
    </Inline>
  );
}
