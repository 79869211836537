import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import {
  Image,
  PopoverMethods,
  PopoverPanel,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
} from 'ui';

import {
  getEntityTypeForUI,
  getMethodNameForApi,
  isValidImageURL,
  showGraphQlErrorToast,
} from '../../utils/common';
import { PAGE_SIZE } from '../../utils/constant';
import { getValueFromObject } from '../Listing/utils';
import { getEntityTypeForApi } from '../Modals/utils/common';
import type {
  EntityInfoType,
  PaginationType,
} from '../VersionControl/VersionControl';
import {
  EntCategoryTextStyled,
  EntNameTextStyled,
  EntityUsageContainer,
  FilterContainer,
  FilterContentStyled,
  FilterVersionItem,
  LauncherContainer,
  ListContainer,
  StyledImg,
} from './EntityDependencyUsage.styled';
import {
  EntityUsedByCard,
  EntityUsedByCardData,
  EntityUsingCard,
  EntityUsingCardData,
} from './components';
import { useGetDependencyUsedByData } from './hooks/graphql/useGetDependencyUsingByData';
import { useGetDependencyUsingData } from './hooks/graphql/useGetDependencyUsingData';
import { useFetEntityVersionData } from './hooks/graphql/useGetEntityVersionData';

/* eslint-disable-next-line import/no-absolute-path */
import entityUsedByEmptyListImg from '/assets/konark/images/entityUsedByEmptyList.svg';

/* eslint-disable-next-line import/no-absolute-path */
import entityUsingAssetsEmptyListImg from '/assets/konark/images/entityUsingAssetsEmptyList.svg';

type EntityDependencyUsageProps = {
  entityInfo: EntityInfoType;
  currentTab?: number;
  updateTabIndex?: (index: number) => void;
  openInSheet?: boolean;
};

export function EntityDependencyUsage({
  entityInfo,
  updateTabIndex,
  openInSheet = false,
}: EntityDependencyUsageProps) {
  const filterRef = useRef<PopoverMethods>(null);
  const { id, type, name, subType, iconUrl, version, status } = entityInfo;

  const [isLoading, setIsLoading] = useState(false);
  const [appendData, setAppendData] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [versionList, setVersionList] = useState<Array<Record<string, any>>>(
    []
  );
  const [selectedVersion, setSelectedVersion] = useState(version);

  const [
    getDependencyUsingListQuery,
    { data: dependencyUsingData, error: dependencyUsingError },
  ] = useGetDependencyUsingData();

  const [
    getDependencyUsedByQuery,
    { data: dependencyUsedByData, error: dependencyUsedByErr },
  ] = useGetDependencyUsedByData();

  const [getVersionListQuery, { data: versionData, error: versionDataErr }] =
    useFetEntityVersionData(type);

  const [entityUsingListData, setEntityUsingListData] = useState<
    EntityUsingCardData[]
  >([]);

  const [entityUsedByListData, setEntityUsedByListData] = useState<
    EntityUsedByCardData[]
  >([]);

  const methodName = useMemo(() => getMethodNameForApi(type), [type]);

  const loadMoreData =
    !_isNil(paginationInfo) &&
    paginationInfo.currentPage < paginationInfo.totalPage;

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id)) {
      void handleGetInitialData();
    }
  }, [id, currentTab, selectedVersion]);

  useEffect(() => {
    if (!_isNil(version)) {
      setSelectedVersion(version);
    }
  }, [version]);

  useEffect(() => {
    if (
      !_isNil(id) &&
      currentTab === 1 &&
      ['rules', 'workflow', 'datasets'].includes(type)
    ) {
      void handleGetVersionList();
    }
  }, [id, currentTab]);

  useEffect(() => {
    if (
      !_isNil(dependencyUsingData) &&
      !_isNil(dependencyUsingData.getDependencies)
    ) {
      setEntityUsingListData([
        ...(appendData ? entityUsingListData : []),
        ...(dependencyUsingData.getDependencies.data ?? []),
      ]);

      setPaginationInfo(dependencyUsingData.getDependencies.paginationInfo);
    }
  }, [JSON.stringify(dependencyUsingData)]);

  useEffect(() => {
    if (
      !_isNil(dependencyUsedByData) &&
      !_isNil(dependencyUsedByData.getUsedBy)
    ) {
      setEntityUsedByListData([
        ...(appendData ? entityUsedByListData : []),
        ...(dependencyUsedByData.getUsedBy.data ?? []),
      ]);

      setPaginationInfo(dependencyUsedByData.getUsedBy.paginationInfo);
    }
  }, [JSON.stringify(dependencyUsedByData)]);

  useEffect(() => {
    if (
      !_isNil(dependencyUsingError) &&
      dependencyUsingError instanceof ApolloError
    ) {
      showGraphQlErrorToast(dependencyUsingError);
      setEntityUsingListData([]);
    }
  }, [dependencyUsingError]);

  useEffect(() => {
    if (
      !_isNil(dependencyUsedByErr) &&
      dependencyUsedByErr instanceof ApolloError
    ) {
      showGraphQlErrorToast(dependencyUsedByErr);
      setEntityUsingListData([]);
    }
  }, [dependencyUsedByErr]);

  useEffect(() => {
    if (
      !_isNil(versionData) &&
      !_isNil(methodName) &&
      !_isNil(versionData[methodName])
    ) {
      setVersionList(versionData[methodName].data ?? []);
    }
  }, [versionData]);

  useEffect(() => {
    if (!_isNil(versionDataErr) && versionDataErr instanceof ApolloError) {
      showGraphQlErrorToast(versionDataErr);
      setVersionList([]);
    }
  }, [versionDataErr]);

  const onTabChange = (index: number) => {
    setCurrentTab(index);
    setSelectedVersion(version);

    if (typeof updateTabIndex === 'function') {
      updateTabIndex(index);
    }
  };

  const handleGetInitialData = async () => {
    setAppendData(false);
    setIsLoading(true);

    if (currentTab === 0 && !openInSheet) {
      const payload = {
        entityId: id,
        entityType: getEntityTypeForApi(type),
        version: status === 'archived' ? status : selectedVersion,
        page: 1,
        perPage: PAGE_SIZE,
      };

      await getDependencyUsingListQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });

      setIsLoading(false);
    } else if (currentTab === 1 || openInSheet) {
      const payload: Record<string, any> = {
        entityId: id,
        page: 1,
        perPage: PAGE_SIZE,
      };

      if (selectedVersion !== 'all') {
        payload.version = status === 'archived' ? status : selectedVersion;
      }

      await getDependencyUsedByQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });

      setIsLoading(false);
    }
  };

  const handleLoadMoreData = async () => {
    if (!loadMoreData || _isNil(id) || _isEmpty(id)) {
      return;
    }

    setAppendData(true);
    setIsLoadingMore(true);

    if (currentTab === 0 && !openInSheet) {
      const payload = {
        entityId: id,
        entityType: getEntityTypeForApi(type),
        version: status === 'archived' ? status : selectedVersion,
        page: (paginationInfo?.currentPage ?? 0) + 1,
        perPage: PAGE_SIZE,
      };

      await getDependencyUsingListQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
      setIsLoadingMore(false);
    } else if (currentTab === 1 || openInSheet) {
      const payload: Record<string, any> = {
        entityId: id,
        page: (paginationInfo?.currentPage ?? 0) + 1,
        perPage: PAGE_SIZE,
      };

      if (selectedVersion !== 'all') {
        payload.version = status === 'archived' ? status : selectedVersion;
      }

      await getDependencyUsedByQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
      setIsLoadingMore(false);
    }
  };

  const handleGetVersionList = async () => {
    const payload = {
      id,
      entityType: getEntityTypeForApi(type),
      page: 1,
      perPage: 50,
      live: true,
      filters: {
        in: {
          status: ['published'],
        },
      },
      sort: {
        updatedAt: -1,
      },
    };

    await getVersionListQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });
  };

  const observerRef = useRef<HTMLDivElement | null>(null);
  const intersectionObserver = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observerRef.current != null && intersectionObserver.current != null) {
      intersectionObserver.current.unobserve(observerRef.current);
    }

    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting && loadMoreData && !isLoadingMore) {
          void handleLoadMoreData();
        }
      },
      {
        root: null,
        rootMargin: '300px',
        threshold: 0,
      }
    );

    if (observerRef.current != null) {
      intersectionObserver.current.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current != null && intersectionObserver.current != null) {
        intersectionObserver.current.unobserve(observerRef.current);
      }
    };
  }, [
    JSON.stringify(paginationInfo),
    currentTab,
    id,
    loadMoreData,
    type,
    status,
    version,
    openInSheet,
    isLoadingMore,
  ]);

  useEffect(() => {
    if (observerRef.current != null && intersectionObserver.current != null) {
      intersectionObserver.current.observe(observerRef.current);
    }
  }, [observerRef.current, intersectionObserver.current]);

  const FilterLauncher = () => {
    return (
      <LauncherContainer>
        <Typography name="paragraphSmall">
          {!_isNil(selectedVersion) ? selectedVersion : 'All'}
        </Typography>
        <IoMdArrowDropdown />
      </LauncherContainer>
    );
  };

  const entityType = useMemo(() => getEntityTypeForUI(type), [type]);
  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  return (
    <>
      {openInSheet ? (
        <Sheet
          size="xs"
          metaInfo={{
            id: getValueFromObject(plan, 'plan.dependencyMap.componentId'),
            trigger: getValueFromObject(plan, 'plan.dependencyMap.trigger'),
          }}
        >
          <EntityUsageContainer $openInSheet={openInSheet} gutter={0}>
            <PadBox padding="1.6rem">
              <Typography name="heading3" id="version-contol-heading">
                Dependency Map
              </Typography>
            </PadBox>
            <PadBox padding="1.6rem" as={Stack} gutter="1rem">
              <Typography>{`See where this ${entityType} is used.`}</Typography>
              <Inline align="center" justify="start">
                {!_isNil(iconUrl) && !_isEmpty(iconUrl) && (
                  <Image
                    src={isValidImageURL(iconUrl)}
                    alt={subType ?? name ?? ''}
                  />
                )}
                <EntNameTextStyled name="heading3">{name}</EntNameTextStyled>
                <EntCategoryTextStyled name="secondarySmall">
                  {subType}
                </EntCategoryTextStyled>
              </Inline>

              {entityUsedByListData.length > 0 && !isLoading && (
                <ListContainer>
                  {entityUsedByListData.map((data, index) => (
                    <EntityUsedByCard
                      key={index}
                      data={data}
                      selectedVersion={selectedVersion}
                    />
                  ))}
                  {isLoadingMore && (
                    <Inline justify="center">
                      <Spinner size="extraSmall" />
                    </Inline>
                  )}
                  <div ref={observerRef} />
                </ListContainer>
              )}

              {entityUsedByListData.length === 0 && !isLoading && (
                <Inline justify="center" align="center">
                  <StyledImg
                    src={entityUsedByEmptyListImg}
                    alt="no-list-data"
                  />
                </Inline>
              )}

              {isLoading && (
                <Inline justify="center">
                  <Spinner />
                </Inline>
              )}
            </PadBox>
          </EntityUsageContainer>
        </Sheet>
      ) : (
        <EntityUsageContainer>
          <PadBox padding="1rem">
            <Typography name="heading3" id="version-contol-heading">
              Dependency Map
            </Typography>
          </PadBox>
          <Tabs defaultOpen={currentTab} onTabChange={onTabChange}>
            <TabList>
              <Tab key="using" size="extraSmall">
                Using
              </Tab>
              <Tab key="usedBy" size="extraSmall">
                Used by
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <PadBox padding="1rem" as={Stack} gutter="1rem">
                  <FilterContainer>
                    <Typography>View assets linked to this rule.</Typography>
                    {/* Please Do not remove this code */}

                    {/* <Inline gutter="1rem" align="end">
                      <Typography>Filter</Typography>
                      <PopoverPanel
                        ref={filterRef}
                        trigger="click"
                        launcher={
                          <div>
                            <FilterLauncher />
                          </div>
                        }
                        padding={0}
                      >
                        <FilterContentStyled gutter={0}>
                          <FilterVersionItem
                            onClick={() => {
                              setSelectedVersion('draft');
                              filterRef?.current?.hide();
                            }}
                          >
                            draft
                          </FilterVersionItem>
                          {versionList.length > 0 &&
                            versionList.map((currVersionInfo, index) => (
                              <FilterVersionItem
                                key={index}
                                onClick={() => {
                                  setSelectedVersion(currVersionInfo.version);
                                  filterRef?.current?.hide();
                                }}
                              >
                                {currVersionInfo.version}
                              </FilterVersionItem>
                            ))}
                        </FilterContentStyled>
                      </PopoverPanel>
                    </Inline> */}
                  </FilterContainer>

                  {entityUsingListData.length > 0 && !isLoading && (
                    <ListContainer>
                      {entityUsingListData.map((data, index) => (
                        <EntityUsingCard key={index} data={data} />
                      ))}

                      {isLoadingMore && (
                        <Inline justify="center">
                          <Spinner size="extraSmall" />
                        </Inline>
                      )}
                      <div ref={observerRef} />
                    </ListContainer>
                  )}

                  {entityUsingListData.length === 0 && !isLoading && (
                    <Inline align="center" justify="center">
                      <StyledImg
                        src={entityUsingAssetsEmptyListImg}
                        alt="no-list-data"
                      />
                    </Inline>
                  )}

                  {isLoading && (
                    <Inline justify="center">
                      <Spinner />
                    </Inline>
                  )}
                </PadBox>
              </TabPanel>

              <TabPanel>
                <PadBox padding="1rem" as={Stack} gutter="1rem">
                  <FilterContainer>
                    <Typography>{`See where this ${entityType} is used.`}</Typography>

                    {status !== 'archived' && (
                      <Inline gutter="1rem" align="end">
                        <Typography>Filter</Typography>
                        <PopoverPanel
                          ref={filterRef}
                          trigger="click"
                          launcher={
                            <div>
                              <FilterLauncher />
                            </div>
                          }
                          padding={0}
                        >
                          <FilterContentStyled gutter={0}>
                            <FilterVersionItem
                              onClick={() => {
                                setSelectedVersion('draft');
                                filterRef?.current?.hide();
                              }}
                            >
                              Draft
                            </FilterVersionItem>
                            <FilterVersionItem
                              onClick={() => {
                                setSelectedVersion('all');
                                filterRef?.current?.hide();
                              }}
                            >
                              All
                            </FilterVersionItem>
                            {versionList.length > 0 &&
                              versionList.map((currVersionInfo, index) => (
                                <FilterVersionItem
                                  key={index}
                                  onClick={() => {
                                    setSelectedVersion(currVersionInfo.version);
                                    filterRef?.current?.hide();
                                  }}
                                >
                                  {currVersionInfo.version}
                                </FilterVersionItem>
                              ))}
                          </FilterContentStyled>
                        </PopoverPanel>
                      </Inline>
                    )}
                  </FilterContainer>
                  {entityUsedByListData.length > 0 && !isLoading && (
                    <ListContainer>
                      {entityUsedByListData.map((data, index) => (
                        <EntityUsedByCard key={index} data={data} />
                      ))}

                      {isLoadingMore && (
                        <Inline justify="center">
                          <Spinner size="extraSmall" />
                        </Inline>
                      )}

                      <div ref={observerRef} />
                    </ListContainer>
                  )}

                  {entityUsedByListData.length === 0 && !isLoading && (
                    <Inline align="center" justify="center">
                      <StyledImg
                        src={entityUsedByEmptyListImg}
                        alt="no-list-data"
                      />
                    </Inline>
                  )}

                  {isLoading && (
                    <Inline justify="center">
                      <Spinner />
                    </Inline>
                  )}
                </PadBox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </EntityUsageContainer>
      )}
    </>
  );
}
