import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import AceEditor from 'react-ace';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Dataset, TextField, TooltipReact, Typography } from 'ui';

import { createRuleSheetAtom, simpleRuleNodeIdAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { ResponseCaching } from '../../../../../components/ResponseCaching/ResponseCaching';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { getTooltipText } from '../../../../../utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../Workflow/atoms/atoms';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodeIdAtom } from '../../DecisionTable/DecisionTable';
import { ruleSetNodeId } from '../../RuleSet/RuleSet';
import { TriggerApi } from '../Trigger API/TriggerApi';
import { JsonContainerStyled } from './TriggerSheet.styled';

type ApiTriggerFormProps = {
  bodyExample: string;
  control?: Control<any>;
  nameByType: string;
  credentialKey: string;
  triggerUrl: string;
  value: any;
  setValue?: UseFormSetValue<any>;
};

export function ApiTriggerForm({
  bodyExample,
  credentialKey,
  nameByType,
  control,
  triggerUrl,
  value,
  setValue,
}: ApiTriggerFormProps) {
  const [siteConstant] = useAtom(siteConstantsAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [dtId] = useAtom(decisionTableNodeIdAtom);
  const [srId] = useAtom(simpleRuleNodeIdAtom);
  const [rsId] = useAtom(ruleSetNodeId);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const [ruleType] = useAtom(createRuleSheetAtom);

  const ruleObject = useMemo(() => {
    if (ruleType === 'decisionTable') {
      return {
        type: 'rule',
        entityId: dtId,
      };
    } else if (ruleType === 'simpleRule') {
      return {
        type: 'rule',
        entityId: srId,
      };
    } else {
      return {
        type: 'rule',
        entityId: rsId,
      };
    }
  }, [srId, rsId, dtId, ruleType]);

  const [customAttributes] = useAtom(customAttributesAtom);

  const getDataset: Record<string, Dataset> = useMemo(() => {
    if (ruleType === 'ruleSet') {
      return {
        customInput: {
          name: 'Custom Input',
          id: 'custom-input',
          attributes: Object.keys(customAttributes).reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: {
                id: customAttributes[curr].name,
                name: customAttributes[curr].name,
                dataType: customAttributes[curr].dataType?.value,
                executedValue: customAttributes[curr].executedValue,
              },
            };
          }, {}),
        },
      };
    }

    const { dataSet, ...rest } = dataset;

    return rest;
  }, [JSON.stringify(dataset), JSON.stringify(customAttributes), ruleType]);

  return (
    <>
      <Stack gutter="0.5rem">
        <ResponseCaching
          name={`${nameByType}.cache`}
          control={control}
          setValue={setValue}
          dataset={getDataset}
          responseParams={ruleObject}
          disabled={isWorkflowReadOnly}
        />

        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Base URL</Typography>
          <TooltipReact id="baseurl-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBaseURL')}
          </TooltipReact>
        </Inline>

        <TextField
          control={control}
          name={`${nameByType}.staticUrl`}
          disabled
        />
      </Stack>

      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Body Params</Typography>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstant, 'rules', 'triggerBodyParams')}
          </TooltipReact>
        </Inline>

        <Typography>
          <span>
            There is no need to provide an environment and version; the default
            environment for testing is staging and for version it would be
            draft.
          </span>
        </Typography>

        <JsonContainerStyled>
          {bodyExample.length > 0 && (
            <AceEditor
              className="json-result-readonly"
              mode="json"
              theme="chrome"
              width="100%"
              fontSize={12}
              showPrintMargin={false}
              highlightActiveLine={true}
              showGutter={true}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              readOnly
              value={bodyExample}
            />
          )}
        </JsonContainerStyled>
      </Stack>

      <TriggerApi
        authType={_isNil(value) ? '' : value}
        credentialKey={credentialKey}
        triggerUrl={triggerUrl}
      />
    </>
  );
}
