import type { ObservableQuery } from '@apollo/client';
import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import {
  Button,
  ExpandingTextField,
  Sheet,
  TextField,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { HowToLink } from '../../../../components/HowToLink/HowToLink';
import { StackAsItem } from '../../../../components/layouts/Stack.styled';
import type { SavedAuthConfigKeys } from '../../../../types';
import {
  getTooltipText,
  handleGetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import { useSaveAuthConfigKey } from '../hooks/useSaveAuthConfigKeys';
import { useUpdateAuthConfigKey } from '../hooks/useUpdateAuthConfigKey';
import { BEARER_TOKEN_SECHEMA } from '../schema/schema';
import {
  AddAuthenticationConfigSheetForm,
  PillStyled,
  SaveButtonContainer,
} from './AuthenticationConfig.styled';

type BearerTokenSheetProps = {
  authKey?: SavedAuthConfigKeys;
  checksum?: string;
  refetch?: ObservableQuery<any>['refetch'];
};

export function BearerTokenSheet({ authKey, refetch }: BearerTokenSheetProps) {
  const { close } = useCurrentLayer();

  const [siteConstants] = useAtom(siteConstantsAtom);

  const { control, handleSubmit, getValues } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      name: authKey != null ? authKey.name : 'Untitled',
    },
    resolver: zodResolver(BEARER_TOKEN_SECHEMA),
  });

  const [saveAuthConfigKey] = useSaveAuthConfigKey();
  const [updateAuthConfigKey] = useUpdateAuthConfigKey();

  const saveAuthKey = async (data: any) => {
    const { name } = data;

    if (!_isNil(authKey) && !_isNil(authKey.id)) {
      try {
        const { token } = getValues();

        const checksum = handleGetCheckSumByEntityName('credentials');

        await updateAuthConfigKey({
          variables: {
            id: authKey?.id,
            name,
            checksum: checksum ?? '',
            value: {
              key: 'Authorization',
              value: token,
            },
          },
        });

        toasts.success('Auth Key Updated', 'success');
        close();
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const { token } = getValues();
        await saveAuthConfigKey({
          variables: {
            name,
            type: 'API',
            authType: 'BEARER_TOKEN',
            value: {
              key: 'Authorization',
              value: token,
            },
          },
        });

        toasts.success('Key saved', 'success');
        close();
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }
    };
  }, []);

  const [togglePassword, setTogglePassword] = useState<boolean>(true);

  return (
    <Sheet size="small">
      <Stack
        as={AddAuthenticationConfigSheetForm}
        onSubmit={handleSubmit(saveAuthKey)}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <StackAsItem as={PadBox} padding="1rem" grow={1}>
          <Stack gutter="2rem">
            <Inline stretch="start" align="center">
              <Inline align="center" gutter="0.8rem">
                <Typography name="heading2">
                  <ExpandingTextField control={control} name="name" />
                </Typography>

                <PillStyled padding={['0.2rem', '1rem']}>
                  <Typography name="paragraphSmall">Bearer Token</Typography>
                </PillStyled>
              </Inline>

              <HowToLink
                link={getTooltipText(
                  siteConstants,
                  'credentials',
                  'bearerTokenHowTo',
                  'howToLinks'
                )}
              />
            </Inline>

            <Stack gutter="2rem">
              <Stack gutter="1rem">
                <Inline align="center">
                  <Typography fontWeight={700}>Header</Typography>

                  <TooltipReact id="bearer-header-name">
                    <Typography>
                      {getTooltipText(siteConstants, 'credentials', 'header')}
                    </Typography>
                  </TooltipReact>
                </Inline>

                <TextField
                  readOnly={true}
                  name="header"
                  value="Authorization"
                  control={control}
                  disabled
                />
              </Stack>

              <Stack gutter="1rem">
                <Inline align="center">
                  <Typography fontWeight={700}>Token</Typography>

                  <TooltipReact id="bearer-token" placement="bottom-start">
                    <Typography>
                      {getTooltipText(siteConstants, 'credentials', 'token')}
                    </Typography>
                  </TooltipReact>
                </Inline>

                <TextField
                  onClickInputIcon={() =>
                    setTogglePassword((isShow) => !isShow)
                  }
                  type={togglePassword ? 'password' : 'text'}
                  name="token"
                  defaultValue={!_isNil(authKey) ? authKey.value.value : ''}
                  icon={
                    togglePassword ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )
                  }
                  rules={{
                    required: 'Field is required',
                  }}
                  control={control}
                />
              </Stack>
            </Stack>
          </Stack>
        </StackAsItem>

        <SaveButtonContainer padding="1rem">
          <Inline justify="end">
            <Button type="submit">Submit</Button>
          </Inline>
        </SaveButtonContainer>
      </Stack>
    </Sheet>
  );
}
