import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

export const DataParamContainer = styled(Inline)<{ topPadding?: string }>`
  min-block-size: 3.7rem;

  ${({ topPadding = '0' }) =>
    !_isEmpty(topPadding) && `padding-top: ${topPadding};`}
`;

export const ModuleSchemaFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-solitude);
  border-radius: 4px;
  padding: 0.8rem;
  inline-size: 13rem;
`;
