/* eslint-disable @typescript-eslint/restrict-plus-operands */
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { Position } from 'reactflow';
import {
  Attributes,
  DataTypes,
  Dataset,
  NectedSuggestionModel,
  getDataTypeNected,
  getObjectUnion,
} from 'ui';

import {
  CUSTOM_DATA_TYPES,
  DATA_TYPES_VALUE,
  convertArrayAsInput,
  convertCaSampleValues,
  convertStringToList,
  formatNectedDate,
  generateUUID,
  generateUid,
  getPropertyIfExists,
  transformResponseCacheForWorkflow,
  transformSampleValue,
} from '../../../utils/common';
import {
  DELAY_OPTION_LIST,
  GSHEET_ACTION_METHODS,
  GSHEET_HEADERS_SELECTION,
  GSHEET_MAPPING_METHODS,
  GSHEET_UPDATING_METHODS,
  INTERVAL_OPTION_LIST,
  LIST_OF_LANGUAGES_FOR_CODE,
  bodyParamTypes,
  methods,
  sourceTypesToIgnoreOnValidation,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../utils/constant';
import {
  EXTRACT_TOKEN_REGEX,
  REMOVE_JAVASCRIPT_COMMENTS_REGEX,
} from '../../../utils/regex';
import { timeToExpireUnits } from '../../DataSets/utils';
import { CustomInputModel } from '../../Rules/components/SimpleRule/models';
import { AttributeModel } from '../../Rules/models';
import { getGroupNode } from '../../Rules/utils/common';
import type {
  WorkflowEdgeType,
  WorkflowNodeType,
} from '../hooks/useOpenWorkflow';
import {
  ApiFormAttributeType,
  DelayNodeForm,
  NodeWithDepth,
  RestApiNodeForm,
  SetVariableNodeForm,
  WorkflowAttribute,
} from '../models/models';

export const filterWorkflowNodes = (nodes: WorkflowNodeType[]) => {
  const newNodes: WorkflowNodeType[] = [];

  nodes.forEach((node, i) => {
    const updatedNode: any = {};

    Object.keys(node).forEach((key) => {
      if (!['height', 'width', 'selected', 'dragging', 'data'].includes(key)) {
        updatedNode[key] = node[key as keyof WorkflowNodeType];
      } else if (key === 'data') {
        const updatedData: Record<string, any> = {};

        for (const k in node[key]) {
          if (typeof node[key][k] !== 'function') {
            updatedData[k] = node[key][k];
          }
        }

        updatedNode[key] = updatedData;
      }
    });

    newNodes.push(updatedNode);
  });

  return newNodes;
};

export const filterWorkflowEdges = (
  edges: any[],
  workflowNodes: WorkflowNodeType[]
) => {
  let newEdges: any[] = [];

  edges.forEach((edge, i) => {
    const updatedEdges: Record<string, any> = {};

    Object.keys(edge).forEach((key) => {
      if (!['data', 'selected'].includes(key)) {
        updatedEdges[key] = edge[key];
      } else if (key === 'data') {
        const updatedData: Record<string, any> = {};

        for (const k in edge[key]) {
          if (typeof edge[key][k] !== 'function') {
            updatedData[k] = edge[key][k];
          }
        }

        updatedEdges[key] = {
          ...updatedData,
          edgeType:
            !_isNil(updatedData.edgeType) && !_isEmpty(updatedData.edgeType)
              ? updatedData.edgeType
              : 'then',
        };
      }
    });

    newEdges.push(updatedEdges);
  });

  newEdges = newEdges.filter(
    (edge) => !(workflowNodes.find((w) => w.id === edge.target) == null)
  );

  newEdges = newEdges.filter(
    (edge) => !(workflowNodes.find((w) => w.id === edge.source) == null)
  );

  return newEdges;
};

export const transformTriggerSheetAttributesToExValue = (attributes: any[]) => {
  const values: Record<string, any> = {};
  attributes.forEach((data) => {
    if (data.selectedType?.dataType === 'string') {
      values[data.name] = data.executedValue ?? '';
    } else if (['date', 'dateTime'].includes(data.selectedType?.dataType)) {
      values[data.name] = data.executedValue;
    } else if (data.selectedType?.dataType === 'numeric') {
      values[data.name] = parseFloat(data.executedValue);
    } else if (data.selectedType?.dataType === 'boolean') {
      // eslint-disable-next-line
      values[data.name] = !!data.executedValue;
    } else if (data.selectedType?.dataType === 'json') {
      let val = data.executedValue;

      try {
        val = JSON.parse(data.executedValue);
      } catch {}
      values[data.name] = val;
    } else if (data.selectedType?.dataType === 'list') {
      values[data.name] = convertArrayAsInput(data.executedValue);
    }
  });

  return values;
};

export const transformTriggerSheetAttributes = (data: AttributeModel[]) => {
  const finalValue: Record<string, ApiFormAttributeType> = {};

  data.forEach((attribute: AttributeModel) => {
    finalValue[attribute.name] = {
      name: attribute.name,
      dataType: attribute.selectedType?.value ?? 'string',
      schemaId: attribute.schemaId,
      isNullable: !!attribute.isNullable,
      isOptional: !!attribute.isOptional,
      isCaseSensitive: !!attribute.isCaseSensitive,
      executedValue: convertCaSampleValues(
        attribute.selectedType?.value ?? 'string',
        attribute.executedValue
      ),
    };
  });

  return finalValue;
};

export const formatCustomAttributesForSheet = (
  data: Record<string, ApiFormAttributeType> = {}
) => {
  if (_isNil(data)) {
    return [];
  }

  return Object.keys(data).map((attributeName) => {
    return {
      ...data[attributeName],
      selectedType: {
        dataType: data[attributeName].dataType,
        key: 'primitive',
        value: data[attributeName].dataType,
      },
      sampleValue: convertSampleValue(
        data[attributeName].sampleValue ?? data[attributeName].executedValue,
        data[attributeName].dataType ?? ''
      ),
      name: attributeName,
      executedValue: convertExecutedValue(
        data[attributeName].executedValue ?? data[attributeName].sampleValue,
        data[attributeName].dataType ?? ''
      ),
    };
  });
};

export const convertSampleValue = (value: any, dataType: string) => {
  return ['json', 'restAPI'].includes(dataType)
    ? JSON.stringify(value ?? {})
    : value;
};

export const convertExecutedValue = (value: any, dataType: string) => {
  if (['date', 'dateTime'].includes(dataType)) {
    return formatNectedDate(value, dataType);
  } else {
    return value;
  }
};

export const getChildNodesAndEdges = (
  currentNodeId: string,
  currEdges: any[],
  currentNodes: WorkflowNodeType[]
) => {
  // Filter edges to find those with the specified node as the source
  const childEdges = currEdges.filter((edge) => edge.source === currentNodeId);

  // Extract target nodes from the child edges
  const childNodes = childEdges
    .map((edge) => currentNodes.find((node) => node.id === edge.target))
    .filter(Boolean);

  return { childNodes, childEdges };
};

export const getParentEdges = (
  currentNodeId: string,
  currentEdges: any[]
): any[] => {
  let edges = currentEdges.filter((edge) => edge.target === currentNodeId);
  const edgeIds: string[] = [];

  edges = edges.filter((edge) => {
    if (edgeIds.includes(edge.id)) {
      edgeIds.push(edge.id);

      return false;
    }

    edgeIds.push(edge.id);

    return true;
  });

  return edges;
};

export const getDirectParentsSkippingType = (
  nodes: WorkflowNodeType[],
  edges: any[],
  currentNodeId: string,
  skipType: string
): WorkflowNodeType[] => {
  const parentEdges = edges.filter((edge) => edge.target === currentNodeId);
  const parentNodes: WorkflowNodeType[] = [];

  parentEdges.forEach((edge) => {
    const parentNode = nodes.find((node) => node.id === edge.source);

    if (parentNode != null) {
      if (parentNode.type === skipType) {
        // Recursively get direct parent nodes if the parent is of type 'addNode'
        const grandParentNodes = getDirectParentsSkippingType(
          nodes,
          edges,
          parentNode.id,
          skipType
        );
        parentNodes.push(...grandParentNodes);
      } else {
        // If the parent is not of type 'addNode', add it to the result
        parentNodes.push(parentNode);
      }
    }
  });

  return parentNodes;
};

const checkIsNestedLoopNode = (
  childLoopId: string | undefined | null,
  parentLoopId: string | undefined | null,
  nodes: WorkflowNodeType[]
) => {
  if (_isNil(childLoopId) || _isNil(parentLoopId)) {
    return false;
  }

  const parentLoopNodes = getAllParentLoopNodesForCurrentNode(
    childLoopId,
    nodes
  );

  return parentLoopNodes.some((node: any) => node.id === parentLoopId);
};

export const checkIfCurrentNodeIsLoopNode = (
  nodeId: string,
  nodes: WorkflowNodeType[]
) => {
  let isLoopNode = false;

  if (_isNil(nodeId) || _isEmpty(nodeId)) {
    return isLoopNode;
  }

  const currentNode = nodes.find((node) => node.id === nodeId);

  if (currentNode?.data.nodeType === 'loopNode') {
    isLoopNode = true;
  }

  return isLoopNode;
};

// Recursively get all the parent loop nodes for the current node
export const getAllParentLoopNodesForCurrentNode = (
  nodeId: string,
  nodes: WorkflowNodeType[]
): any[] => {
  const currentNode = nodes.find((node) => node.id === nodeId);
  let parentId = currentNode?.data.rootId;

  const predecessors: WorkflowNodeType[] = [];

  while (!_isNil(parentId) && !_isEmpty(parentId)) {
    const parentNode = nodes.find((node) => node.id === parentId);

    if (checkIfCurrentNodeIsLoopNode(parentId, nodes)) {
      if (!_isNil(parentNode) && !_isEmpty(parentNode)) {
        predecessors.push(parentNode);
      } else {
        break;
      }
    }

    parentId = parentNode?.data.rootId;
  }

  return predecessors;
};

export const checkIfCurrentNodeIsInsideLoopNode = (
  nodeId: string,
  nodes: WorkflowNodeType[]
) => {
  const parentLoopNodes = getAllParentLoopNodesForCurrentNode(nodeId, nodes);

  return {
    status: !_isNil(parentLoopNodes) && !_isEmpty(parentLoopNodes),
    parentLoopNode: parentLoopNodes?.[0] ?? {},
  };
};

export const getAllPredecessorsSkippingType = (
  nodeId: string,
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  skipType?: string
): any[] => {
  const predecessors: WorkflowNodeType[] = [];
  const visited: Set<string> = new Set();
  const queue: string[] = [nodeId];

  const { status: isNodeInsideLoopNode, parentLoopNode } =
    checkIfCurrentNodeIsInsideLoopNode(nodeId, nodes);

  while (queue.length > 0) {
    // eslint-disable-next-line
    const currentNodeId: string = queue.shift()!;
    visited.add(currentNodeId);

    const currentNode = nodes.find((node) => node.id === currentNodeId);

    if (currentNode != null && currentNode.type !== 'input') {
      //  If node is not inside loop node then ignore all the nodes that are present inside
      //  loop node and traverse above

      const {
        status: isCurrentNodeInsideLoopNode,
        parentLoopNode: currentNodeParentLoopNode,
      } = checkIfCurrentNodeIsInsideLoopNode(currentNodeId, nodes);

      if (!isNodeInsideLoopNode && !isCurrentNodeInsideLoopNode) {
        predecessors.push(currentNode);
      }

      if (isNodeInsideLoopNode) {
        if (isCurrentNodeInsideLoopNode) {
          // check if current node parent is same as nodeParentId then do not ignore
          if (currentNodeParentLoopNode?.id === parentLoopNode?.id) {
            predecessors.push(currentNode);
          }

          const isCurrentNodeLoopNode = checkIfCurrentNodeIsLoopNode(
            currentNodeId,
            nodes
          );

          // check if current node parent is children of nodeParentId
          // and if current node is not loop node then do not ignore
          if (
            !isCurrentNodeLoopNode &&
            checkIsNestedLoopNode(
              parentLoopNode?.id,
              currentNodeParentLoopNode?.id,
              nodes
            )
          ) {
            predecessors.push(currentNode);
          }
        } else {
          if (currentNode?.data?.nodeType !== 'loopNode') {
            predecessors.push(currentNode);
          } else {
            const nodesAllParentLoopNode = getAllParentLoopNodesForCurrentNode(
              nodeId,
              nodes
            );
            const isCurrentNodePresentInParentLoopNodes =
              nodesAllParentLoopNode.find((n: any) => n.id === currentNodeId);

            if (_isNil(isCurrentNodePresentInParentLoopNodes)) {
              predecessors.push(currentNode);
            }
          }
        }
      }

      const incomingEdges = edges.filter(
        (edge) => edge.target === currentNodeId
      );
      incomingEdges.forEach((edge) => {
        const sourceNodeId: string = edge.source;

        if (!visited.has(sourceNodeId)) {
          queue.push(sourceNodeId);
        }
      });
    }
  }

  return predecessors.filter(
    (n) => !_isNil(n) && n.type !== skipType && n.id !== nodeId
  );
};

export const getAllSuccessorsSkippingType = (
  nodeId: string,
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  skipType?: string
): any[] => {
  const successors: WorkflowNodeType[] = [];
  const visited: Set<string> = new Set();
  const queue: string[] = [nodeId];

  while (queue.length > 0) {
    // eslint-disable-next-line
    const currentNodeId: string = queue.shift()!;
    visited.add(currentNodeId);

    const currentNode = nodes.find((node) => node.id === currentNodeId);

    if (currentNode != null && currentNode.type !== 'output') {
      successors.push(currentNode);

      const outgoingEdges = edges.filter(
        (edge) => edge.source === currentNodeId
      );
      outgoingEdges.forEach((edge) => {
        const targetNodeId: string = edge.target;

        if (!visited.has(targetNodeId)) {
          queue.push(targetNodeId);
        }
      });
    }
  }

  return successors.filter(
    (n) => !_isNil(n) && n.type !== skipType && n.id !== nodeId
  );
};

export const getParentNodeAndEdgeRecursive = (
  nodeId: string,
  edges: WorkflowEdgeType[],
  nodes: WorkflowNodeType[],
  skipNodeType: string
): {
  parentNode: WorkflowNodeType | null;
  parentEdge: WorkflowEdgeType | null;
} => {
  const parentEdge = edges.find((edge) => edge.target === nodeId);

  if (parentEdge == null) {
    return { parentNode: null, parentEdge: null };
  }

  const parentNode = nodes.find(
    (node) => node.id === parentEdge.source && node.type !== skipNodeType
  );

  if (parentNode == null) {
    // If the parent node is not found or it's of the skipped type, recursively backtrack
    return getParentNodeAndEdgeRecursive(
      parentEdge.source,
      edges,
      nodes,
      skipNodeType
    );
  }

  return { parentNode, parentEdge };
};

export const getNodesByType = (
  nodes: WorkflowNodeType[],
  nodeType: string
): WorkflowNodeType[] => {
  return nodes.filter((node) => node.type === nodeType);
};

export const transformRuleAttributes = (attributes: WorkflowAttribute[]) => {
  const ruleInput: Record<string, any> = {};

  attributes.forEach((attribute) => {
    ruleInput[attribute.keyName] = {
      value:
        !_isNil(attribute.value) && attribute.isNullable !== true
          ? convertCaSampleValues(
              attribute.dataType ?? 'string',
              attribute.value
            )
          : null,
      attribute: attribute.attribute,
      source: attribute.source,
      dataType: attribute.dataType,
      name: attribute.keyName,
      sendNull: attribute.isNullable === true,
      notSend: attribute.isOptional === true,
    };
  });

  return ruleInput;
};

export const transformWorkflowSettings = (data: any) => {
  let timeout = 30;

  try {
    timeout = parseInt(data.settings.timeout);
  } catch (error) {
    timeout = data.settings.timeout;
  }

  const settings = {
    timeout,
    errorContinue: data.settings.errorContinue ?? false,
  };

  return settings;
};

export const transformRuleNode = (data: any) => {
  let timeout = 30;

  try {
    timeout = parseInt(data.settings.timeout);
  } catch (error) {
    timeout = data.settings.timeout;
  }

  const settings = {
    timeout,
    runAction: data.settings.runAction,
    // eslint-disable-next-line
    actionInSync: !!data.settings.runAction
      ? data.settings.actionInSync
      : false,
    errorContinue: data.settings.errorContinue === true,
  };

  return {
    name: data.name,
    attributes: transformRuleAttributes(data.attributes),
    settings,
    selectedRule: data.selectedRule?.value,
  };
};

export const formatDataAttributes = (input: Record<string, any>) => {
  return _map(input, (val, key) => {
    return {
      keyName: key,
      source: val.source ?? null,
      attribute: val.attribute ?? null,
      value: val.value,
      dataType: val.dataType,
    };
  });
};

export const generateUniqueName = (
  nodes: WorkflowNodeType[],
  desiredName: string
): string => {
  const existingNames = new Set<string>();

  nodes.forEach((node) => {
    if (!_isNil(node.data.name)) {
      existingNames.add(node.data.name);
    }
  });

  desiredName = desiredName.replaceAll(' ', '_');

  let newName = desiredName;
  let counter = 1;

  while (existingNames.has(newName)) {
    newName = `${desiredName}${counter}`;
    counter++;
  }

  return newName.replaceAll(' ', '_');
};

export const checkIfNameExists = (
  nodes: WorkflowNodeType[],
  name: string,
  currentNode: WorkflowNodeType
): boolean => {
  return !(
    nodes.find(
      (node) => node.data.name === name && currentNode.id !== node.id
    ) == null
  );
};

export const getNodeNameByRuleName = (type: string) => {
  switch (type) {
    case 'decisionTable':
      return 'dtNode';
    case 'simpleRule':
      return 'srNode';

    case 'ruleSet':
      return 'ruleSetNode';
  }

  return 'dtNode';
};

export const getNodeNameByPluginName = (type: string) => {
  switch (type) {
    case 'database':
      return 'dbNode';
    case 'api':
      return 'restApiNode';
    case 'third-party':
      return 'gSheetNode';
    default:
      return 'dbNode';
  }
};

export const getStartIdFromTrigger = (
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  triggerNodeId: string
): string => {
  const triggerNode = nodes.find((node) => node.id === triggerNodeId);

  if (triggerNode == null || triggerNode.type !== 'trigger') {
    return '';
  }

  const outgoingEdges = edges.filter((edge) => edge.source === triggerNodeId);

  const addNode = nodes.find((node) => node.id === outgoingEdges[0]?.target);

  if (addNode == null || addNode.type !== 'addNode') {
    return '';
  }

  const outgoingEdges2 = edges.filter((edge) => edge.source === addNode.id);

  const firstNode = nodes.find((node) => node.id === outgoingEdges2[0]?.target);

  if (firstNode == null) {
    return '';
  }

  return firstNode.id;
};

export const sortObjectByStep = (
  objectOfObjects: Record<string, Record<string, any>>
) => {
  if (
    typeof objectOfObjects !== 'object' ||
    objectOfObjects === null ||
    Array.isArray(objectOfObjects)
  ) {
    throw new Error('Input must be an object');
  }

  const listOfObjects: Array<Record<string, any>> = Object.entries(
    objectOfObjects
  ).map(([key, value]) => ({
    key,
    ...value,
  }));

  listOfObjects.sort((a, b) => a.step - b.step);

  return listOfObjects;
};

export const appendTypeToEdges = (
  edges: WorkflowEdgeType[],
  type: 'smoothEdge' | 'step' = 'smoothEdge'
): WorkflowEdgeType[] => {
  return edges.map((edg) => ({
    ...edg,
    type,
  }));
};

export const getItemsToBeDeletedBranch = (
  nodes: WorkflowNodeType[],
  currentNode: WorkflowNodeType
) => {
  const idsToBeDeleted: string[] = [currentNode.id];

  nodes.forEach((node, index) => {
    if (node.data.rootId === currentNode.id) {
      idsToBeDeleted.push(node.id);

      if (['srNode', 'switchNode'].includes(node.data.nodeType)) {
        getBranchNodeIdsRecursion(nodes, node, idsToBeDeleted);
      }
    }
  });

  return idsToBeDeleted;
};

export const getBranchNodeIdsRecursion = (
  nodes: WorkflowNodeType[],
  currentNode: WorkflowNodeType,
  idsToBeDeleted: string[]
) => {
  nodes.forEach((node, index) => {
    if (node.data.rootId === currentNode.id) {
      idsToBeDeleted.push(node.id);

      if (node.data.nodeType === 'srNode') {
        getBranchNodeIdsRecursion(nodes, node, idsToBeDeleted);
      }
    }
  });
};

export const getRootNodeEqualToCurrentNodeRootId = (
  nodes: WorkflowNodeType[],
  currentNode: WorkflowNodeType
) => {
  return nodes.find((node) => node.id === currentNode.data.rootId);
};

export const getEdgeIdsByNodeIds = (
  edges: WorkflowEdgeType[],
  nodeIds: string[]
) => {
  return edges
    .filter((edge) => {
      if (nodeIds.includes(edge.source)) {
        return true;
      }

      return false;
    })
    .map((edge) => edge.id);
};

export const getMergeNodeCorrespondingToSrId = (
  nodes: WorkflowNodeType[],
  nodeId: string
) => {
  return nodes.find(
    (node) => node.data.rootId === nodeId && node.data.isMergeNode
  );
};

export const getNextNode = (
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  currentNodeId: string
): WorkflowNodeType | null => {
  const outgoingEdges = edges.filter((edge) => edge.source === currentNodeId);

  if (outgoingEdges.length > 0) {
    // Assuming there's only one outgoing edge, you may need to adjust the logic if there can be multiple
    const nextNodeId = outgoingEdges[0].target;
    const nextNode = nodes.find((node) => node.id === nextNodeId);

    return nextNode ?? null;
  }

  return null; // No outgoing edges, no next node
};

export const getParentNodes = (
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  currentNodeId: string
): WorkflowNodeType[] => {
  const incomingEdges = edges.filter((edge) => edge.target === currentNodeId);

  const parentNodes = incomingEdges.map((edge) => {
    const parentNode = nodes.find((node) => node.id === edge.source);

    return parentNode ?? null;
  });

  return parentNodes.filter((node) => node !== null) as WorkflowNodeType[];
};

export const sortNodesByPositionYAsc = (
  nodes: WorkflowNodeType[]
): WorkflowNodeType[] => {
  const sortedNodes = [...nodes].sort((a, b) => a.position.y - b.position.y);

  return sortedNodes;
};

export const sortNodesByPositionYDesc = (
  nodes: WorkflowNodeType[]
): WorkflowNodeType[] => {
  const sortedNodes = [...nodes].sort((a, b) => b.position.y - a.position.y);

  return sortedNodes;
};

export const getOrderedSuccessorNodesRecursiveDFS = (
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  startNodeId: string
): WorkflowNodeType[] => {
  const visited: Set<string> = new Set();
  const successors: NodeWithDepth[] = [];

  const dfs = (currentNodeId: string, depth: number) => {
    visited.add(currentNodeId);

    const outgoingEdges = edges.filter((edge) => edge.source === currentNodeId);

    for (const edge of outgoingEdges) {
      const targetNodeId = edge.target;

      if (!visited.has(targetNodeId)) {
        dfs(targetNodeId, depth + 1);
      }

      const targetNode = nodes.find(
        (node) => node.id === targetNodeId
      ) as WorkflowNodeType;
      successors.push({ node: targetNode, depth });
    }
  };

  dfs(startNodeId, 0);

  // Sort successors based on depth and then by insertion order within each depth level
  const sortedSuccessors = successors.sort((a, b) => {
    if (a.depth !== b.depth) {
      return a.depth - b.depth;
    }

    // Preserve insertion order within each depth level
    return a.depth - b.depth;
  });

  // Return the sorted successors without depth information
  return sortedSuccessors
    .map(({ node }) => node)
    .filter((node) => !_isNil(node));
};

export function formatRuleAttributes(
  customInput: Record<string, CustomInputModel>,
  inputAttributes: Record<string, any> = {}
) {
  return _reduce(
    customInput,
    (res: any, val, key) => {
      return [
        ...res,
        {
          keyName: key,
          value: transformSampleValue(
            val.dataType,
            inputAttributes?.[key]?.value ?? null
          ),
          dataType: val.dataType,
          source: inputAttributes?.[key]?.source ?? null,
          attribute: inputAttributes?.[key]?.attribute ?? null,
          isNullable:
            inputAttributes?.[key]?.sendNull === true ||
            inputAttributes?.[key]?.isNullable === true,
          isOptional:
            inputAttributes?.[key]?.notSend === true ||
            inputAttributes?.[key]?.isOptional === true,
        },
      ];
    },
    []
  );
}

export const getErrorState = (result: Record<string, any>) => {
  return (
    (!_isNil(result.error) && !_isEmpty(result.error)) ||
    (!_isNil(result.data?.error) && !_isEmpty(result.data?.error))
  );
};

export const extractTokens = (inputString: string) => {
  const matches = inputString.match(EXTRACT_TOKEN_REGEX) ?? [];

  return matches;
};

export const convertValues = (type: any, value: any, meta: any) => {
  if (type === 'array' || type === 'object') {
    return JSON.stringify(value, null);
  } else {
    if (meta.toLowerCase() === 'datetime') {
      return formatNectedDate(value, 'dateTime');
    } else if (meta.toLowerCase() === 'date') {
      return formatNectedDate(value, 'date');
    }

    return value;
  }
};

export const detectType = (value: any) => {
  if (value === null) {
    return null;
  }

  if (typeof value === 'object') {
    return Array.isArray(value) ? 'array' : 'object';
  }

  return typeof value;
};

export const sanitizedString = (
  value: string,
  allAutocomplete: NectedSuggestionModel[]
) => {
  if (_isNil(value) || _isEmpty(value)) {
    return value;
  }

  let updatedValue = value;
  const tokens: string[] = extractTokens(value);

  tokens.forEach((i) => {
    const tokenValue = allAutocomplete.find((j) => j.value.includes(i));

    if (!_isNil(tokenValue)) {
      updatedValue = updatedValue.replace(
        i,
        !_isNil(tokenValue.executedValue)
          ? convertValues(
              detectType(tokenValue.executedValue),
              tokenValue.executedValue,
              tokenValue.meta
            )
          : DATA_TYPES_VALUE[tokenValue.meta]
      );
    } else {
      Object.keys(CUSTOM_DATA_TYPES).forEach((element) => {
        const token = i.split('.')[1];

        if (token === element) {
          updatedValue = updatedValue.replace(
            i,
            CUSTOM_DATA_TYPES[element].value
          );
        }
      });
      updatedValue = updatedValue.replace(i, '');
    }
  });

  return updatedValue;
};

export const checkAttributeIsValid = (
  dataset: Record<string, Dataset>,
  source?: string,
  attribute?: string
) => {
  const isValid = true;

  const ignoreSourceValidation = sourceTypesToIgnoreOnValidation.includes(
    source ?? ''
  );

  if (
    _isNil(source) ||
    _isEmpty(source) ||
    _isNil(attribute) ||
    _isEmpty(attribute) ||
    ignoreSourceValidation
  ) {
    return {
      isValid,
      message: '',
    };
  }

  const mappedData = getPropertyIfExists(
    JSON.parse(
      JSON.stringify(
        Object.keys(dataset[source]?.attributes ?? {}).reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: dataset[source].attributes[`${curr}`].executedValue,
          };
        }, {})
      )
    ) ?? {},
    attribute
  );

  return {
    isValid: !_isUndefined(mappedData),
    message: _isUndefined(mappedData) ? 'Unable to find attribute' : '',
  };
};

export const checkUsedTokensAreValid = (
  codeString: string,
  dataset: Record<string, Dataset>
) => {
  let isValidTokenUsed = true;
  const value = codeString
    .toString()
    .replaceAll(REMOVE_JAVASCRIPT_COMMENTS_REGEX, '');

  if (_isNil(value) || _isEmpty(value)) {
    return {
      isValidTokenUsed,
      message: '',
    };
  }

  const tokens = (typeof value === 'string' ? value : '')
    .match(EXTRACT_TOKEN_REGEX)
    ?.map((token) => {
      const newToken = token.replace('{{.', '').replace('}}', '');

      return {
        source: newToken.split('.')[0],
        attribute: newToken.split('.').splice(1).join('.'),
      };
    });

  tokens
    ?.filter((token) => !sourceTypesToIgnoreOnValidation.includes(token.source))
    .forEach((token) => {
      if (!_isNil(dataset[token.source])) {
        const mappedData = getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              Object.keys(dataset[token.source].attributes).reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      dataset[token.source].attributes[`${curr}`].executedValue,
                  };
                },
                {}
              )
            )
          ) ?? {},
          token.attribute
        );

        const dataType = getDataTypeNected(mappedData);

        if (dataType === '') {
          isValidTokenUsed = false;
        }
      } else {
        isValidTokenUsed = false;
      }
    });

  return {
    isValidTokenUsed,
    message: isValidTokenUsed ? '' : 'Invalid Token Used',
  };
};

export const sanitizedStringV2 = (
  value: string,
  dataset: Record<string, Dataset>,
  filterSourceList: string[] = []
) => {
  if (_isNil(value) || _isEmpty(value)) {
    return value;
  }

  let updatedValue = value;
  const tokens = (typeof value === 'string' ? value : '')
    .match(EXTRACT_TOKEN_REGEX)
    ?.map((token) => {
      const newToken = token.replace('{{.', '').replace('}}', '');

      return {
        source: newToken.split('.')[0],
        attribute: newToken.split('.').splice(1).join('.'),
      };
    });

  tokens?.forEach((token) => {
    if (filterSourceList.includes(token.source)) {
      // eslint-disable-next-line
      return;
    } else if (!_isNil(dataset[token.source])) {
      const mappedData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            Object.keys(dataset[token.source].attributes).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]:
                    dataset[token.source].attributes[`${curr}`].executedValue,
                };
              },
              {}
            )
          )
        ) ?? {},
        token.attribute
      );

      const dataType = getDataTypeNected(mappedData);

      updatedValue = updatedValue.replaceAll(
        `{{.${token.source}.${token.attribute}}}`,
        convertValues(detectType(mappedData), mappedData, dataType) ?? null
      );
    }
  });

  return updatedValue;
};

// If there is Run In Loop present in SR Node then disable its false branch.
export const checkNodeDisabled = (
  rootId: string,
  nodeId: string,
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[]
) => {
  let currentNode = nodes.find((node) => node.id === nodeId);
  let rootNode = nodes.find((node) => node.id === rootId);
  let message = 'Node is disabled';

  let search = true;
  let isDisabled = false;

  while (search) {
    if (!_isNil(currentNode)) {
      search =
        !_isNil(currentNode.data.rootId) &&
        currentNode.data.rootId !== '' &&
        !isDisabled;

      rootNode = nodes.find((node) => node.id === currentNode?.data.rootId);

      if (!isDisabled) {
        if (
          rootNode != null &&
          !_isNil(rootNode.data.runInLoop) &&
          rootNode.data.nodeType === 'srNode' &&
          (rootNode.data.runInLoop.status as boolean)
        ) {
          isDisabled = true;
          search = false;
          message =
            "Node is disabled as the runInLoop is enabled in it's root SR node";
        } else if (
          rootNode != null &&
          rootNode.data.nodeType === 'switchNode' &&
          !(rootNode.data.input.defaultPath.value as boolean)
        ) {
          isDisabled = true;
          search = false;
          message = 'Node is disabled as the default branch is disabled';
        }

        currentNode = rootNode;
      }
    } else {
      search = false;
    }
  }

  const elseEdge = edges
    .filter((edge) => edge.source === rootNode?.id)
    .find(
      (edg) =>
        edg.data.edgeType === 'else' || edg.data.edgeType === 'defaultCase'
    );

  if (!_isNil(elseEdge) && isDisabled) {
    const successors = getOrderedSuccessorNodesRecursiveDFS(
      nodes,
      edges,
      elseEdge.target
    );

    if (
      !_isNil(successors.find((s) => s.id === nodeId)) ||
      nodeId === elseEdge.target
    ) {
      const curNode = nodes.find((n) => n.id === nodeId);

      if (
        (curNode?.data.isMergeNode as boolean) &&
        curNode?.data.rootId === rootId
      ) {
        return {
          isDisabled: false,
          message,
        };
      }

      return {
        isDisabled: true,
        message,
      };
    } else {
      return {
        isDisabled: false,
        message,
      };
    }
  }

  return {
    isDisabled,
    message,
  };
};

export const transformCodeInWorkflow = (data: Record<string, any>) => {
  let timeout: number | null = null;

  try {
    timeout = +data.settings.timeout;
  } catch (error) {
    timeout = null;
  }

  return {
    name: data.name,
    language: { value: data.language?.value ?? 'JS' },
    query: { value: data.query },
    settings: {
      timeout,
      errorContinue: data.settings.errorContinue === true,
    },
  };
};

export const transformResponseInWorkflow = (node: SetVariableNodeForm) => {
  return {
    name: node.name,
    settings: node.settings,
    input: node.attributes.reduce(
      (attributesObj: Record<string, WorkflowAttribute>, currAttribute) => ({
        ...attributesObj,
        [currAttribute.keyName]: {
          ...currAttribute,
          name: currAttribute.keyName,
        },
      }),
      {}
    ),
  };
};

export const formatCodeInWorkflow = (data: Record<string, any>) => {
  return {
    name: data.name,
    language: !_isNil(data.input?.language?.value)
      ? LIST_OF_LANGUAGES_FOR_CODE.find(
          (l) => l.value === data.input?.language?.value
        ) ?? { label: 'JavaScript', value: data.input.language.value }
      : { label: 'JavaScript', value: 'JS' },
    query: data.input?.snippet?.value ?? '',
    settings: data.settings,
  };
};

export const formatResponseInWorkflow = (data: Record<string, any>) => {
  return {
    name: data.name,
    settings: data.settings,
    attributes: _map(data.input, (currAttribute) => ({
      ...currAttribute,
      keyName: currAttribute.name,
    })).sort((attr1, attr2) => attr1?.order - attr2?.order),
  };
};

export const formatDelayInWorkflow = (data: Record<string, any>) => {
  let dateTime: Date | null = null;

  if (!_isNil(data.input?.dateTime?.value)) {
    dateTime = new Date(data.input?.dateTime?.value);
  } else {
    dateTime = new Date();
  }

  const response = {
    name: data.name,
    action:
      DELAY_OPTION_LIST.find((i) => i.value === data.input?.action?.value) ??
      DELAY_OPTION_LIST[0],
    dateTime,
    amount: data.input?.amount,
    unit:
      INTERVAL_OPTION_LIST.find((d) => d.value === data.input?.unit?.value) ??
      null,
    settings: data.settings,
  };

  return response;
};

export function transformDelayNodeData(node: DelayNodeForm) {
  let timeout: number | null = null;

  try {
    timeout = +node.settings.timeout;
  } catch (error) {
    timeout = null;
  }

  if (node.action.value === 'immed') {
    return {
      name: node.name,
      settings: {
        timeout,
      },
      amount: {
        value: null,
      },
      dateTime: {
        value: null,
      },
      action: {
        value: node.action.value,
      },
      unit: {
        value: null,
      },
    };
  } else if (node.action.value === 'AST') {
    return {
      name: node.name,
      settings: node.settings,
      amount: {
        value: null,
      },
      dateTime: {
        value: node.dateTime,
      },
      action: {
        value: node.action.value,
      },
      unit: {
        value: null,
      },
    };
  } else {
    const isToken =
      !_isNil(node.amount?.attribute) && node.amount?.attribute !== '';

    return {
      name: node.name,
      settings: node.settings,
      amount: {
        ...node.amount,
        value: isToken
          ? null
          : typeof node.amount === 'string'
          ? parseInt(node.amount)
          : parseInt(node.amount?.value),
      },
      dateTime: {
        value: null,
      },
      action: {
        value: node.action.value,
      },
      unit: {
        value:
          !_isNil(node.unit) && typeof node.unit !== 'string'
            ? node.unit.value
            : 'minute',
      },
    };
  }
}

export const formatSetVariableInWorkflow = (data: Record<string, any>) => {
  return {
    name: data.name,
    settings: data.settings,
    attributes: _map(data.input, (currAttribute) => ({
      ...currAttribute,
      keyName: currAttribute.name,
    })).sort((attr1, attr2) => attr1?.order - attr2?.order),
  };
};

export const transformSetVariableNodeData = (node: SetVariableNodeForm) => {
  let timeout: number | null = null;

  try {
    timeout = +node.settings.timeout;
  } catch (error) {
    timeout = null;
  }

  return {
    name: node.name,
    settings: {
      ...node.settings,
      timeout,
      errorContinue: node.settings.errorContinue === true,
    },
    input: node.attributes.reduce(
      (
        attributesObj: Record<string, WorkflowAttribute>,
        currAttribute,
        index
      ) => ({
        ...attributesObj,
        [currAttribute.keyName]: {
          ...currAttribute,
          value:
            !_isNil(currAttribute.value) &&
            !['jsFormula', 'json', 'excelFormula', 'list'].includes(
              currAttribute.dataType
            )
              ? convertCaSampleValues(
                  currAttribute.dataType ?? 'string',
                  currAttribute.value
                )
              : currAttribute.value ?? null,
          name: currAttribute.keyName,
          order: index,
        },
      }),
      {}
    ),
  };
};

export const getExecutedValueOfUsedAttributes = (
  attributes: Record<string, WorkflowAttribute>,
  dataset: Record<string, Dataset>,
  isJsonStringify = true
) => {
  const attributeExecutedValues: Record<string, any> = _reduce(
    attributes,
    (execValObj, currAttribute) => {
      if (
        sourceTypesToIgnoreOnValueReplacement.includes(
          currAttribute.source ?? ''
        )
      ) {
        return {
          ...execValObj,
        };
      }

      if (currAttribute.source === 'loop') {
        return {
          ...execValObj,
          [currAttribute.keyName]: undefined,
        };
      }

      const hasSource =
        !_isNil(currAttribute.source) && !_isEmpty(currAttribute.source);

      let mappedValue =
        !_isNil(currAttribute.source) &&
        !_isEmpty(currAttribute.source) &&
        !_isNil(currAttribute.attribute) &&
        !_isEmpty(currAttribute.attribute)
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataset[currAttribute.source].attributes).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataset[currAttribute.source ?? ''].attributes[
                            `${curr}`
                          ].executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              currAttribute.attribute ?? ''
            )
          : currAttribute.value;

      if (
        !_isUndefined(mappedValue) &&
        !['json', 'jsFormula', 'excelFormula', 'list'].includes(
          currAttribute.dataType
        )
      ) {
        mappedValue = convertCaSampleValues(
          currAttribute.dataType,
          mappedValue,
          hasSource
        );
      }

      if (
        ['json', 'jsFormula', 'excelFormula', 'list'].includes(
          currAttribute.dataType
        ) &&
        !hasSource
      ) {
        mappedValue = sanitizedStringV2(
          currAttribute.value ?? '',
          dataset,
          sourceTypesToIgnoreOnValueReplacement
        );

        if (!isJsonStringify) {
          mappedValue =
            typeof mappedValue === 'string'
              ? JSON.parse(mappedValue)
              : mappedValue;
        }
      }

      if (currAttribute.dataType === 'list') {
        mappedValue = _isUndefined(mappedValue)
          ? []
          : typeof mappedValue === 'string'
          ? mappedValue
              .replaceAll('"[', '[')
              .replaceAll(']"', ']')
              .replaceAll('\\"', "'")
          : mappedValue;
      }

      if (currAttribute.dataType === 'string' && _isUndefined(mappedValue)) {
        mappedValue = mappedValue ?? '';
      }

      if (currAttribute?.sendNull as boolean) {
        mappedValue = null;
      }

      if (
        !!(currAttribute?.notSend as boolean) ||
        currAttribute.dataType === 'restAPI'
      ) {
        mappedValue = undefined;
      }

      return {
        ...execValObj,
        [currAttribute.keyName]: mappedValue,
      };
    },
    {}
  );

  return attributeExecutedValues;
};

export const transformDBNode = (data: any) => {
  const settings = {
    cacheEnabled: data.settings.cacheEnabled,
    durationUnit: data.settings.durationUnit?.value,
    durationValue: parseInt(data.settings.durationValue),
    rowLimit: parseInt(data.settings.rowLimit),
    timeout: parseInt(data.settings.timeout),
    errorContinue: data.settings.errorContinue === true,
  };

  return {
    name: data.name,
    settings,
    entityId: data.integration?.value,
    input: {
      ...data.input,
      action: {
        value: data.input.action.value.value,
      },
    },
    action: data.action,
  };
};

export const checkIfNodeHasSrRoot = (
  rootId: string,
  nodes: WorkflowNodeType[]
) => {
  let rootNode: WorkflowNodeType | null = null;
  let currentNode = nodes.find((node) => node.id === rootId);

  if (currentNode != null) {
    while (rootNode === null) {
      // eslint-disable-next-line
      if (!!currentNode?.data.rootId as boolean) {
        currentNode = nodes.find((node) => node.id === rootId);
      }

      if (
        currentNode?.data.nodeType === 'srNode' ||
        currentNode?.data.nodeType === 'switchNode' ||
        currentNode?.data.nodeType === 'loopNode'
      ) {
        rootNode = currentNode;
      }
    }
  }

  return rootNode;
};

export const formatDbNodeSettings = (settings: Record<string, any>) => {
  return {
    ...settings,
    durationUnit:
      timeToExpireUnits.find((i) => i.value === settings?.durationUnit) ?? null,
  };
};

export const getExecutedValueAndStatus = (entity: any, name?: string) => {
  const testOutput = entity?.data?.data?.testOutput;
  const testOutputKey = Object.keys(testOutput ?? {})[0];

  const executedValue = structuredClone(
    testOutput?.[name ?? testOutputKey]?.executedValue ?? {}
  );

  if ('logId' in executedValue) {
    delete executedValue.logId;
  }

  return {
    executedValue,
    status: testOutput?.[name ?? testOutputKey]?.status,
    workflowStatus: entity?.data?.data?.workflowStatus ?? '',
    executionId: testOutput?.[name ?? testOutputKey]?.executedValue?.logId,
    error:
      testOutput?.[name ?? testOutputKey]?.error ??
      testOutput?.[name ?? testOutputKey]?.executedValue?.error,
  };
};

export const getFieldsByValue = (value: any[] = []) => {
  const elements = getObjectUnion(value);

  return Object.keys(elements ?? {}).reduce<any>((acc, curr, index) => {
    return {
      ...acc,
      [curr]: {
        columnName: curr,
        dataType: getDataTypeNected(elements[curr]),
        order: index + 1,
      },
    };
  }, {});
};

export const nonAddTypeNodes = (nodes: WorkflowNodeType[]) => {
  return nodes.filter((node) => node.type !== 'addNode').length;
};

export const formatRestApiInWorkflow = (data: Record<string, any>) => {
  const currentMethod = data.input?.method?.value ?? '';
  const currContentType = data.input?.contentType?.value ?? '';

  return {
    name: data.name,
    settings: {
      errorContinue: false,
      ...data.settings,
      cache: {
        enabled: data.settings?.cacheEnabled ?? false,
        duration: {
          unit:
            timeToExpireUnits.find(
              (u) => u.value === data.settings?.durationUnit
            ) ?? timeToExpireUnits[0],
          value: data.settings?.durationValue ?? 0,
        },
        cacheKeys: data.settings?.cacheKeys ?? '',
      },
    },
    integration:
      !_isNil(data.entityId) && data.entityId !== ''
        ? {
            label: data.name,
            value: data.entityId,
          }
        : undefined,
    input: !_isNil(data.input)
      ? {
          ...data.input,
          method: {
            ...data.input.method,
            value:
              !_isNil(currentMethod) && !_isEmpty(currentMethod)
                ? methods.find((methodObj) => methodObj.value === currentMethod)
                : methods[0],
          },
          contentType: {
            ...data.input.contentType,
            value:
              !_isNil(currContentType) && !_isEmpty(currContentType)
                ? bodyParamTypes.find(
                    (paramObj) => paramObj.value === currContentType
                  )
                : null,
          },
        }
      : null,
  };
};

export const formatGSheetInWorkflow = (data: Record<string, any>) => {
  const file = data.input.file?.value;
  const sheet = data.input?.sheet?.value;
  const actionMethod = GSHEET_ACTION_METHODS.find(
    (d) => d.value === data.input?.actionMethod?.value
  );
  const policy = GSHEET_UPDATING_METHODS.find(
    (d) => d.value === data.input?.policy?.value
  );

  const name = data.name;

  const settings = {
    timeout: 30,
    cacheEnabled: false,
    durationValue: 5,
    errorContinue: false,
    ...data.settings,
    rowLimit: data.settings.rowLimit ?? 1000,
    durationUnit:
      timeToExpireUnits.find((i) => i.value === data.settings?.durationUnit) ??
      timeToExpireUnits[2],
  };

  const mappingMethod = GSHEET_MAPPING_METHODS.find(
    (d) => d.value === data.input?.mappingMethod?.value
  );
  const filterList = data.input.filterList;
  const columnMatch = data.input.columnMatch?.value?.map((c: string) => ({
    label: c,
    value: c,
  }));

  const hasHeader = GSHEET_HEADERS_SELECTION.find(
    (d) => d.value === data.input.hasHeader?.value
  );

  const columnData = data.input.columnData?.value;

  const groupId = generateUid('group_');
  const conditionId = generateUid('condition_');

  const groupNode = getGroupNode('', [conditionId], 'and');
  const conditionNode = getBlankConditions(conditionId, groupId);

  const paths = !_isNil(data.conditions)
    ? [data.conditions?.nodes ?? {}]
    : [{ [groupId]: groupNode, ...conditionNode }];

  const values = {
    file,
    sheet,
    actionMethod,
    policy,
    name,
    settings,
    mappingMethod,
    filterList,
    hasHeader,
    columnMatch,
    columnData,
    paths,
  };

  return values;
};

export const transformRestNodeInWorkflow = (data: RestApiNodeForm) => {
  const settings = {
    timeout: parseInt(data.settings.timeout),
    errorContinue: data.settings.errorContinue ?? false,
    ...transformResponseCacheForWorkflow(data.settings.cache ?? {}),
  };

  return {
    name: data.name,
    settings,
    entityId: data?.integration?.value ?? '',
    input: {
      ...data.input,
      method: {
        ...data.input.method,
        value: data.input.method?.value?.value ?? '',
      },
      contentType: {
        ...data.input.contentType,
        value: data.input.contentType?.value?.value ?? '',
      },
    },
  };
};

// key (Input) can be custom value or token
// This function returns the actual value if the key is token
// otherwise returns the key
export const getKeyMapppedValueFromDataset = (
  key: string,
  dataset: Record<string, Dataset>,
  filterSourceList: string[] = []
) => {
  const tokens = extractTokens(key).map((token) =>
    token.replace('{{.', '').replace('}}', '')
  );

  let mappedValue = key;

  tokens?.forEach((t: any) => {
    const source = t.split('.')[0];
    const attribute = t.split('.').splice(1).join('.');

    if (filterSourceList.includes(source)) {
      // eslint-disable-next-line
      return;
    }

    const mValue =
      !_isNil(source) &&
      !_isEmpty(source) &&
      !_isNil(attribute) &&
      !_isEmpty(attribute)
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[source].attributes).reduce((acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      dataset[source ?? ''].attributes[`${curr}`].executedValue,
                  };
                }, {})
              )
            ) ?? {},
            attribute ?? ''
          )
        : key;

    mappedValue = mappedValue.replaceAll(
      // eslint-disable-next-line
      `{{.${source}.${attribute}}}`,
      // eslint-disable-next-line
      `${mValue}`
    );
  });

  return mappedValue;
};

export const removeNakedEdges = (
  edges: WorkflowEdgeType[],
  nodes: WorkflowNodeType[]
) => {
  const nodeIds = nodes.map((node) => node.id);

  const newEdges = edges.filter(
    (e) => e.target !== '' && e.source !== '' && nodeIds.includes(e.target)
  );

  return newEdges;
};

export const getBlankConditions = (
  conditionId: string,
  parentId: string = ''
) => {
  const lshId = generateUid('lhs_');

  return {
    [conditionId]: {
      dataType: '',
      operator: 'any',
      leftNode: [lshId],
      rightNode: [],
      parent: parentId,
      nodeType: 'condition',
    },
    [lshId]: {
      dataType: '',
      parent: conditionId,
      nodeType: 'params',
      sourceType: '',
      attribute: '',
    },
  };
};

export const getBlankPath = () => {
  const groupId = generateUid('group_');

  const conditionId = generateUid('condition_');
  const lshId = generateUid('lhs_');

  const examplePath = {
    [groupId]: {
      nodeType: 'group',
      name: 'Path',
      operator: '', // and / or
      children: [conditionId],
    },
    [conditionId]: {
      dataType: '',
      operator: 'any',
      leftNode: [lshId],
      rightNode: [],
      parent: groupId,
      nodeType: 'condition',
    },
    [lshId]: {
      dataType: '',
      parent: conditionId,
      nodeType: 'params',
      sourceType: '',
      attribute: '',
    },
  };

  return examplePath;
};

export const getBlankGroup = (groupId: string, parentId: string) => {
  const conditionId = generateUid('condition_');
  const lshId = generateUid('lhs_');

  const examplePath = {
    [groupId]: {
      nodeType: 'group',
      name: 'Path',
      operator: '', // and / or
      children: [conditionId],
      parent: parentId,
    },
    [conditionId]: {
      dataType: '',
      operator: 'any',
      leftNode: [lshId],
      rightNode: [],
      parent: groupId,
      nodeType: 'condition',
    },
    [lshId]: {
      dataType: '',
      parent: conditionId,
      nodeType: 'params',
      sourceType: '',
      attribute: '',
    },
  };

  return examplePath;
};

export const getBlankRhs = (
  rhsId: string,
  dataType: string,
  parentId: string = ''
) => {
  return {
    dataType,
    nodeType: 'constant',
    sourceType: '',
    attribute: '',
    parent: parentId,
    value: null,
  };
};

export const formatSwitchNode = (data: Record<string, any>) => {
  const paths: Array<Record<string, any>> = [];

  data.switcher?.forEach((s: Record<string, any>, i: number) => {
    const obj: Record<string, any> = {};
    obj[s.pathId] = data.conditions.nodes[s.pathId];
    getPathsFromSwitcher(data.conditions.nodes, obj, s.pathId);

    paths.push(obj);
  });

  const defaultValue = data.input?.defaultPath?.value;

  const name = data.name;
  const settings = data.settings;

  return {
    name,
    settings,
    paths,
    default: defaultValue,
  };
};

export const getPathsFromSwitcher = (
  conditions: Record<string, any>,
  obj: Record<string, any>,
  groupId: string
) => {
  obj[groupId] = conditions[groupId];

  conditions[groupId].children.forEach((c: string) => {
    obj[c] = conditions[c];

    conditions[c]?.leftNode?.forEach((l: string) => {
      obj[l] = conditions[l];
    });

    conditions[c]?.rightNode?.forEach((r: string) => {
      obj[r] = conditions[r];
    });

    if (conditions[c].nodeType === 'group') {
      getPathsFromSwitcher(conditions, obj, c);
    }
  });
};

export const transformSwitchNodePayload = (data: Record<string, any>) => {
  return {
    name: data.name,
    settings: { ...data.settings },
    switcher: getSwitcher(data.paths),
    input: {
      defaultPath: {
        value: data.default,
      },
    },
    conditions: {
      nodes: transformProperties(
        _reduce(
          data.paths,
          (acc, curr) => {
            return {
              ...acc,
              ...curr,
            };
          },
          {}
        )
      ),
    },
  };
};

export const transformProperties = (nodes: Record<string, any>) => {
  return Object.keys(nodes).reduce((acc, curr) => {
    const currentNode = nodes[curr];

    if (!_isNil(currentNode) && currentNode.nodeType === 'constant') {
      const parent = nodes[nodes[curr].parent];

      if (
        parent?.dataType === 'list' ||
        ['containsIn', 'notContainsIn'].includes(parent?.operator ?? '')
      ) {
        const convertedValue = convertCaSampleValues('list', currentNode.value);
        currentNode.value = convertedValue;
      } else {
        let val = convertCaSampleValues(
          currentNode.dataType,
          currentNode.value
        );

        if (
          (['containsIn', 'notContainsIn', 'in', 'nin'].includes(
            parent?.operator ?? ''
          ) ||
            currentNode.dataType === 'list') &&
          typeof currentNode.value === 'string'
        ) {
          val = convertStringToList(currentNode.value);
        }

        currentNode.value = val;
      }

      nodes[curr] = currentNode;

      return {
        ...acc,
        [curr]: currentNode,
      };
    } else if (!_isNil(currentNode) && currentNode.nodeType !== 'constant') {
      return {
        ...acc,
        [curr]: nodes[curr],
      };
    }

    return {
      ...acc,
    };
  }, {});
};

export const getSwitcher = (paths: any[]) => {
  const switcher: Record<string, any> = [];

  paths.forEach((path) => {
    const group = getRuleNodeFromPath(path);

    switcher.push({
      name: group.value?.name,
      pathId: group.key,
    });
  });

  return switcher;
};

export const getRuleNodeFromPath = (path: Record<string, any>) => {
  const groupKey = Object.keys(path).find((key) => {
    return (
      path[key]?.nodeType === 'group' &&
      (path[key]?.parent === '' || _isNil(path[key]?.parent))
    );
  });

  return {
    key: groupKey ?? '',
    value: path[groupKey ?? ''],
  };
};

export const getGroupNodeFromPath = (
  path: Record<string, any>,
  keyName: string
) => {
  const groupKey = Object.keys(path).find((key) => {
    return (
      path[key]?.nodeType === 'group' &&
      path[key]?.parent !== '' &&
      !_isNil(path[key]?.parent) &&
      key === keyName
    );
  });

  return {
    key: groupKey ?? '',
    value: path[groupKey ?? ''],
  };
};

export const getCaseNodesAndEdges = (
  groupIds: string[],
  mergeNodeId: string,
  rootId: string,
  conditions: Record<string, any>
) => {
  const nodes: WorkflowNodeType[] = [];
  const edges: WorkflowEdgeType[] = [];

  groupIds.forEach((pathId) => {
    const addNodeId = generateUid('node_');

    nodes.push({
      id: addNodeId,
      position: {
        x: 0,
        y: 0,
      },
      type: 'addNode',
      data: {
        rootId,
        nodeType: 'addNodeMd',
      },
      targetPosition: Position.Top,
      draggable: false,
    });

    const newEdgeId = generateUid('edge_');

    const newEdge1 = {
      id: newEdgeId,
      source: rootId,
      target: addNodeId,
      animated: false,
      type: 'smoothEdge',
      style: { stroke: 'gray', strokeWidth: 1 },
      data: {
        edgeType: 'case',
        pathId,
      },
    };

    const newEdgeId2 = generateUid('edge_');

    const newEdge2 = {
      id: newEdgeId2,
      source: addNodeId,
      target: mergeNodeId,
      type: 'smoothEdge',
      animated: false,
      style: { stroke: 'gray', strokeWidth: 1 },
      data: {
        edgeType: 'then',
      },
    };

    edges.push(newEdge1, newEdge2);
  });

  return {
    nodesToBeAdded: nodes,
    edgesToBeAdded: edges,
  };
};

export function findNodesAndEdgesBetween(
  startNodeId: string,
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  endNodeId: string,
  edgeIdToSkip?: string // Optional parameter to specify the edge to skip
) {
  // Initialize arrays to store nodes and edges between start and end nodes
  const nodesBetween: WorkflowNodeType[] = [];
  const edgesBetween: WorkflowEdgeType[] = [];

  // Start finding nodes and edges from the start node
  findConnectedNodes(
    startNodeId,
    [],
    [],
    nodes,
    edges,
    nodesBetween,
    edgesBetween,
    endNodeId,
    edgeIdToSkip
  );

  // Return the list of nodes and edges between start and end nodes
  return { nodes: nodesBetween, edges: edgesBetween };
}

// Helper function to recursively find nodes and edges
function findConnectedNodes(
  nodeId: any,
  visitedNodes: string[],
  visitedEdges: string[],
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  nodesBetween: WorkflowNodeType[],
  edgesBetween: WorkflowEdgeType[],
  endNodeId: string,
  edgeIdToSkip?: string // Optional parameter to specify the edge to skip
) {
  // Mark the current node as visited
  visitedNodes.push(nodeId);
  const node = nodes.find((n) => n.id === nodeId);

  if (node != null) {
    nodesBetween.push(node);
  }

  // Find all edges connected to the current node
  const connectedEdges = edges.filter(
    (edge) => edge.source === nodeId && edge.id !== edgeIdToSkip // Skip the edge if it matches edgeIdToSkip
  );

  // Add the connected edges to the list of edges between start and end nodes
  connectedEdges.forEach((edge) => {
    if (!visitedEdges.includes(edge.id)) {
      visitedEdges.push(edge.id);

      if (_isNil(edgesBetween.find((e) => e.id === edge.id))) {
        edgesBetween.push(edge);
      }

      // Determine the next node
      const nextNodeId = edge.source === nodeId ? edge.target : null;

      // If the next node is the end node, add it to the list of nodes between start and end nodes
      if (nextNodeId === endNodeId) {
        // eslint-disable-next-line
        return;
      }
      // If the next node is not visited yet and not the end node, recursively find connected nodes
      else if (!_isNil(nextNodeId) && !visitedNodes.includes(nextNodeId)) {
        findConnectedNodes(
          nextNodeId,
          visitedNodes,
          visitedEdges,
          nodes,
          edges,
          nodesBetween,
          edgesBetween,
          endNodeId,
          edgeIdToSkip
        );
      }
    }
  });
}

export function findPreviousNode(
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  lastNodeId: string,
  startEdgeId: string
): WorkflowNodeType | null {
  // Find the edge from where we need to start
  const startEdge = edges.find((edge) => edge.id === startEdgeId);

  if (startEdge == null) {
    throw new Error(`Edge with id ${startEdgeId} not found`);
  }

  // Start traversing from the target of the start edge
  let currentNodeId = startEdge.target;

  // Traverse through outgoing edges until we reach the lastNodeId
  while (currentNodeId !== lastNodeId) {
    const outgoingEdge = edges.find((edge) => edge.source === currentNodeId);

    if (outgoingEdge == null) {
      return null; // No outgoing edge found, meaning we can't traverse further
    }

    // If the outgoing edge's target is the lastNodeId, we've found the previous node
    if (outgoingEdge.target === lastNodeId) {
      return nodes.find((node) => node.id === currentNodeId) ?? null;
    }

    // Move to the next node
    currentNodeId = outgoingEdge.target;
  }

  return null; // Return null if the previous node isn't found
}

export const findListOfNodesAndEdgesToBeDeleted = (
  groupIds: string[],
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[],
  mergeNodeId: string
) => {
  const nodeIdsToBeDeleted: string[] = [];
  const edgeIdsToBeDeleted: string[] = [];
  groupIds.forEach((pathId) => {
    const edge = edges.find((e) => e.data.pathId === pathId);

    if (!_isNil(edge)) {
      nodeIdsToBeDeleted.push(edge.target);
      edgeIdsToBeDeleted.push(edge.id);
      const itemsToBeDeleted = findNodesAndEdgesBetween(
        edge.target,
        nodes,
        edges,
        mergeNodeId
      );
      itemsToBeDeleted.edges.forEach((e) => {
        edgeIdsToBeDeleted.push(e.id);
      });
      itemsToBeDeleted.nodes.forEach((n) => {
        nodeIdsToBeDeleted.push(n.id);
      });
    }
  });

  return {
    nodeIdsToBeDeleted,
    edgeIdsToBeDeleted,
  };
};

export const getExecutedValueByName = (
  dataset: Record<string, Dataset>,
  keysToIgnore = ['systemVar', 'globalVar']
) => {
  const values: Record<string, any> = {};
  Object.keys(dataset)
    .filter((key) => !keysToIgnore.includes(key))
    .forEach((key) => {
      values[key] = Object.keys(dataset[key].attributes).reduce((acc, curr) => {
        const currentItem = dataset[key].attributes[curr].executedValue;

        return {
          ...acc,
          [curr]: currentItem,
        };
      }, {});
    });

  return values;
};

export const getIdsToBeRemovedForGroup = (
  condId: string,
  conditions: Record<string, any>,
  ids: string[]
) => {
  ids.push(condId);

  if (conditions[condId].nodeType === 'group') {
    conditions[condId].children?.forEach((c: string) => {
      ids.push(c);

      if (conditions[condId].nodeType === 'condition') {
        conditions[c].leftNode?.forEach((l: string) => {
          ids.push(l);
        });

        conditions[c].rightNode?.forEach((r: string) => {
          ids.push(r);
        });
      } else {
        getIdsToBeRemovedForGroup(c, conditions, ids);
      }
    });
  } else {
    conditions[condId].leftNode?.forEach((c: string) => {
      ids.push(c);
    });

    conditions[condId].rightNode?.forEach((c: string) => {
      ids.push(c);
    });
  }
};

export const getConditionLength = (path: Record<string, any>) => {
  return Object.keys(path).reduce((acc, curr) => {
    if (
      ['condition', 'jsCondition', 'excelCondition'].includes(
        path[curr]?.nodeType
      )
    ) {
      return acc + 1;
    }

    return acc;
  }, 0);
};

export const getEntityNameById = (str: string) => {
  switch (str) {
    case 'srNode':
      return 'simpleRule';
    case 'dtNode':
      return 'decisionTable';
    case 'ruleSetNode':
      return 'ruleSet';
  }

  return '';
};

function validateAndConvertColumnList(
  list: Array<Record<string, string>>,
  schema: Record<string, any>,
  hasHeader: boolean
) {
  return list.map((item) => {
    const newItem: Record<string, any> = {};
    for (const key in schema) {
      const columnName = hasHeader ? schema[key].headerColumnName : key;
      const value = item[columnName];

      if (
        !_isUndefined(value) &&
        typeof value === 'string' &&
        !value.includes('{{.') &&
        !value.includes('<<NECTED')
      ) {
        // Check data type and convert if necessary
        switch (schema[key].dataType) {
          case 'numeric':
            newItem[columnName] = isNaN(Number(value)) ? value : Number(value);
            break;
          case 'boolean':
            newItem[columnName] = value.toLowerCase() === 'true';
            break;
          case 'string':
            newItem[columnName] = String(value);
            break;
          // Add more cases for other data types if needed
          default:
            newItem[columnName] = value;
        }
      } else {
        newItem[columnName] = value;
      }
    }

    if (!_isNil(item.ROW_NUMBER)) {
      newItem.ROW_NUMBER = isNaN(Number(item.ROW_NUMBER))
        ? item.ROW_NUMBER
        : Number(item.ROW_NUMBER);
    }

    return newItem;
  });
}

export const transformGSheetData = (
  data: any,
  dataSet: Record<string, Dataset>
) => {
  const file = { value: data.file };
  const sheet = { value: data.sheet };

  const hasHeader = { value: data.hasHeader?.value };
  const columnData = { value: data.columnData };
  const columnMatch = { value: data.columnMatch?.map((c: any) => c.value) };

  const policy = { value: data.policy?.value };

  const mappingMethod = { value: data.mappingMethod?.value };

  let filterList: Record<string, any> = { value: null };
  filterList = { ...(data.filterList ?? {}), dataType: 'list' };

  if (data.mappingMethod?.value !== 'manual') {
    filterList = { ...(data.filterList ?? {}), dataType: 'list' };
  } else {
    const strValue = JSON.stringify(data.filterList?.value ?? null);

    const sString = sanitizedStringV2(
      strValue,
      dataSet,
      sourceTypesToIgnoreOnValueReplacement
    );

    try {
      // eslint-disable-next-line
      const finalValue = JSON.parse(sString);

      if (!_isNil(finalValue[0])) {
        Object.keys(finalValue[0]).forEach((k) => {
          if (hasHeader.value !== 'yes') {
            if (
              columnData.value[k]?.dataType === 'numeric' &&
              Number.isNaN(parseFloat(finalValue[0][k]))
            ) {
              try {
                finalValue[0][k] = parseFloat(finalValue[0][k]);
              } catch {}
            } else if (columnData.value[k]?.dataType === 'boolean') {
              finalValue[0][k] = finalValue[0][k] === 'true';
            }
          } else {
            const key = Object.keys(columnData.value).find(
              (ke) =>
                columnData.value[ke]?.headerColumnName === k ||
                columnData.value[ke]?.columnName === k
            );
            // eslint-disable-next-line
            if (!!key) {
              const hKey = columnData.value[key].headerColumnName;

              if (
                // eslint-disable-next-line
                columnData.value[key]?.dataType === 'numeric' &&
                // eslint-disable-next-line
                !Number.isNaN(parseFloat(finalValue[0][k]))
              ) {
                try {
                  finalValue[0][hKey] = parseFloat(finalValue[0][hKey]);
                } catch {}
              } else if (columnData.value[key]?.dataType === 'boolean') {
                finalValue[0][hKey] = finalValue[0][hKey] === 'true';
              }
            }
          }
        });
      }

      if (!_isNil(finalValue[0].ROW_NUMBER)) {
        finalValue[0].ROW_NUMBER = isNaN(Number(finalValue[0].ROW_NUMBER))
          ? finalValue[0].ROW_NUMBER
          : Number(finalValue[0].ROW_NUMBER);
      }
      filterList.executedValue = finalValue;
    } catch {}

    const newValue = validateAndConvertColumnList(
      filterList.value ?? [],
      columnData.value,
      hasHeader.value === 'yes'
    );

    filterList.value = newValue;
  }
  const name = data.name;

  const entityId = data.integration?.value;

  const actionMethod = { value: data.actionMethod?.value };
  let timeout = 30;

  try {
    timeout = parseInt(data.settings.timeout);
  } catch {}

  const settings = {
    cacheEnabled: data.settings.cacheEnabled,
    durationUnit: data.settings.durationUnit.value,
    durationValue: parseInt(data.settings.durationValue),
    rowLimit: parseInt(data.settings.rowLimit),
    timeout,
    errorContinue: data.settings.errorContinue === true,
  };

  const filterConditions: Record<string, any> = {
    value: {
      operator: '',
      conditions: [],
    },
    dataType: 'json',
  };

  if (actionMethod.value === 'lookup') {
    const groupNode = getRuleNodeFromPath(data.paths[0]);

    filterConditions.value.operator = data.paths[0][groupNode.key]?.operator;
    filterConditions.value.conditions = data.paths[0][
      groupNode.key
    ]?.children?.map((c: string) => {
      const currentCond = data.paths[0][c];

      const finalObject: Record<string, any> = {
        columnName: '',
        operator: currentCond?.operator,
        value: '',
        dataType: currentCond?.dataType,
      };

      for (let i = 0; i < currentCond?.rightNode?.length; i++) {
        const currentRhs = data.paths[0][currentCond?.rightNode[i]];

        finalObject[i === 0 ? 'value' : `value${i}`] =
          currentRhs?.nodeType === 'params'
            ? // eslint-disable-next-line
              `{{.${currentRhs.sourceType}.${currentRhs?.attribute}}}`
            : convertCaSampleValues(currentRhs.dataType, currentRhs?.value);
      }

      for (let i = 0; i < currentCond?.leftNode?.length; i++) {
        const currentLhs = data.paths[0][currentCond?.leftNode[i]];

        finalObject.columnName = currentLhs.attribute;
      }
      filterConditions.value.conditions.push(finalObject);

      return finalObject;
    });

    const strValue = JSON.stringify(filterConditions?.value ?? null);

    const sString = sanitizedStringV2(
      strValue,
      dataSet,
      sourceTypesToIgnoreOnValueReplacement
    );

    try {
      // eslint-disable-next-line
      const finalValue = JSON.parse(sString);
      finalValue.conditions = finalValue.conditions.map(
        (cond: Record<string, any>) => {
          if (hasHeader.value === 'yes') {
            const key = Object.keys(columnData.value).find(
              (ke) =>
                columnData.value[ke]?.headerColumnName === cond.columnName ||
                columnData.value[ke]?.columnName === cond.columnName
            );

            // eslint-disable-next-line
            if (!!key) {
              const dataType = columnData.value[key].dataType;

              if (
                dataType === 'numeric' &&
                // eslint-disable-next-line
                !Number.isNaN(parseFloat(cond.value))
              ) {
                try {
                  cond.value = parseFloat(cond.value);
                } catch {}
              } else if (dataType === 'boolean') {
                cond.value = cond.value === 'true';
              }
            }
          } else {
            const dataType = columnData.value[cond.columnName].dataType;

            if (
              dataType === 'numeric' &&
              // eslint-disable-next-line
              !Number.isNaN(parseFloat(cond.value))
            ) {
              try {
                cond.value = parseFloat(cond.value);
              } catch {}
            } else if (dataType === 'boolean') {
              cond.value = cond.value === 'true';
            }
          }

          return cond;
        }
      );

      filterConditions.executedValue = finalValue;
    } catch {}
  }

  return {
    input: {
      file,
      sheet,
      hasHeader,
      columnMatch,
      policy,
      mappingMethod,
      filterList,
      filterConditions,
      actionMethod,
      columnData,
    },
    name,
    entityId,
    conditions: { nodes: data.paths[0] ?? {} },
    settings,
  };
};

export const convertCurrentNodeAttributesToDataset = (
  attributes: WorkflowAttribute[],
  dataSet: Record<string, Dataset>
) => {
  if (_isNil(attributes) || _isEmpty(attributes)) {
    return {};
  }

  const attributeDataset: Record<string, Dataset> = {
    currentNode: {
      name: 'Current Node',
      id: `currentNode_${generateUUID()}`,
      attributes: _reduce(
        attributes,
        (finalAttrObj: Record<string, Attributes>, currAttr) => {
          const { keyName, source, dataType, attribute, executedValue } =
            currAttr;
          let mappedValue: any = executedValue;

          if (source === 'currentNode') {
            const currentNodeAttribute = attributes.filter(
              (attr) => attr.keyName === attribute
            );

            if (
              !_isNil(currentNodeAttribute) &&
              !_isEmpty(currentNodeAttribute)
            ) {
              mappedValue = currentNodeAttribute[0].executedValue;
            }
          }

          if (
            !_isNil(source) &&
            !_isEmpty(source) &&
            source !== 'currentNode' &&
            !_isNil(attribute) &&
            !_isEmpty(attribute)
          ) {
            mappedValue = getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSet[source]?.attributes ?? {}).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSet[source].attributes[`${curr}`].executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute ?? ''
            );
          }

          const dataTypeMappedValue = getDataTypeNected(mappedValue);

          return {
            ...finalAttrObj,
            [keyName]: {
              id: keyName,
              name: keyName,
              dataType: (['jsFormula', 'excelFormula'].includes(dataType)
                ? dataTypeMappedValue
                : dataType) as DataTypes,
              executedValue: mappedValue,
            },
          };
        },
        {}
      ),
    },
  };

  return attributeDataset;
};

export const updateDatasetWithCurrentNode = (
  dataset: Record<string, Dataset>,
  attributes: Record<string, WorkflowAttribute>
) => {
  return {
    ...dataset,
    currentNode: {
      name: 'Current Node',
      id: `currentNode_${generateUUID()}`,
      attributes: _reduce(
        attributes,
        (finalAttrObj: Record<string, Attributes>, currAttr) => {
          const { keyName, source, dataType, attribute, executedValue } =
            currAttr;
          let mappedValue: any = executedValue;

          if (source === 'currentNode') {
            const currentNodeAttribute = attributes[attribute ?? ''];

            if (
              !_isNil(currentNodeAttribute) &&
              !_isEmpty(currentNodeAttribute)
            ) {
              mappedValue = currentNodeAttribute.executedValue;
            }
          }

          if (
            !_isNil(source) &&
            !_isEmpty(source) &&
            source !== 'currentNode' &&
            !_isNil(attribute) &&
            !_isEmpty(attribute)
          ) {
            mappedValue = getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataset[source]?.attributes ?? {}).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataset[source].attributes[`${curr}`].executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute ?? ''
            );
          }

          const dataTypeMappedValue = getDataTypeNected(mappedValue);

          return {
            ...finalAttrObj,
            [keyName]: {
              id: keyName,
              name: keyName,
              dataType: (['jsFormula', 'excelFormula'].includes(dataType)
                ? dataTypeMappedValue
                : dataType) as DataTypes,
              executedValue: mappedValue,
            },
          };
        },
        {}
      ),
    },
  };
};

export const transformLoopNodeData = (data: Record<string, any>) => {
  let timeout: number | null = null;

  try {
    timeout = +data.settings.timeout;
  } catch (error) {
    timeout = null;
  }

  return {
    name: data.name,
    settings: {
      timeout,
      errorContinue: data.settings.errorContinue === true,
    },
    input: {
      inputValue: {
        ...data.input.inputValue,
        value: convertCaSampleValues('list', data.input.inputValue.value),
      },
      chunkSize: {
        value: convertCaSampleValues('numeric', data.input.chunkSize.value),
      },
      concurrency: {
        value: convertCaSampleValues('numeric', data.input.concurrency.value),
      },
    },
  };
};
