import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useRef } from 'react';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  RulePopover,
  Typography,
} from 'ui';

import { StyledLink } from '../../../pages/Workflow/components/Sheets/LoopNodeSheet/LoopNodeTest.styled';

/* eslint-disable-next-line import/no-absolute-path */
import emptyListImg from '/assets/konark/images/emptyList2.svg';

export type onClickTokenSelectionArgs = {
  schemaName?: string;
  moduleName?: string;
  dataType: string;
  schemaId?: string;
};

type PredefineTokenPopoverProps = {
  dataset: Record<string, Dataset>;
  onTokenSelection?: (data: onClickTokenSelectionArgs) => void;
  showMultiSelect?: boolean;
  selectedCheckboxData?: Record<string, OnClickRuleArgs>;
  setSelectedCheckboxData?: (data: Record<string, OnClickRuleArgs>) => void;
};
export const PredefineTokenPopover = ({
  dataset,
  onTokenSelection,
  showMultiSelect = false,
  selectedCheckboxData,
  setSelectedCheckboxData,
}: PredefineTokenPopoverProps) => {
  const ref = useRef<PopoverMethods>(null);

  return (
    <RulePopover
      dataset={dataset}
      allowList={true}
      placeholder={'Search in attributes'}
      version="v2"
      showMultiSelect={showMultiSelect}
      selectedCheckboxData={selectedCheckboxData}
      setSelectedCheckboxData={setSelectedCheckboxData}
      footer={
        Object.keys(dataset).length === 0 ? (
          <PadBox padding={['2rem']}>
            <Stack as={Inline} align="center" gap={16}>
              <img
                src={emptyListImg}
                alt="empty-list-module"
                style={{ inlineSize: '12rem' }}
              />
              <Inline gutter="0.5rem">
                <Typography>Create Groups in</Typography>
                <StyledLink to={'/module'}>Attribute Library</StyledLink>
                <Typography>to easily use defined values</Typography>
              </Inline>
            </Stack>
          </PadBox>
        ) : (
          false
        )
      }
      onClick={({ value, key, dataType }) => {
        if (typeof onTokenSelection === 'function') {
          let schemaId;
          Object.keys(dataset[key].attributes).forEach((attrKey) => {
            if (attrKey === value) {
              schemaId = dataset[key].attributes[attrKey].id;
            }
          });
          onTokenSelection({
            schemaId,
            moduleName: key,
            schemaName: value,
            dataType,
          });
        }
        ref.current?.hide();
      }}
    />
  );
};
