import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef } from 'react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import {
  type Dataset,
  Image,
  Menu,
  MenuItem,
  PopoverMethods,
  TextButton,
  TextField,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { publishedConnectorsAtom } from '../../../../../components/CreateAction/CreateAction';
import {
  convertCaSampleValues,
  getTooltipText,
} from '../../../../../utils/common';
import { envMap } from '../../../../../utils/constant';
import { useGetPublishedConnectors } from '../../../../DataSets/hooks/useGetPublishedConnectors';
import { PublishedConnectors } from '../../../../Rules/components/DecisionTable/types';
import type { AttributeModel } from '../../../../Rules/models';
import { getDefaultValueByDataTypeForRhsOutput } from '../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';
import { SetVariableNodeForm, WorkflowAttribute } from '../../../models/models';
import { convertCurrentNodeAttributesToDataset } from '../../../utils/common';
import { Selector } from '../RuleSheet/Selector/Selector';
import {
  AddFieldStyled,
  StyledTextContainer,
} from './SetVariableMapping.styled';

type SetVariableMappingProps = {
  control?: Control<SetVariableNodeForm>;
  setValue: UseFormSetValue<any>;
  dataSet?: Record<string, Dataset>;
  watch: UseFormWatch<any>;
  customInputs?: Record<string, AttributeModel>;
  idsToNotExpand?: string[];
};

export function SetVariableMapping({
  control,
  setValue,
  dataSet = {},
  watch,
  customInputs = {},
  idsToNotExpand = [],
}: SetVariableMappingProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const menuRef = useRef<PopoverMethods>(null);

  const attributes: WorkflowAttribute[] = useWatch({
    name: 'attributes',
    control,
  });

  const { data } = useGetPublishedConnectors(false);
  const [, setPublishConnectors] = useAtom(publishedConnectorsAtom);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data;

      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [data]);

  const menuItems = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'numeric', label: 'Numeric' },
    { value: 'string', label: 'String' },
    { value: 'date', label: 'Date' },
    { value: 'dateTime', label: 'DateTime' },
    { value: 'list', label: 'List' },
    {
      value: 'json',
      label: 'JSON',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJsonRule'),
    },
    {
      value: 'jsFormula',
      label: 'JS Code',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJSRule'),
    },
    {
      value: 'excelFormula',
      label: 'Formula',
      tooltip: getTooltipText(siteConstants, 'rules', 'customExcelRule'),
    },
  ];

  const handleAddDataParam = (dataType: string) => {
    if (attributes.length < 10) {
      setValue('attributes', [
        ...attributes,
        {
          keyName: `key_${attributes.length}`,
          dataType,
          returnType: '',
          executedValue: convertCaSampleValues(
            dataType,
            getDefaultValueByDataTypeForRhsOutput(dataType)
          ),
          value: getDefaultValueByDataTypeForRhsOutput(dataType),
        },
      ]);
    } else {
      toasts.info(
        'You have reached the limit to set Variables',
        'max-set-variables'
      );
    }

    menuRef.current?.hide();
  };

  const handleDeleteElement = (index: number) => {
    const updatedAttributes = attributes.filter((attr, i) => i !== index);

    setValue('attributes', updatedAttributes);
  };

  const addFieldLauncher = (
    <AddFieldStyled $disabled={isWorkflowReadOnly} align="center" gutter={5}>
      {isWorkflowReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add Field</Typography>
    </AddFieldStyled>
  );

  return (
    <Stack gutter="1.5rem">
      <Menu
        ref={menuRef}
        launcher={addFieldLauncher}
        onMenuItemClick={(value) => {
          handleAddDataParam(value);
        }}
        disabled={isWorkflowReadOnly}
      >
        {menuItems.map((menuItem) => (
          <MenuItem value={menuItem.value} key={menuItem.value}>
            <Inline align="center" gutter={8}>
              <Typography>{menuItem.label}</Typography>

              {!_isNil(menuItem.tooltip) && (
                <TooltipReact id={`dataType-${menuItem.value}`}>
                  <Typography>{menuItem.tooltip}</Typography>
                </TooltipReact>
              )}
            </Inline>
          </MenuItem>
        ))}
      </Menu>

      {attributes.length > 0 && (
        <Inline align="center">
          <StyledTextContainer>
            <Typography>Key Name</Typography>
          </StyledTextContainer>

          <StyledTextContainer>
            <Typography>Value</Typography>
          </StyledTextContainer>
        </Inline>
      )}

      {attributes?.map((currAttribute, index: number) => {
        const currentNodeDataset = convertCurrentNodeAttributesToDataset(
          attributes.slice(0, index),
          dataSet
        );

        return (
          <Inline key={index} align="center">
            <StyledTextContainer>
              <TextField
                name={`attributes.${index}.keyName`}
                control={control}
                disabled={isWorkflowReadOnly}
                showErrorIcon={false}
              />
            </StyledTextContainer>

            <StyledTextContainer>
              <Selector
                control={control}
                setValue={setValue}
                keyName={currAttribute.keyName}
                nodeName={`attributes.${index}`}
                dataType={currAttribute.dataType ?? 'string'}
                dataSet={{ ...dataSet, ...currentNodeDataset }}
                index={index}
                key={`attributes.${index}`}
                customInputs={customInputs}
                showKeyName={false}
                showPillOnly
                idsToNotExpand={idsToNotExpand}
              />
            </StyledTextContainer>

            <TextButton
              disabled={isWorkflowReadOnly}
              onClick={() => handleDeleteElement(index)}
            >
              <Image
                src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
                alt="minus"
                size="small"
              />
            </TextButton>
          </Inline>
        );
      })}
    </Stack>
  );
}
