import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';
import { TbDownload } from 'react-icons/tb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../../../atom';
import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { updateWidgetState } from '../../../../pages/Home/components/sub-components/UpdateWidgetState';
import { useGenrateEntityId } from '../../../../pages/Home/components/sub-components/useGenerateEntityId';
import { useGetDemoEntity } from '../../../../pages/Home/hooks/useGetHomePageData';
import {
  checkLimitExceeded,
  isOnboardingCompleted,
} from '../../../../utils/common';
import { demoWorkflowCreated, envMap } from '../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../utils/validation';
import { ImportEntityModal } from '../../../Modals/ImportEntityModal/ImportEntityModal';
import { Threshold } from '../../../Threshold/Threshold';
import { StackAsItem } from '../../../layouts/Stack.styled';
import { CustomClose, RuleTemplateFooterContainer } from '../../listing.styled';
import { useCreateWorkflow } from '../hooks/graphql/useCreateWorkflow';
import { DemoObjProps, StartFromTemplate } from './StartFromTemplate';

type CreateWorkflowFormProps = {
  control: Control<any>;
};

type WorkflowTemplatePopupProps = {
  title: string;
  type: 'WORKFLOW_TEMPLATE';
  selectedTab: number;
  subModules?: Record<string, boolean>;
};
const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export const WorkflowTemplatePopup = ({
  title,
  type,
  selectedTab,
  subModules,
}: WorkflowTemplatePopupProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState(selectedTab ?? 0);
  const [searchedText, setSearchedText] = useState('');

  const [demoWorkflow, setDemoWorkflow] = useState<DemoObjProps[]>([]);
  const [selectedDemoObj, setSelectedDemoObj] = useState<DemoObjProps | null>(
    null
  );

  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);

  const { control, watch, setError } = useForm<Record<string, string>>({
    mode: 'onChange',
  });

  const { close: closePopup } = useCurrentLayer();
  const { sendEventToGTM } = useSendEventToGTM();

  const { generateEntityId } = useGenrateEntityId();
  const [getDemoEntityData, { data: demoWorkflowData }] = useGetDemoEntity();

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="workflow" from="import_list" />
  );

  const [createWorkflow] = useCreateWorkflow();

  const formValues = watch();

  useEffect(() => {
    if (deploymentType !== 'onpremise') {
      void getDemoEntityData({
        variables: {
          filters: {
            eq: { ref_entity: 'workflow' },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_isNil(demoWorkflowData)) {
      setDemoWorkflow(demoWorkflowData.getDemoUseCases.data);
    }
  }, [demoWorkflowData]);

  useEffect(() => {
    if (searchedText === '') {
      if (!_isNil(demoWorkflowData)) {
        setDemoWorkflow(demoWorkflowData.getDemoUseCases.data);
      }
    } else {
      if (!_isNil(demoWorkflowData)) {
        const searchedData = demoWorkflowData.getDemoUseCases.data.filter((i) =>
          i.name.toLocaleLowerCase().includes(searchedText.toLocaleLowerCase())
        );
        setDemoWorkflow(searchedData);
      }
    }
  }, [searchedText]);

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const customClosePopup = () => {
    sendEventToGTM({
      event: 'lb_popup_closed',
      action: 'click',
      type,
      title,
    });
    closePopup();
  };

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoWorkflowCreated)) {
      updateWidgetState(demoWorkflowCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const handleINeedAssistance = () => {
    sendEventToGTM({
      event: 'lb_pf_ina',
      action: 'click',
      from: `tab_${currentTab}`,
    });
  };

  const handleCreateWorkflow = async () => {
    if (_isNil(formValues.useCaseName) || _isEmpty(formValues.useCaseName)) {
      setError('useCaseName', { message: 'Workflow name is required.' });

      return;
    } else if (formValues.useCaseName.length > maxFiftyCharactersRule.value) {
      setError('useCaseName', { message: maxFiftyCharactersRule.message });

      return;
    }

    try {
      const payload: Record<string, any> = {
        name: formValues.useCaseName,
        description: formValues.workflowDescripiton ?? '',
        nodes: [
          {
            id: '1',
            type: 'addNode',
            data: {
              nodeType: 'addNodeLg',
              settings: {
                isEnabled: true,
                authType: 'none',
              },
            },
            position: { x: 0, y: 0 },
            sourcePosition: 'bottom',
            draggable: false,
          },
        ],
        edges: [],
        startNode: '',
        isEnabled: true,
        settings: {},
        trigger: {},
      };
      const response = await createWorkflow({
        variables: { ...payload },
      });

      const workflowURL = `/workflow/ID?type=edit&stage=staging&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;
      customClosePopup();

      navigate(workflowURL.replace('ID', response.data.createWorkflow.id));
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }
  };

  const handleCreateDemoWorkflow = () => {
    if (!_isNil(selectedDemoObj)) {
      let workflowURL = `/workflow/ID?type=edit&stage=staging&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;

      if (!_isNil(searchParams.get('step'))) {
        workflowURL = `${workflowURL}&step=${
          searchParams.get('step') as string
        }`;
      }

      const entityId = selectedDemoObj?.data?.entityId;

      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        sendEventToGTM({
          event: 'lb_demo_workflow_open',
          action: 'click',
          type: selectedDemoObj.category,
          title,
          entityId,
        });
        navigate(workflowURL.replace('ID', entityId));
      } else {
        sendEventToGTM({
          event: 'lb_demo_workflow_created',
          action: 'click',
          type: selectedDemoObj.category,
          title,
          entityId: selectedDemoObj.id,
        });
        void updateUserState();
        void createEntityAndOpen(selectedDemoObj.id, workflowURL, '_self');
      }
    }
  };

  const handleFooterClose = () => {
    if (currentTab === 1) {
      handleCreateDemoWorkflow();
    } else {
      void handleCreateWorkflow();
    }
  };

  const RenderPopupTabs = useMemo(() => {
    const tabs = ['Build your own', 'Start from template'];
    let updatedTabs = tabs;

    if (deploymentType === 'onpremise') {
      updatedTabs = tabs.filter((i) => i !== 'Start from template');
    }

    return updatedTabs.map((tab, index) => {
      return <Tab key={index}>{tab}</Tab>;
    });
  }, [deploymentType]);

  return (
    <Modal hideCloseButton={true} size="extraLargeXS">
      <CustomClose>
        <Button
          onClick={() => openImportModal({})}
          trailingIcon={<TbDownload />}
          appearance="filled"
        >
          Import
        </Button>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'1.5rem'}>
          <Typography name="heading2">{title} </Typography>
          <StackAsItem>
            <Tabs onTabChange={setCurrentTab} defaultOpen={currentTab}>
              <TabList>{RenderPopupTabs}</TabList>
              <TabPanels>
                <TabPanel>
                  <CreateWorkflowForm control={control} />
                </TabPanel>
                <TabPanel>
                  <StartFromTemplate
                    selectDemoEntity={setSelectedDemoObj}
                    selectedDemoObj={selectedDemoObj}
                    demoEntity={demoWorkflow}
                    searchedText={searchedText}
                    setSearchedText={setSearchedText}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </StackAsItem>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <RuleTemplateFooterContainer>
          {deploymentType !== 'onpremise' && (
            <Button
              appearance="neutral"
              onClick={handleINeedAssistance}
              id="i_need_assistance_list_popup"
            >
              <img src={`${envMap.VITE_ASSETS_URL}home/assist_small.png`} />I
              need assistance
            </Button>
          )}

          <Inline align="center">
            <Threshold
              entity="workflow"
              current={subscriptionPlan?.usage.workflow}
              max={subscriptionPlan?.plan.workflowLimit}
            />
            <Button
              disabled={checkLimitExceeded('workflow', subscriptionPlan)}
              onClick={handleFooterClose}
            >
              Next
            </Button>
          </Inline>
        </RuleTemplateFooterContainer>
      </ModalFooter>
    </Modal>
  );
};

const CreateWorkflowForm = ({ control }: CreateWorkflowFormProps) => {
  return (
    <>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Workflow Name</Typography>
        <TextField
          size="medium"
          name="useCaseName"
          rules={{
            required: {
              value: true,
              message: 'Required Field',
            },
            maxLength: maxFiftyCharactersRule,
          }}
          showErrorIcon={false}
          control={control}
          placeholder="Enter name"
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Typography name="heading4">Workflow Description (Optional)</Typography>
        <TextAreaField
          isDefaultOutlineVisible={true}
          isShowingEllipsis={true}
          control={control}
          name="workflowDescripiton"
          placeholder="Enter description"
          size="medium"
        />
      </Stack>
    </>
  );
};
