import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import {
  predefineTokenDatasetAtom,
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../atom';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import type { UsedConnectorMappingInEntityType } from '../../../types';
import {
  convertModuleDataIntoDataset,
  getTooltipText,
  handleSetCheckSumByEntityName,
  unionStringArrays,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { useGetAllModuleAndSchema } from '../../DataSets/hooks/graphql/useGetAllModuleAndSchema';
import type { Role } from '../../Workspace/component/types';
import {
  firstCustomAttributeAtom,
  isRulePublishableAtom,
  isRulePublishedAtom,
} from '../components/CreateRuleSheet/CreateRuleSheet';
import type {
  ProductionConfigModel,
  ResultAddDataModel,
  RuleResponse,
  SimpleRuleNodesModel,
  StagingConfigModel,
} from '../components/SimpleRule/models';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../components/atom/atom';
import { ruleInitialState } from '../fixtures/ruleInitialState';
import {
  dataSetFieldsByIdAtom,
  isRuleReadOnlyAtom,
  ruleWarningsAtom,
} from '../index';
import type { AttributeModel } from '../models';
import type { ResultAction } from '../types';
import {
  formatTriggersDataFromResponse,
  getDataSetUsedInRuleById,
  transformActionNode,
  transformPayloadCustomInputToTableData,
  transformPayloadToResultDataType,
} from '../utils/common';
import { useDiscardSimpleRule } from './graphql/useDiscardSimpleRule';
import { useGetDataSetById } from './graphql/useGetDataSetById';
import { useGetRuleById } from './graphql/useGetRuleById';
import { useCIWarning } from './useCIWarning';

type UsePrefillSimpleRuleProp = {
  isClone: boolean;
  isLive: boolean;
  ruleId?: string;
  ruleIdExist?: boolean;
  isLiveNodeSheetClosed?: boolean;
  commitId?: string;
};

function getAuthType(editRuleData: RuleResponse) {
  return editRuleData.settings.api.isPrivate
    ? { label: 'Private', value: 'private' }
    : { label: 'None', value: 'none' };
}

export function useEditSimpleRule({
  ruleId,
  isClone,
  isLive,
  ruleIdExist = false,
  isLiveNodeSheetClosed = false,
  commitId,
}: UsePrefillSimpleRuleProp) {
  const [searchParams] = useSearchParams();

  const version = searchParams.get('version') ?? '';

  const [getRuleById, { data: editRuleData, loading: ruleLoading }] =
    useGetRuleById();

  const [discardRuleById, { data: discardRule, error: discardError }] =
    useDiscardSimpleRule();
  const [getDataSetById] = useGetDataSetById();
  const { handleSetWarning } = useCIWarning();

  const [getAllModuleAndSchema] = useGetAllModuleAndSchema();

  const [nodesStartId, setNodesStartId] = useState('');
  const [stagingConfig] = useState<StagingConfigModel>();
  const [productionConfig, setProductionConfig] =
    useState<ProductionConfigModel>();

  const [isMounted, setIsMounted] = useState(false);

  const [selectedDataSets, setSelectedDataSet] = useState<string[]>([]);
  const [thenActionResponse, setThenActionResponse] = useState<ResultAction[]>(
    []
  );
  const [elseActionResponse, setElseActionResponse] = useState<ResultAction[]>(
    []
  );

  const [createdAt, setCreatedAt] = useState<string>();
  const [publishedAt, setPublishedAt] = useState<string>();
  const [status, setStatus] = useState('');
  const [accessRole, setAccessRole] = useState<Role>();

  const [nodeList, setNodeList] = useState<
    Record<string, SimpleRuleNodesModel>
  >(structuredClone(ruleInitialState));
  const [ruleName, setRuleName] = useState('Untitled');
  const [ruleDescription, setRuleDescription] = useState('');
  const [ruleVersion, setRuleVersion] = useState('draft');
  const [loadingData, setLoadingData] = useState(true);
  const [dataClone, setDataClone] = useState(false);

  const [thenDataParams, setThenDataParams] = useState<ResultAddDataModel[]>(
    []
  );
  const [elseDataParams, setElseDataParams] = useState<ResultAddDataModel[]>(
    []
  );
  const [customDataParams, setCustomDataParams] = useState<
    Record<string, AttributeModel>
  >({});

  const [sheetMode, setSheetMode] = useState('');

  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setIsRulePublished] = useAtom(isRulePublishedAtom);
  const [, setDataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [, setFirstCustomAttribute] = useAtom(firstCustomAttributeAtom);
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setVersionMappingInfo] = useAtom(versionMappingInfoAtom);

  const [, setDatasetDetailsInRule] = useAtom(datasetDetailsInRuleAtom);
  const [, setUsedConnectorMappingInRules] = useAtom(usedConnectorMappingAtom);
  const [, setPredefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const [siteConstant] = useAtom(siteConstantsAtom);

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
  });

  const isEditSheetDisable = sheetMode === 'view' || editDisable;

  useEffect(() => {
    setDataClone(isClone);
  }, [isClone]);

  useEffect(() => {
    void handleGetModuleAndSchemaData();
  }, []);

  const handleGetModuleAndSchemaData = async () => {
    const response = await getAllModuleAndSchema({
      variables: {
        page: 1,
        perPage: 20,
      },
      fetchPolicy: 'no-cache',
    });

    const moduledata = response?.data?.getModule?.data;

    if (!_isNil(moduledata)) {
      const tokenDataset = convertModuleDataIntoDataset(moduledata);

      setPredefineTokenDataset(tokenDataset);
    }
  };

  const handleGetRuleById = async () => {
    try {
      const filters: Record<string, any> = {};

      if (!_isNil(commitId) && !_isEmpty(commitId)) {
        filters.eq = { commitId };
      }

      if (!_isNil(version) && !_isEmpty(version)) {
        filters.eq = { version };
      }

      const response = await getRuleById({
        variables: { id: ruleId, live: isLive, filters },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  const handleGetRuleAfterStateTransition = async () => {
    try {
      const response = await getRuleById({
        variables: { id: ruleId },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  useEffect(() => {
    setSheetMode(searchParams.get('type') ?? '');
  }, []);

  useEffect(() => {
    if (_isNil(ruleId)) {
      setNodesStartId('rule_01');
    } else if (ruleIdExist) {
      void handleGetRuleById();
    }
  }, [ruleId, ruleIdExist]);

  const initialize = async (ruleData?: RuleResponse) => {
    if (!_isNil(ruleData)) {
      setLoadingData(true);
      setNodeList(ruleData.conditions.nodes);
      setNodesStartId(ruleData.conditions.startNode);
      setRuleName(`${ruleData.name}${isClone ? '_copy' : ''}`);
      setRuleDescription(ruleData.description);
      setCreatedAt(ruleData.createdAt);
      setPublishedAt(ruleData.publishedAt);
      setAccessRole(ruleData.accessRole);
      setStatus(ruleData.status);
      setApprovalInfoRule(ruleData.approvalInfo);
      setVersionInfoRule(ruleData.versionInfo);
      setDatasetDetailsInRule(ruleData.datasetDetail);
      setVersionMappingInfo(
        ruleData.dependencyMap?.map((currMapping) => ({
          entityId: currMapping.id,
          type: currMapping.type,
          version: currMapping.version,
          nodeId: currMapping.nodeId,
        })) ?? []
      );

      setIsRuleLive(ruleData.isLive ?? false);

      if (!_isNil(ruleData.version) && !dataClone) {
        setRuleVersion(ruleData.version);
      }

      const usedConnectorMapping: UsedConnectorMappingInEntityType = {};

      if (!_isNil(ruleData.datasetDetail)) {
        usedConnectorMapping[ruleData.datasetDetail.connector.id] = {
          status: true,
          source: ['dataset'],
        };
      }

      handleSetCheckSumByEntityName('rule', ruleData.checksum);

      if (!_isNil(ruleData.action)) {
        if (!_isNil(ruleData.action.then)) {
          setThenDataParams(
            transformPayloadToResultDataType(
              ruleData.action.then.outputData,
              ruleData.action.then.firstOutputData
            )
          );
        }

        const thenActionNode = transformActionNode(
          ruleData.action.then.actionNode,
          ruleData.action.then.firstActionNode
        );

        setThenActionResponse(thenActionNode);

        thenActionNode.forEach((currActionNode) => {
          const id = currActionNode.connectorId;

          const originalSource = usedConnectorMapping?.[id]?.source ?? [];

          const source = originalSource?.includes('action')
            ? originalSource
            : [...originalSource, 'action'];

          usedConnectorMapping[id] = {
            status: true,
            source,
          };
        });

        if (!_isNil(ruleData.action.else)) {
          setElseDataParams(
            transformPayloadToResultDataType(
              ruleData.action.else.outputData,
              ruleData.action.else.firstOutputData
            )
          );
        }

        if (!_isNil(ruleData.action.else.actionNode)) {
          const elseActionNode = transformActionNode(
            ruleData.action.else.actionNode,
            ruleData.action.else.firstActionNode
          );

          setElseActionResponse(elseActionNode);

          elseActionNode.forEach((currActionNode) => {
            const id = currActionNode.connectorId;

            const originalSource = usedConnectorMapping?.[id]?.source ?? [];

            const source = originalSource?.includes('action')
              ? originalSource
              : [...originalSource, 'action'];

            usedConnectorMapping[currActionNode.connectorId] = {
              status: true,
              source,
            };
          });
        }
      }

      if (!_isNil(ruleData.dataSetId) && !_isEmpty(ruleData.dataSetId)) {
        const dataSetKey = ruleData.dataSetId;

        setSelectedDataSet([dataSetKey]);

        if (!_isNil(dataSetKey)) {
          const mappedDatasetVersionInfo = ruleData.dependencyMap?.find(
            (versionMap) => versionMap.nodeId === 'dataSet'
          );

          const result = await getDataSetUsedInRuleById(
            dataSetKey,
            getDataSetById,
            mappedDatasetVersionInfo?.version
          );

          if (!_isNil(result)) {
            setDataSetFieldById(result);
          }
        }
      }

      setFirstCustomAttribute(ruleData.firstCustomInput);

      if (!_isNil(ruleData.warnings)) {
        setRuleWarnings((prev) =>
          unionStringArrays(prev, ruleData.warnings ?? [])
        );
      }

      if (!_isNil(ruleData.customInput)) {
        const customInput = transformPayloadCustomInputToTableData(
          ruleData.customInput
        );

        setCustomDataParams(customInput);

        handleSetWarning(customInput, ruleData.dataSetId);

        Object.keys(customInput).forEach((key) => {
          const currCustomInput = customInput[key];
          const id = currCustomInput.attribute;

          if (currCustomInput.dataType?.value === 'restAPI' && !_isNil(id)) {
            const originalSource = usedConnectorMapping?.[id]?.source ?? [];

            const source = originalSource?.includes('restApi')
              ? originalSource
              : [...originalSource, 'restApi'];

            usedConnectorMapping[id] = {
              status: true,
              source,
            };
          }
        });
      }

      setUsedConnectorMappingInRules(usedConnectorMapping);

      if (!_isNil(ruleData.settings)) {
        const authType = getAuthType(ruleData);

        setProductionConfig(
          formatTriggersDataFromResponse(
            ruleData.settings,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (dataClone) {
        setIsRulePublishable(false);
        setIsRulePublished(false);
        setDataClone(false);
      } else {
        setIsRulePublishable(false);
        setIsRulePublished(false);
      }
    }

    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };

  useEffect(() => {
    if (isLiveNodeSheetClosed) {
      void initialize(editRuleData?.getRule.data[0]);
    }
  }, [editRuleData, isLive, isLiveNodeSheetClosed]);

  useEffect(() => {
    if (!_isNil(ruleId) && !_isNil(discardRule)) {
      void initialize(discardRule.discardRule);
    }
  }, [discardRule]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      if (isLive && isMounted && !isEditSheetDisable) {
        setIsRuleReadOnly(false);
      }
    };
  }, [isMounted, isEditSheetDisable]);

  useEffect(() => {
    if (!_isNil(discardError)) {
      toasts.error(discardError.message, 'error');
    }
  }, [discardError]);

  return {
    nodesStartId,
    nodeList,
    thenDataParams,
    elseDataParams,
    customDataParams,
    stagingConfig,
    productionConfig,
    discardRuleById,
    ruleName,
    ruleDescription,
    ruleVersion,
    ruleLoading,
    selectedDataSets,
    thenActionResponse,
    elseActionResponse,
    loadingData,
    createdAt,
    publishedAt,
    accessRole,
    status,
    handleGetRuleAfterStateTransition,
  };
}
