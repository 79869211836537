import { Inline } from '@bedrock-layout/primitives';
import _upperFirst from 'lodash/upperFirst';
import { useRef, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { Dataset, PopoverMethods, PopoverPanel, TreeViewerField } from 'ui';

import { DataTypeSelectionLauncher } from '../../../components/rules/forms/CustomAttributeSheet/DataTypeSelectionLauncher';
import { defaultDataTypesForSchema } from '../models';
import type { SchemaDataTypeSelectionProps } from '../types';

export function SchemaDataTypeSelection({
  control,
  index,
  setValue,
  name,
  isDisabled,
}: SchemaDataTypeSelectionProps) {
  const [typesToSelectFrom] = useState<Record<string, Dataset>>(
    defaultDataTypesForSchema
  );
  const ref = useRef<PopoverMethods>(null);
  const { fieldState } = useController({
    name: `moduleSchema.${index}.dataType`,
    control,
  });
  const dataType: Record<string, any> | null = useWatch({
    name: `moduleSchema.${index}.dataType`,
    control,
  });

  const displayLabel =
    dataType != null &&
    typeof dataType.value === 'string' &&
    dataType.value !== ''
      ? _upperFirst(dataType.value)
      : 'Select Type';

  return (
    <Inline>
      <PopoverPanel
        launcher={
          <DataTypeSelectionLauncher
            label={displayLabel}
            error={fieldState.error?.message}
            isDisabled={isDisabled}
            setTabIndex={() => {}}
            dataType={dataType?.value ?? ''}
          />
        }
        trigger="click"
        ref={ref}
        disabled={isDisabled}
      >
        <TreeViewerField
          name={name}
          control={control}
          dataset={typesToSelectFrom}
          showSearchField
          searchPlaceholder="Search..."
          onClick={({ key, value }: { key: string; value: string }) => {
            if (key === 'primitive') {
              setValue(`moduleSchema.${index}.dataType`, {
                label: _upperFirst(value),
                value,
              });
            }
            ref.current?.hide();
          }}
        />
      </PopoverPanel>
    </Inline>
  );
}
