import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetIdsToBeNotRendered } from '../../../hooks/useGetIdsToBeNotRendered';
import { useTestResponseNode } from '../../../hooks/useTestResponseNode';
import type { WorkflowAttribute } from '../../../models/models';
import {
  checkIfNameExists,
  formatResponseInWorkflow,
  getExecutedValueAndStatus,
  transformSetVariableNodeData,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  uniqueKeyNameValidationForAttributes,
  validateSetVariableOrResponseNode,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { ResponseMapping } from './ResponseMapping/ResponseMapping';
import { ResponseNodeTest } from './ResponseNodeTest/ResponseNodeTest';

type ResponseNodeSheetProps = {
  data?: any;
  id?: string;
};

export function ResponseNodeSheet({
  data: localData,
  id = '',
}: ResponseNodeSheetProps) {
  const [isResultDisabled] = useState(false);
  const [nodeTestData, setNodeTestData] = useState<
    Record<string, WorkflowAttribute>
  >({});

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setIsWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const {
    testData,
    responseData,
    responseError,
    updatedDataSet,
    currentStatus,
    setCurrentStatus,
    isTesting,
    directParents,
    setIsTesting,
  } = useTestResponseNode({
    localData,
    id,
  });

  const [counter, setCounter] = useState(0);
  const [initialFormRender, setInitialFormRender] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { close } = useCurrentLayer();

  const { open: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close response sheet" />
  );

  const [currentTab, setCurrentTab] = useState(0);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { control, clearErrors, watch, handleSubmit, setError, setValue } =
    useForm<any>({
      defaultValues: {
        settings: localData.settings ?? {
          timeout: 30,
        },
        attributes: [],
        name: localData.name,
      },
    });

  // eslint-disable-next-line
  const onSubmit = async (dt: any, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    const isAttributeKeysValid = uniqueKeyNameValidationForAttributes(
      dt.attributes,
      setError
    );

    if (!isNameValid || !isAttributeKeysValid) {
      setIsSaving(false);
      setIsTesting(false);

      return;
    }

    const sheetData = transformSetVariableNodeData(dt);

    if (!_isNil(workflowNode)) {
      const newWorkflowNode = workflowNode;
      newWorkflowNode.data.status = currentStatus;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;

      newWorkflowNode.data.settings = sheetData.settings;

      newWorkflowNode.data.input = sheetData.input;

      newWorkflowNode.data.executedValue =
        !_isNil(responseData) && !_isNil(responseData?.data)
          ? responseData.data?.data?.output
          : newWorkflowNode.data.executedValue ?? null;

      newWorkflowNode.data.settings = sheetData.settings;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setIsWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode);
    }

    if (!test) {
      close();
    } else {
      const isValid = await validateSetVariableOrResponseNode(
        dt,
        updatedDataSet,
        setError
      );

      if (isValid) {
        setCounter(1);
        setNodeTestData(sheetData.input);
        setIsTesting(true);
      }
    }

    setIsSaving(false);

    return null;
  };

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  const formValues = watch();

  useEffect(() => {
    if (initialFormRender) {
      setCounter((count) => count + 1);
      clearErrors();
    }
  }, [JSON.stringify(formValues), initialFormRender]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  useEffect(() => {
    const value = formatResponseInWorkflow(localData);

    setValue('attributes', value.attributes);
    setValue('settings', value.settings);
    setValue('name', value.name);

    setInitialFormRender(true);
  }, []);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const handleTestNode = async () => {
    await testData(nodeTestData, () => {
      setCurrentTab(1);
      setIsTesting(false);
      setNodeTestData({});
    });
  };

  const { idsToNotExpand } = useGetIdsToBeNotRendered({
    directParents,
    dataSet: updatedDataSet,
  });

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  return (
    <Sheet size="small" onClose={counter > 1 ? openSheetCloseModal : close}>
      <WorkflowSheetFormStyled>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline stretch="start">
              <Stack gutter={8}>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Inline>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <PadBox padding="2rem">
                  <ResponseMapping
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    dataSet={updatedDataSet}
                    idsToNotExpand={idsToNotExpand}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>

            <TabPanel>
              {(!_isNil(responseData?.data?.data) ||
                !_isNil(responseError?.response?.data) || !_isNil(localData.executedValue)) && (
                <ResponseNodeTest
                  output={
                    !_isNil(responseData?.data?.data)
                      ? getExecutedValueAndStatus(responseData, watch('name')).executedValue
                      : responseError?.response?.data ?? localData.executedValue
                  }
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <SheetFooterStyled>
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading && !isTesting ? (
              <Spinner size="extraSmall" />
            ) : (
              <Inline>Save</Inline>
            )}
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
