import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Typography } from 'ui';

import { isRuleReadOnlyAtom, simpleRuleNodeIdAtom } from '../../..';
import { predefineTokenDatasetAtom } from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { onClickTokenSelectionArgs } from '../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { TokenMenu } from '../../../../../components/TokenComponents/TokenMenu/TokenMenu';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { AddChildStyled } from './Results.styled';

type DataParamsTypeProps = {
  handleAddDataParam: (
    type: string,
    data?: onClickTokenSelectionArgs
  ) => { isKeyNameUnique: boolean };
  control?: Control<any>;
};

export function DataParamsType({
  handleAddDataParam,
  control,
}: DataParamsTypeProps) {
  const [ruleId] = useAtom(simpleRuleNodeIdAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const handleAddData = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      action: 'add',
      element: 'data',
      nec_source: '',
      action_name: '',
    });
  };

  const launcher = (
    <AddChildStyled
      $disabled={isRuleReadOnly}
      align="center"
      gutter={5}
      onClick={handleAddData}
    >
      {isRuleReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add Result</Typography>
    </AddChildStyled>
  );

  const handlePredefineTokenSelection = (data: onClickTokenSelectionArgs) => {
    const { isKeyNameUnique } = handleAddDataParam(data.dataType, data);

    return {
      canCloseModal: isKeyNameUnique,
    };
  };

  return (
    <TokenMenu
      entity="rules"
      disabled={isRuleReadOnly}
      tokenDataset={predefineTokenDataset}
      launcher={launcher}
      handleAddCustomToken={handleAddDataParam}
      handlePredefineTokenSelection={handlePredefineTokenSelection}
    />
  );
}
