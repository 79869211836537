import { Inline, PadBox } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { RefObject, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  HorizontalLine,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { ButtonWithOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { EntityUnderReview } from '../../../components/EntityUnderReview/EntityUnderReview';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { AutoSaveLoader } from '../../../components/autoSaveLoader/AutoSaveLoader';
import { TrashIcon } from '../../../components/icons/Trash';
import { useUpdateModalStateOnButtonOptionClick } from '../../../hooks/useUpdateModalStateOnButtonOptionClick';
import {
  handleGetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { useDeleteDataSet } from '../hooks/useDeleteDataSet';
import { TestDataSetFunction } from '../hooks/useTestDataSet';
import { useWatchFields } from '../hooks/useWatchFields';
import { DataSetModel } from '../models';
import type {
  CreateDataSetFormValues,
  DataSetPublishedStatus,
  UpdateDataSetStatusFunction,
} from '../types';
import { getEditorDetailsByPlugin } from '../utils';
import {
  FooterActionsContainer,
  PublishButtonContainer,
} from './DataSetFooter.styled';
import { StyledInlineContainer } from './DataSetForm.styled';
import { DeleteDataSetModal } from './DeleteDataSetModal';

type DataSetFooterProps = {
  dataSetId: string;
  isCreatingOrUpdating: boolean;
  dataSetStatus: DataSetPublishedStatus;
  updateDataSetStatus: UpdateDataSetStatusFunction;
  datasetObj: DataSetModel | undefined;
  control: Control<CreateDataSetFormValues>;
  version?: string;
  from?: string;
  onPublish?: () => void;
  onUnmount?: () => void;
  setValue?: UseFormSetValue<CreateDataSetFormValues>;
  handleEntityUpdate: (data: Record<string, any>) => void;
  testDataSet: TestDataSetFunction;
  handleEditButton: () => void;
  isDataSetLoading?: boolean;
  isTestInProgress: boolean;
  isQueryValidNected: boolean;
  setIsQueryValidNected: any;
  outputRef: RefObject<HTMLDivElement>;
};

export function DataSetFooter({
  dataSetId,
  isCreatingOrUpdating,
  updateDataSetStatus,
  from = '',
  onPublish,
  onUnmount,
  datasetObj,
  control,
  handleEntityUpdate,
  testDataSet,
  handleEditButton,
  isDataSetLoading,
  isTestInProgress,
  isQueryValidNected,
  setIsQueryValidNected,
  outputRef,
}: DataSetFooterProps) {
  const [searchParams] = useSearchParams();
  const [deleteDataSet] = useDeleteDataSet();
  const status = useWatch({ control, name: 'status' });
  const isLive = useWatch({ control, name: 'isLive' });
  const { statusValue, approvalInfo } = useWatchFields(control);
  const query = useWatch({ control, name: 'query' });
  const params = useWatch({ control, name: 'params' });

  const { isHide: deleteDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.delete],
    entityList: [ENTITY_ID.datasets],
    entityStatus: statusValue,
  });

  const { isHide: hideEditBtn } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.workflow],
    statusShouldBe: ['published'],
    entityStatus: status,
  });

  const { isHide: testDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.datasets],
    entityStatus: status,
  });

  const handleDeleteDataSet = async (id: string) => {
    const checksum = handleGetCheckSumByEntityName('datasets');
    try {
      await deleteDataSet({
        variables: {
          id,
          checksum: checksum ?? '',
        },
      });
      toasts.success('Data Source deleted successfully', 'deleted-success');
    } catch (error) {
      showGraphQlErrorToast(error);
    }
  };

  const onDeleteClick = async (dataSetId?: string) => {
    await handleDeleteDataSet(dataSetId ?? '');
    window.location.href = '/datasets';
    closeAllLayers();
  };

  const { closeAllLayers } = useCurrentLayer();

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteDataSetModal onDeleteClick={onDeleteClick} />
  );

  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function') {
        onUnmount();
      }
    };
  }, []);

  const { selectedOptionId, handleOptionButtonClick } =
    useUpdateModalStateOnButtonOptionClick({
      entityId: dataSetId,
      entityType: ENTITY_ID.datasets,
      entityName: datasetObj?.name ?? '',
      entityStatus: statusValue ?? '',
      entitySubType: datasetObj?.connector?.plugin?.name ?? '',
      handleParentStateUpdate: handleEntityUpdate,
      formData: {
        requestReview: approvalInfo,
        publishModal: {
          title: approvalInfo?.title,
        },
      },
    });

  const showEditBtn = (isLive ?? false) && !hideEditBtn;
  const isNotPreview = searchParams.get('type') !== 'view';

  const editorNotRequiredList = ['gsheet'];
  const editorDetails = getEditorDetailsByPlugin(datasetObj?.connector?.plugin);
  const hideEditor = editorNotRequiredList.includes(
    editorDetails?.databaseName ?? ''
  );

  const selectedFile = params?.file;
  const selectedSpreadsheet = params?.sheet;

  const hasGSheetData =
    !_isNil(selectedFile) &&
    !_isEmpty(selectedFile) &&
    !_isNil(selectedSpreadsheet) &&
    !_isEmpty(selectedSpreadsheet);

  const shouldDisableExecuteNodeButton =
    (isDataSetLoading ?? false) ||
    _isEmpty(dataSetId) ||
    isTestInProgress ||
    (hideEditor ? !hasGSheetData : _isEmpty(query.trim()));

  return (
    <PublishButtonContainer className="dataset-footer-container">
      <HorizontalLine />

      <PadBox padding=".8rem">
        <FooterActionsContainer $deleteDisable={deleteDisable}>
          {!deleteDisable && (
            <Button
              size="medium"
              disabled={_isEmpty(dataSetId)}
              appearance="neutral"
              leadingIcon={<TrashIcon />}
              onClick={() => openDeleteConfirmationModal({ dataSetId })}
            >
              <Typography fontWeight={700}>Delete</Typography>
            </Button>
          )}

          <Inline align="center" justify="center" gutter="1rem">
            <AutoSaveLoader isLoading={isCreatingOrUpdating} />

            <StyledInlineContainer status={status}>
              <EntityUnderReview entityType="Dataset" entityStatus={status} />
              <>
                {showEditBtn && isNotPreview && (
                  <Button onClick={handleEditButton}>Edit</Button>
                )}

                {!testDisable && (
                  <Inline justify="end">
                    <Button
                      disabled={shouldDisableExecuteNodeButton}
                      onClick={async () => {
                        if (!isQueryValidNected) {
                          toasts.error(
                            'You still have some errors in the editor. Please resolve to proceed',
                            'error'
                          );

                          return;
                        }

                        await testDataSet(outputRef);
                      }}
                      appearance="filled"
                      type="button"
                    >
                      Test Query
                    </Button>
                  </Inline>
                )}
              </>
            </StyledInlineContainer>

            {!['published', 'archived'].includes(statusValue) && (
              <ButtonWithOptions
                id="dataset-footer"
                entityType={ENTITY_ID.datasets}
                entityStatus={statusValue}
                defaultSelectedOptionId={selectedOptionId}
                onClick={handleOptionButtonClick}
              />
            )}
          </Inline>
        </FooterActionsContainer>
      </PadBox>
    </PublishButtonContainer>
  );
}
